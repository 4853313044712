import React from 'react';
import {
    StyledIcon,
    StyledInsuranceCategory,
    StyledInsuranceImage,
    StyledInsuranceName,
    StyledInsuranceNameContainer,
    StyledNameContainer,
    StyledDetailsContainer
} from './styles';
import { ChevronDownIcon, DefaultInsuranceIcon } from '../../../assets/img';
import { IInsuranceIdentity } from './types';
import { getPolicyTitle } from '../../../utils/PolicyServices';

const InsuranceIdentity: React.FunctionComponent<IInsuranceIdentity> = ({
    policyCategory,
    policyImage,
    policyName,
    familyStructure,
    onIconClick,
    isInverted = false,
    isPhoneResponsive = false
}) => {
    return (
        <StyledNameContainer>
            <StyledDetailsContainer $isPhoneResponsive={isPhoneResponsive}>
                <StyledInsuranceImage src={policyImage || DefaultInsuranceIcon} alt={'Insurance Image'} />
                <StyledInsuranceNameContainer $isPhoneResponsive={isPhoneResponsive}>
                    <StyledInsuranceName>{getPolicyTitle(policyCategory, familyStructure)}</StyledInsuranceName>
                    <StyledInsuranceCategory>{policyName}</StyledInsuranceCategory>
                </StyledInsuranceNameContainer>
            </StyledDetailsContainer>
            {onIconClick ? (
                <StyledIcon src={ChevronDownIcon} alt={'Chevron-Down'} onClick={onIconClick} $isInverted={isInverted} />
            ) : null}
        </StyledNameContainer>
    );
};

export default InsuranceIdentity;
