import React from 'react';
import { StarFilledIcon } from '../../../assets/img';
import theme from '../../../theme';
import { TextView } from '../TextView';
import { StyledChip, StyledLabel, StyledRecommendedTag } from './styles';
import { IChip } from './types';

const Chip: React.FunctionComponent<IChip> = ({
    label = '',
    variant = 'filled',
    color,
    backgroundColor,
    showTag = false,
    borderRadius = '1rem',
    onClick,
    padding
}) => {
    return (
        <StyledChip
            color={color}
            variant={variant}
            backgroundColor={backgroundColor}
            borderRadius={borderRadius}
            onClick={onClick}
            $showTag={showTag}
        >
            {showTag ? (
                <StyledRecommendedTag>
                    <img src={StarFilledIcon} />
                    <TextView fontSize="12px" margin="0px 0px 0px 4px" color={theme.colors.loopEmerald}>
                        Recommended
                    </TextView>
                </StyledRecommendedTag>
            ) : null}
            <StyledLabel color={color} padding={padding}>
                {label}
            </StyledLabel>
        </StyledChip>
    );
};

export default Chip;
