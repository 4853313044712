import styled from 'styled-components';

export const StyledContainer = styled.div`
    box-shadow: 0px -2px 14px rgba(168, 196, 206, 0.1);
    border-radius: 12px;
    background-color: ${(p) => p.theme.colors.white};
    max-width: 1100px;
    padding: 24px;
    position: relative;
    @media (max-width: 768px) {
        width: 100%;
        border-radius: 0px;
        margin: 0px;
    }
`;

export const StyledContentWrapper = styled.div`
    display: grid;
    grid-template: 'sumInsured coveredMembers amountPayable .';
    margin: 28px 0px 24px 0px;
    gap: 48px;
    width: 100%;
    justify-content: flex-start;
    grid-area: detailsWrapper;
    @media only screen and (max-width: 768px) {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: space-between;
        margin: 10px 0px;
        grid-template: 'sumInsured coveredMembers amountPayable';
    }
`;

export const StyledEnrolledMemberContainer = styled.div`
    grid-area: enrolledMembersContainer;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 20px;
    @media (max-width: 768px) {
        gap: 10px;
    }
`;

export const StyledEnrolledMemberWrapper = styled.div`
    display: flex;
    width: 100%;
    gap: 5px;
    padding-top: 16px;
    border-top: 1px dashed ${(p) => p.theme.colors.seafoamGray};
    align-items: center;
    cursor: pointer;
`;

export const StyledIcon = styled.img`
    display: flex;
    width: 18px;
`;

export const StyledArrowIcon = styled.img<{ $isCollapsed: boolean }>`
    display: flex;
    width: 24px;
    cursor: pointer;
    transform: rotate(${(p) => (p.$isCollapsed ? '180deg' : '0deg')});
`;

export const StyledDeleteIconContainer = styled.div`
    position: absolute;
    right: 32px;
    top: 32px;
    @media (max-width: 768px) {
        right: 20px;
        top: 16px;
    }
`;

export const StyledDeleteIcon = styled.img`
    height: 24px;
    width: 24px;
    cursor: pointer;
    @media (max-width: 768px) {
        height: 20px;
        width: 20px;
    }
`;
