import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 15px 0px;
    @media (max-width: 768px) {
        width: 100%;
        padding: 0px;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .leadpage-input {
        width: 100%;
        box-shadow: 0px 4px 10px rgba(149, 159, 190, 0.1);
        border-radius: 8px;
        padding: 9px;
        box-sizing: border-box;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #3d4144;
        background: #edeff3;
        border: 0px;
    }

    .react-date-picker__wrapper {
        border: 0;
    }

    .date-picker-error {
        border: 1px solid ${(p) => p.theme.colors.error};
    }
`;

export const StyledModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 32px 24px;
    @media (max-width: 768px) {
        padding: 24px 20px;
        width: 100%;

        &::-webkit-scrollbar {
            display: none;
        }
    }
`;

export const StyledTitle = styled.div`
    font-size: ${(p) => p.theme.fontSizes.body};
    color: ${(p) => p.theme.colors.black};
    font-weight: 700;
    display: flex;
    align-items: center;
    span {
        color: ${(p) => p.theme.colors.muted};
        font-weight: 500;
        margin-left: 8px;
    }
    @media (max-width: 768px) {
        padding: 16px 20px;
    }
`;

export const StyledNomineesContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    @media (max-width: 768px) {
        width: 100%;
        justify-content: flex-start;
        flex-direction: column;
        gap: 12px;
        background-color: ${(p) => p.theme.colors.white};
        padding: 24px 20px;
        margin: 0px;
    }
`;

export const StyledFormTip = styled.div(
    (p) => `
  position: absolute;
  color: ${p.theme.colors.muted};
  margin-top: 6px;
  margin-left: 6px;
  font-size: 12px;
  max-width: 300px;
  @media (max-width: 768px) {
      position: relative;
  }
`
);
export const StyledTitleWarningText = styled.div(
    (p) => `
  color: ${p.theme.colors.error};
  font-size: 12px;
`
);

export const StyledMutedWarningText = styled.div(
    (p) => `
  color: ${p.theme.colors.mutedBG};
  font-size: 12px;
`
);

export const StyledAlignment = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    align-items: center;
`;

export const StyledStickyContainer = styled.div`
    border-top: 1px solid ${(p) => p.theme.colors.seafoamGray};
    background-color: ${(p) => p.theme.colors.white};
    position: sticky;
    bottom: 0px;
    display: flex;
    justify-content: flex-end;
    padding: 20px 16px;
`;

export const StyledButtonContainer = styled.div`
    width: 135px;
    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const StyledIcon = styled.img`
    width: 72px;
    height: 72px;
    @media (max-width: 768px) {
        width: 48px;
        height: 48px;
    }
`;

export const StyledDescription = styled.p`
    text-align: center;
    @media (max-width: 768px) {
        width: 285px;
    }
`;

export const StyledBlockingModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 401px;
`;

export const StyledContent = styled.div`
    padding: 30px 30px 0px 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    gap: 24px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    @media (max-width: 768px) {
        padding: 10px;
        gap: 16px;
    }
`;
