import React from 'react';
import { TopUpDetailContainer, StyledDividerContainer } from './styles';
import TopUpDetailItem from './TopUpDetailItem';
import theme from '../../../theme';
import { ImageView } from '../../atoms/ImageView';
import { contentIcon, PersonImg, QuotesImg, Stethoscope } from '../../../assets/img';
import { TextView } from '../../atoms/TextView';

const TopUpDetails: React.FunctionComponent = () => {
    const commonImgProps = {
        height: '21px',
        width: '21px',
        containerMinHeight: '42px',
        containerWidth: '42px',
        radius: '32px'
    };
    const commonTextProps = {
        fontSize: '16px',
        color: theme.colors.loopEmerald,
        margin: '0px 0px 0px 10px'
    };
    return (
        <TopUpDetailContainer>
            <div style={{ height: '70px' }}></div>
            <TopUpDetailItem color={theme.colors.loopEmerald}>
                <ImageView {...commonImgProps} src={Stethoscope} backgroundColor={theme.colors.planRed} />
                <TextView fontSize="16px" color={theme.colors.loopEmerald} margin="0px 0px 0px 10px">
                    Are pre-existing diseases covered?
                </TextView>
            </TopUpDetailItem>
            <StyledDividerContainer />
            <TopUpDetailItem>
                <ImageView {...commonImgProps} src={PersonImg} backgroundColor={theme.colors.coverUpGreen} />
                <TextView {...commonTextProps}>Are parents covered?</TextView>
            </TopUpDetailItem>
            <StyledDividerContainer />
            <TopUpDetailItem color={theme.colors.loopEmerald}>
                <ImageView {...commonImgProps} src={QuotesImg} backgroundColor={theme.colors.coverYellow} />
                <TextView {...commonTextProps}>
                    What about out-of-pocket <br /> payment for claims?
                </TextView>
            </TopUpDetailItem>
            <StyledDividerContainer />
            <TopUpDetailItem color={theme.colors.loopEmerald}>
                <ImageView {...commonImgProps} src={contentIcon} backgroundColor={theme.colors.purple} />
                <TextView {...commonTextProps}>What about claims support?</TextView>
            </TopUpDetailItem>
        </TopUpDetailContainer>
    );
};

export default TopUpDetails;
