import styled from 'styled-components';

export const CoveredMemberContainer = styled.div<{
    $color: string;
}>`
    background-color: ${(p) => (p.$color === 'white' ? p.theme.colors.white : p.theme.colors.lightGrey)};
    display: flex;
    margin-top: 12px;
    flex-direction: row;
    padding: 13px 0px 13px 0px;
    border-radius: 12px;
    min-width: 200px;
    width: ${(p) => (p.$color === 'white' ? '100%' : '')};
    @media (max-width: 768px) {
        width: 90%;
        margin-top: 0px;
    }
}
`;
export const MemberNameContainer = styled.div`
    margin-left: 10px;
    display: flex;
    flex-direction: column;
`;
