import React from 'react';
import {
    StyledFooter,
    PremiumBreakdownContainer,
    StyledAlertContainer,
    StyledAlertTextContainer,
    StyledAlignment,
    StyledButtonContainer,
    StyledContainer,
    StyledContainerWrapper,
    StyledContent,
    StyledMenuContainer,
    StyledPageContainer,
    StyledStickyContainer,
    StyledTitle,
    StyledTitleContainer
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ReduxState } from '../../../../../redux';
import { AnnounceIcon, LogoIcon } from '../../../../../assets/img';

import { SEGMENT_ACTIONS } from '../../../../../utils/constants/SegmentActionConstants';

import { useLocation } from 'react-router-dom';
import { fetchUserPolicyList } from '../../../../../redux/slices/PolicyListSlice';

import { ISummaryPage } from './types';
import { Alerts, LoopButton, Typography } from '@loophealth/loop-ui-web-library';

import PremiumBreakdown from './PremiumBreakdown';

import useRazorpay from '../../../../../utils/Razorpay';
import {
    calculatePremium,
    resetCalculatedPremium,
    resetOrderState
} from '../../../../../topup-src/redux/slices/PolicyListSlice';
import { createPaymentOrder, fetchOrderStatus } from '../../../../../topup-src/redux/slices/PolicyListSlice/thunks';
import PaymentFailedModal from '../../../../containers/PaymentFailedModal';
import { SummaryCard } from '../../../../containers';
import { Stepper } from '../../../../atoms';
import { INTERNAL_ROUTES } from '../../../../../utils/constants';
import useSegment from '../../../../../utils/hooks/useSegment';
import { getSegmentPageName } from '../../../../../utils/common';
import { updateUserPolicyEnrolmentStatus } from '../../../../../adapters';
import { checkParentalPolicyTopUp } from '../../../../../utils/PolicyServices';
import {
    enrolUserPolicyFamily,
    fetchTopUpAvailabiltyStatus,
    updateSelectedTopUp
} from '../../../../../redux/slices/EnrolmentSlice/thunks';

const SummaryPage: React.FunctionComponent<ISummaryPage> = ({ selectedBasePolicy }) => {
    interface LocationState {
        makeCalculatePremiumApiCall: boolean;
        previousUrl?: string;
        policyType?: string;
        enrolmentStatus?: string;
    }
    const dispatch = useDispatch();
    const location = useLocation();
    const trackClick = useSegment('click');
    const trackTask = useSegment('task');
    const history = useHistory();
    const checkMakeCalculatePremiumApiCall = location.state as LocationState;
    const topupPolicyDetails = useSelector((state: ReduxState) => state.TopUp_policyData.topupPolicyDetails.data);
    const policyData: any = useSelector((state: ReduxState) => state.TopUp_policyData.policyList.data);
    const topUpDetails: any = useSelector((state: ReduxState) => state.TopUp_policyData.memberTopUp);
    const userDetails = useSelector((state: ReduxState) => state.TopUp_user?.userData?.data);
    const paymentDetails = useSelector((state: ReduxState) => state.TopUp_policyData.paymentOrder);
    const orderLoading = useSelector((state: ReduxState) => state.TopUp_policyData.paymentOrder.loading);
    const paymentStatus = useSelector((state: ReduxState) => state.TopUp_policyData.paymentOrderStatus);
    const baseGmcPolicyData = policyData?.baseGmcPolicyData;
    const [paymentFailedIsVisible, setPaymentFailedIsVisible] = React.useState<boolean>(false);
    const currentPageAction = SEGMENT_ACTIONS.PAGE[getSegmentPageName(location.pathname) as any];
    const userId = useSelector((state: ReduxState) => state.TopUp_user?.userData?.data?.userId);
    const policyId = useSelector((state: ReduxState) => state.TopUp_applicationState.topupPolicyId);
    const [checkboxChecked, setShowcheckboxChecked] = React.useState<boolean>(true);
    const userData = useSelector((state: ReduxState) => state.TopUp_user.userData);
    React.useEffect(() => {
        if (!topUpDetails?.sumInsured) {
            setShowcheckboxChecked(true);
        }
    }, [topUpDetails.sumInsured]);
    React.useEffect(() => {
        if (checkMakeCalculatePremiumApiCall) {
            const topUpPolicyData = policyData?.topUpPolicyData;
            if (topUpPolicyData && baseGmcPolicyData) {
                const userIds = topUpDetails.userIds;
                if (userIds?.length) {
                    dispatch(resetCalculatedPremium());
                    dispatch(
                        calculatePremium({
                            selfUserId: userDetails.userId,
                            sumInsured: topUpDetails.sumInsured,
                            deductible: topUpDetails.deductible,
                            userIds,
                            policyId: policyId,
                            isParentalPolicy: checkParentalPolicyTopUp(baseGmcPolicyData)
                        })
                    );
                }
            }
        }
    }, [checkMakeCalculatePremiumApiCall]);
    const enrolUserToGMC = async () => {
        await updateUserPolicyEnrolmentStatus(userData.data.userId, baseGmcPolicyData.policyId);
    };
    React.useEffect(() => {
        if (topUpDetails.hasOwnProperty('annualPremium')) {
            const dependentsArray = topUpDetails.userIds.filter((item: string) => item !== userId);
            if (paymentStatus.data) {
                if (paymentStatus.data.msg === 'success') {
                    // TODO: to update URL
                    // window.history.pushState(null, '', 'thank-you');
                    setPaymentFailedIsVisible(false);
                    enrolUserToGMC();
                    // TODO: Api to be removed as not required
                    dispatch(
                        enrolUserPolicyFamily({
                            userId: userId,
                            policyId: policyId,
                            slabId: topUpDetails.slabId,
                            enrolmentStatus: 'ENROLED',
                            policyStatus: 'ENROLED',
                            dependentIds: dependentsArray,
                            parentalPolicyId: checkParentalPolicyTopUp(baseGmcPolicyData)
                                ? baseGmcPolicyData.policyId
                                : ''
                        })
                    );

                    // Create task event here
                    const segmentAction = SEGMENT_ACTIONS.TASK.PAYMENT_SUCCESS;
                    trackTask({
                        name: segmentAction.name,
                        properties: {
                            paid_amount: (segmentAction.properties?.paid_amount as (amount: string) => string)(
                                topUpDetails?.annualPremium ?? ''
                            )
                        }
                    });
                    setTimeout(() => {
                        dispatch(fetchUserPolicyList({ userId }));
                        dispatch(fetchTopUpAvailabiltyStatus({ userId }));
                        history.push(INTERNAL_ROUTES.dashboard, {
                            previousUrl: location.pathname,
                            policyType: baseGmcPolicyData?.policyType
                        });
                    }, 2000);
                } else {
                    setPaymentFailedIsVisible(true);
                }
            }
        }
    }, [paymentStatus]);

    const proceedToPay = () => {
        if (topUpDetails?.annualPremium == 0 || !topUpDetails.hasOwnProperty('annualPremium')) {
            dispatch(
                updateSelectedTopUp({
                    policyId: selectedBasePolicy?.policyId || '',
                    topUpDetails: null,
                    userId: userId
                })
            );
            const segmentAction = SEGMENT_ACTIONS.CLICK.COMPLETE_REGISTRATION;
            trackClick({
                name: segmentAction.name,
                properties: {
                    policy_id:
                        (segmentAction.properties?.policy_id as (policy: string) => string)(
                            selectedBasePolicy?.policyId || ''
                        ) || '',
                    login_status: segmentAction.properties?.login_status,
                    page_title: (segmentAction.properties?.page_title as (title: string) => string)('Summary'),
                    sum_insured: (segmentAction.properties?.sum_insured as (sumInsured: number) => number)(0) || ''
                }
            });
            history.push(INTERNAL_ROUTES.dashboard, {
                previousUrl: location.pathname,
                policyType: baseGmcPolicyData?.policyType
            });
            return;
        }
        const segmentAction = SEGMENT_ACTIONS.CLICK.PROCEED_TO_BUY;
        trackClick({
            name: segmentAction.name,
            properties: {
                describe: segmentAction.properties?.describe || '',
                login_status: segmentAction.properties?.login_status || '',
                page_url: (currentPageAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                page_id: (currentPageAction.properties?.page_id as (path: string) => string)(location.pathname) || '',
                page_title: (segmentAction.properties?.page_title as (pageTile: string) => string)('Summary'),
                sum_insured:
                    (segmentAction.properties?.sum_insured as (sumInsured: number) => number)(
                        topUpDetails.sumInsured
                    ) || '',
                enrolling_in: (segmentAction.properties?.enrolling_in as (enrollingIn: string) => string)('Both')
            }
        });
        dispatch(
            createPaymentOrder({
                amount: topUpDetails?.annualPremium,
                premium: topUpDetails?.annualPremium,
                gst: topUpDetails?.annualPremiumGST,
                userId: userDetails.userId,
                sumInsured: topUpDetails.sumInsured,
                deductible: topUpDetails.deductible,
                coveredMembers: topUpDetails.userIds,
                digitPremium: topUpDetails?.digitPremiumWithoutGST,
                loopCharges: topUpDetails?.loopMarginWithoutGST,
                policyId: policyId,
                slabId: topUpDetails.slabId,
                parentalPolicyId: checkParentalPolicyTopUp(baseGmcPolicyData) ? baseGmcPolicyData.policyId : ''
            })
        );
    };

    React.useEffect(() => {
        if (!orderLoading && paymentDetails.data) {
            const segmentAction = SEGMENT_ACTIONS.CLICK.PROCEED_TO_BUY_SUMMARY;
            trackClick({
                name: segmentAction.name,
                properties: {
                    user_id: (segmentAction.properties?.user_id as (userId: string) => string)(userDetails.userId),
                    sum_insured: (segmentAction.properties?.sum_insured as (sumInsured: string) => string)(
                        topUpDetails.sumInsured
                    ),
                    selected_members: (segmentAction.properties?.selected_members as (
                        selectedMembers: string
                    ) => string)(topUpDetails.userIds),
                    deductible: (segmentAction.properties?.deductible as (deductible: string) => string)(
                        topUpDetails.deductible
                    ),
                    annual_premium: (segmentAction.properties?.selected_members as (annualPremium: string) => string)(
                        topUpDetails?.annualPremium
                    )
                }
            });
            handlePayment(paymentDetails.data);
        }
    }, [paymentDetails]);

    const Razorpay = useRazorpay();
    const handlePayment = (params: any) => {
        const options = {
            key: `${process.env.REACT_APP_RAZORPAY_KEY}`,
            amount: params.amount,
            currency: params.currency,
            name: 'Loop Health',
            description: 'Super TopUp',
            image: LogoIcon,
            order_id: params.orderId,
            retry: {
                enabled: false
            },
            handler: (response: any) => {
                dispatch(
                    fetchOrderStatus({
                        orderCreationId: params.orderId,
                        razorpayPaymentId: response.razorpay_payment_id,
                        razorpayOrderId: response.razorpay_order_id,
                        razorpaySignature: response.razorpay_signature
                    })
                );
            },
            prefill: {
                name: userDetails.firstName + ' ' + userDetails.lastName,
                email: userDetails.email,
                contact: userDetails.mobile
            },
            modal: {
                ondismiss: () => {
                    dispatch(resetOrderState());
                }
            },
            notes: {
                address: 'Loop Health'
            },
            theme: {
                color: '#025F4C'
            }
        };

        const rzp1 = new Razorpay(options);
        rzp1.on('payment.failed', () => {
            const segmentAction = SEGMENT_ACTIONS.TASK.PAYMENT_FAILED;
            trackTask({
                name: segmentAction.name,
                properties: {
                    paid_amount: (segmentAction.properties?.paid_amount as (amount: string) => string)(
                        topUpDetails?.annualPremium ?? ''
                    )
                }
            });
            setPaymentFailedIsVisible(true);
        });

        rzp1.open();
    };

    const AlertDescription = () => {
        return (
            <StyledAlertTextContainer>
                <Typography variant="small" weight="medium">
                    Double-check your details
                </Typography>
                <Typography variant="small" color="secondary">
                    No edits can be made across any policy after super top-up purchase
                </Typography>
            </StyledAlertTextContainer>
        );
    };

    return (
        <StyledPageContainer>
            <StyledContainerWrapper>
                <StyledMenuContainer>
                    <Stepper />
                </StyledMenuContainer>
                <StyledContainer>
                    <StyledTitleContainer>
                        <StyledTitle>Complete Registration</StyledTitle>
                        {topUpDetails.sumInsured ? (
                            <StyledAlertContainer>
                                <Alerts.StatusAlert
                                    text={<AlertDescription />}
                                    variant="over"
                                    iconSrc={AnnounceIcon}
                                ></Alerts.StatusAlert>
                            </StyledAlertContainer>
                        ) : null}
                    </StyledTitleContainer>

                    {selectedBasePolicy && (
                        <StyledAlignment>
                            <StyledContent>
                                <SummaryCard
                                    insuranceCategory={selectedBasePolicy.policyType}
                                    insuranceName={selectedBasePolicy.policyName}
                                    dependents={selectedBasePolicy.dependents}
                                    policyImage={selectedBasePolicy.policyImage}
                                    coveredMembers={selectedBasePolicy.familyStruture}
                                    sumInsured={selectedBasePolicy.sumInsured}
                                    familyStructure={selectedBasePolicy.familyStruture}
                                    selectedMemberTopUp={null}
                                />

                                {topupPolicyDetails && topUpDetails.sumInsured ? (
                                    <SummaryCard
                                        insuranceCategory={'Family Top-Up'}
                                        insuranceName={topupPolicyDetails.policyName}
                                        dependents={selectedBasePolicy.dependents}
                                        policyImage={topupPolicyDetails.policyLogo}
                                        coveredMembers={selectedBasePolicy.familyStruture}
                                        sumInsured={topUpDetails.sumInsured}
                                        familyStructure={selectedBasePolicy.familyStruture}
                                        selectedMemberTopUp={null}
                                        amountPayable={topUpDetails.annualPremium}
                                        isTopup={!!(topupPolicyDetails && topUpDetails.slabId)}
                                    />
                                ) : null}
                            </StyledContent>
                            {topUpDetails.sumInsured ? (
                                <PremiumBreakdown
                                    checkboxChecked={checkboxChecked}
                                    setShowcheckboxChecked={setShowcheckboxChecked}
                                />
                            ) : null}
                        </StyledAlignment>
                    )}
                </StyledContainer>
            </StyledContainerWrapper>
            <PaymentFailedModal isVisible={paymentFailedIsVisible} setIsVisible={setPaymentFailedIsVisible} />
            <StyledFooter>
                <StyledStickyContainer>
                    {topUpDetails.sumInsured ? (
                        <PremiumBreakdownContainer>
                            <Typography variant="small" color="secondary">
                                Annual Premium
                            </Typography>
                            <Typography variant="large">₹{topUpDetails.annualPremium}</Typography>
                        </PremiumBreakdownContainer>
                    ) : null}
                    <StyledButtonContainer>
                        <LoopButton
                            variant={checkboxChecked ? 'filled' : 'disabled'}
                            onClick={proceedToPay}
                            isLoading={orderLoading}
                        >
                            {topUpDetails.sumInsured ? 'Proceed to Pay' : 'Complete Registration'}
                        </LoopButton>
                    </StyledButtonContainer>
                </StyledStickyContainer>
            </StyledFooter>
        </StyledPageContainer>
    );
};

export default SummaryPage;
