import styled from 'styled-components';

export const Container = styled.div<{
    $bgColor: string;
}>`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${(p) => p.$bgColor};
    align-items: center;
    padding: 50px 0px;
    @media only screen and (max-width: 768px) {
        padding: 30px 20px;
        width: initial;
    }
`;

export const StyledLoaderContainer = styled.div`
    margin-top: 50px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    align-items: center;

    width: 100%;
    margin-top: 20px;
    gap: 20px;
    justify-content: center;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: flex-start;
    }
`;

export const StyledCallExpertWrapper = styled.div`
    display: flex;
`;
export const StyledButtonWrapper = styled.div`
    width: 300px;
    display: flex;
    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const StyledHeaderWrapper = styled.div`
    display: flex;
    margin-bottom: 56px;
    justify-content: center;
    @media (max-width: 768px) {
        margin-bottom: 24px;
        justify-content: flex-start;
    }
`;

export const StyledHeaderText = styled.div`
    display: flex;

    justify-content: center;
    @media (max-width: 768px) {
        margin-bottom: 24px;
        justify-content: flex-start;
    }
`;

export const StyledInnerHeaderText = styled.span<{
    $color: string;
}>`
    color: ${(p) => p.$color};
    font-size: ${(p) => p.theme.fontSizes.landing};
    line-height: 40px;
    font-family: 'Work Sans';
    @media only screen and (max-width: 768px) {
        font-size: 20px;
    }
`;
