import React from 'react';
import { IWelcomeCard } from './types';
import { StyledButtonContainer, StyledContainer, StyledContentWrapper, StyledIcon, StyledText } from './styles';
import { PeopleIcon } from '../../../assets/img';
import theme from '../../../theme';
import { LoopButton } from '@loophealth/loop-ui-web-library';
import DownloadAppModal from '../../containers/DownloadAppCard/DownloadAppModal';
import useResponsive from '../../containers/PolicyInclusionsModal/hooks/useResponsive';
import useSegment from '../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';
import { redirectToMobileStore } from '../../pages/SPAContainer/pages/DashboardPage/utils';
import { REDIRECT_URL } from '../../../utils/constants/RedirectConstants';
const WelcomeCard: React.FunctionComponent<IWelcomeCard> = ({ loggedInUser, showAppDownloadCTA }) => {
    const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
    const isDesktop = useResponsive('(min-width: 768px)');
    const trackClick = useSegment('click');
    const handleDownloadClick = () => {
        const segmentAction = SEGMENT_ACTIONS.CLICK.DASHBOARD_PAGE_DOWNLOAD_APP;
        trackClick({
            name: segmentAction.name,
            properties: {
                page_url: (segmentAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                page_id: (segmentAction.properties?.page_id as (path: string) => string)(location.pathname) || '',
                login_status: segmentAction.properties?.login_status,
                page_title: segmentAction.properties?.page_title,
                banner_position: (segmentAction.properties?.banner_position as (position: string) => string)('Banner')
            }
        });
        if (isDesktop) {
            setIsModalVisible(true);
        } else {
            redirectToMobileStore(REDIRECT_URL.STORE);
        }
    };
    return (
        <StyledContainer>
            <StyledContentWrapper>
                <StyledText
                    $color={theme.colors.beyondGrey}
                    $fontDesktop={theme.fontSizes.title1}
                    $fontMobile={theme.fontSizes.heading}
                >
                    Hey {loggedInUser}, welcome to
                    <StyledText
                        $color={theme.colors.loopEmerald}
                        $fontDesktop={theme.fontSizes.title1}
                        $fontMobile={theme.fontSizes.heading}
                    >
                        {' Loop!'}
                    </StyledText>
                </StyledText>
                <StyledText
                    $color={theme.colors.secondaryText}
                    $fontDesktop={theme.fontSizes.body}
                    $fontMobile={theme.fontSizes.body}
                >
                    Register yourself to get 24x7 support with claims and unlimited access to doctors across 13
                    specialities
                </StyledText>
                {showAppDownloadCTA && (
                    <StyledButtonContainer>
                        <LoopButton onClick={handleDownloadClick} variant="filled">
                            Download Loop App
                        </LoopButton>
                        <DownloadAppModal isVisible={isModalVisible} setIsVisible={setIsModalVisible} />
                    </StyledButtonContainer>
                )}
            </StyledContentWrapper>
            <StyledIcon src={PeopleIcon} />
        </StyledContainer>
    );
};

export default WelcomeCard;
