import styled from 'styled-components';

export const StyledPageContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledContainerWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
    padding: 60px 70px;
    gap: 40px;
    @media (max-width: 768px) {
        flex-direction: column;
        padding: 0px;
        gap: 0px;
    }
`;

export const StyledContainer = styled.div`
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
`;

export const StyledAlignment = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const StyledContent = styled.div`
    margin-bottom: 30px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 138px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    gap: 30px;
    @media (max-width: 768px) {
        padding: 0px;
        margin-bottom: 0;
    }
`;

export const StyledBackIcon = styled.img`
    margin-right: 20px;
`;

export const StyledMenuContainer = styled.div`
    @media (max-width: 768px) {
        padding: 0px;
        width: 100%;
        background-color: ${(p) => p.theme.colors.white};
    }
`;

export const StyledTitleContainer = styled.div`
    width: 100%;
`;

export const StyledTitle = styled.div`
    color: ${(p) => p.theme.colors.beyondGrey};
    font-size: ${(p) => p.theme.fontSizes.heading};
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 20px;
    @media (max-width: 768px) {
        margin: 0px;
        padding: 16px 20px 16px 20px;
        font-size: ${(p) => p.theme.fontSizes.body};
        color: ${(p) => p.theme.colors.secondaryText};
    }
`;

export const StyledAlertTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const StyledAlertContainer = styled.div`
    border: 1px solid ${(p) => p.theme.colors.error};
    border-radius: 8px;
    width: 100%;
    margin: 20px 0px;
    @media (max-width: 768px) {
        margin: 0px;
        border: 0px;
    }
`;

export const StyledPremiumCard = styled.div`
    box-shadow: 0px -2px 14px rgba(168, 196, 206, 0.1);
    border-radius: 12px;
    background-color: ${(p) => p.theme.colors.white};
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    min-width: 320px;
    height: 280px;
    @media (max-width: 768px) {
        margin-bottom: 80px;
    }
`;

export const StyledRowAlignment = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const StyledDivider = styled.div`
    margin: 12px 0px;
    border: 1px dashed ${(p) => p.theme.colors.seafoamGray};
`;

export const StyledTermsContainer = styled.div`
    padding-top: 24px;
    border-top: 1px solid ${(p) => p.theme.colors.seafoamGray};
    display: flex;
    flex-direction: row;
    margin-top: 10px;
`;

export const StyledInput = styled.input.attrs({ type: 'checkbox' })`
    width: 40px;
    height: 20px;
    border-radius: 4px;
    margin-right: 15px;
    cursor: pointer;
    accent-color: ${(p) => p.theme.colors.loopEmerald};
    &::checked {
        background-color: ${(p) => p.theme.colors.loopEmerald};
    }
`;

export const StyledCheckboxText = styled.div`
    display: flex;
    flex-direction: row;
    white-space: nowrap;
`;

export const StyledVerify = styled.div`
    font-size: ${(p) => p.theme.fontSizes.label};
    font-weight: 400;
    line-height: 18px
    letter-spacing: 0.2px;
    color: ${(p) => p.theme.colors.beyondGrey};
`;

export const StyledTermsText = styled.div`
    font-size: ${(p) => p.theme.fontSizes.label};
    font-weight: 400;
    line-height: 18px
    letter-spacing: 0.2px;
    color: ${(p) => p.theme.colors.loopEmerald};
    text-decoration: underline;
    cursor:pointer;
`;

export const StyledStickyContainer = styled.div`
    border-top: 1px solid ${(p) => p.theme.colors.seafoamGray};
    background-color: ${(p) => p.theme.colors.white};
    position: absolute;
    width: 100%;
    bottom: 0px;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    padding: 20px 70px;
    @media (max-width: 768px) {
        padding: 12px 20px;
        justify-content: space-between;
    }
`;

export const StyledButtonContainer = styled.div`
    width: 314px;
    margin-right: 130px;
    @media (max-width: 768px) {
        width: 100%;
        margin-right: 40px;
    }
`;

export const PremiumBreakdownContainer = styled.div`
    box-sizing: border-box;
    padding: 10px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    @media (max-width: 768px) {
        min-width: 0;
        width: 100%;
        max-width: 100%;
        flex-direction: column;
        gap: 0px;
        padding: 0px;
        justify-content: center;
        align-items: flex-start;
    }
`;
export const StyledPartPremiumContainer = styled.div(
    (p) => `
    color: ${p.theme.colors.mutedBG};
    font-size: ${p.theme.fontSizes.label};
    font-weight: 600;
`
);
export const StyledPartAmountText = styled.div(
    (p) => `
    font-weight: bold;
    color: ${p.theme.colors.black};
    font-size: ${p.theme.fontSizes.body};
    line-height: 18px;
    margin-top: 5px;
`
);

export const StyledFooter = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const StyledPaymentSummary = styled.div`
    display: none;
    @media (max-width: 768px) {
        margin: 0px;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 20px;
        padding: 16px 20px 16px 20px;
        font-size: ${(p) => p.theme.fontSizes.body};
        color: ${(p) => p.theme.colors.secondaryText};
    }
`;
