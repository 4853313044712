import React from 'react';
import {
    StyledEnrolledMemberCard,
    StyledEnrolledMemberCardHeader,
    StyledRelationContainer,
    StyledEnrolledMemberCardBody,
    StyledEnrolledMemberItem
} from './styles';

import { IMembersData } from './types';
import { defaultDateFormat } from '../../../utils/common';
import { Avatar, Typography } from '@loophealth/loop-ui-web-library';

const EnrolledMemberRow: React.FunctionComponent<IMembersData> = ({
    firstName,
    lastName,
    relationship,
    gender,
    doB,
    mobile
}) => {
    return (
        <StyledEnrolledMemberCard>
            <StyledEnrolledMemberCardHeader>
                <Avatar size="small" users={[{ name: firstName, imageURL: '' }]} />
                <Typography variant="small" weight="bold">
                    {firstName + ' ' + lastName}
                </Typography>
                <StyledRelationContainer>
                    <Typography variant="extraSmall" weight="semiBold" color="secondary">
                        {relationship}
                    </Typography>
                </StyledRelationContainer>
            </StyledEnrolledMemberCardHeader>
            <StyledEnrolledMemberCardBody>
                <StyledEnrolledMemberItem>
                    <Typography variant="small" color="secondary">
                        Gender
                    </Typography>
                    <Typography variant="small" weight="semiBold">
                        {gender ?? '--'}
                    </Typography>
                </StyledEnrolledMemberItem>
                <StyledEnrolledMemberItem>
                    <Typography variant="small" color="secondary">
                        DOB
                    </Typography>
                    <Typography variant="small" weight="semiBold">
                        {defaultDateFormat(doB) ?? '--'}
                    </Typography>
                </StyledEnrolledMemberItem>
                <StyledEnrolledMemberItem>
                    <Typography variant="small" color="secondary">
                        Mobile
                    </Typography>
                    <Typography variant="small" weight="semiBold">
                        {mobile ?? '--'}
                    </Typography>
                </StyledEnrolledMemberItem>
            </StyledEnrolledMemberCardBody>
        </StyledEnrolledMemberCard>
    );
};

export default EnrolledMemberRow;
