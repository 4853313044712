import React from 'react';
import { StyledContainer, Spacing } from './styles';
import { GoogleIcon, OutlookIcon } from '../../../assets/img';
import { Button } from '../../atoms';
import { ILoginOAuth } from './types';
import { IOAuthType } from '../../../redux/types';
import useSegment from '../../../../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../../../../utils/constants/SegmentActionConstants';

const LoginOAuth: React.FunctionComponent<ILoginOAuth> = ({ signInWithOAuth }) => {
    const trackClick = useSegment('click');
    const handleAuthClick = (buttonType: IOAuthType) => {
        const segmentAction =
            buttonType === 'google' ? SEGMENT_ACTIONS.CLICK.GOOGLE_LOGIN : SEGMENT_ACTIONS.CLICK.OUTLOOK_LOGIN;
        trackClick({
            name: segmentAction.name,
            properties: segmentAction.properties
        });
        signInWithOAuth(buttonType);
    };
    return (
        <StyledContainer>
            <Button type="OAuth" iconSrc={GoogleIcon} onClick={() => handleAuthClick('google')}>
                Continue with Google
            </Button>
            <Spacing />
            <Button type="OAuth" iconSrc={OutlookIcon} onClick={() => handleAuthClick('outlook')}>
                Continue with Outlook
            </Button>
        </StyledContainer>
    );
};

export default LoginOAuth;
