import styled from 'styled-components';

export const StyledContainer = styled.div`
    width: 90%;
    margin-bottom: 100px;
    @media (max-width: 768px) {
        padding: 25px 20px;
        width: 100%;
        margin: 0px;
        flex-direction: column;
    }
`;

export const StyledDescription = styled.p(
    (p) => `
    color: ${p.theme.colors.loopEmerald};
    font-size:  ${p.theme.fontSizes.innerheading};
    font-style: normal;
    font-weight: 100;
    line-height: 38px;
    letter-spacing: -0.02em;
`
);

export const StyledContainerWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: 768px) {
        padding: 0% 5%;
        margin-top: 0px;
        flex-direction: column;
    }
`;
export const StyledLeftContainer = styled.div`
    width: 50%;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 50px;
    @media (max-width: 768px) {
        width: 100%;
    }
`;
export const StyledRightContainer = styled.div`
    width: 40%;
    margin-top: 60px;
    @media (max-width: 768px) {
        width: 100%;
    }
`;
export const StyledImage = styled.img`
    width: 100%;
`;

export const ButtonContainer = styled.div`
    flex-direction: column;
    display: flex;
`;
