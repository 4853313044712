import React from 'react';
import { IModalProps } from './types';
import useResponsive from './hooks/useResponsive';

const withPolicyBenefitsModal = (
    PolicyModalDesktop: React.ComponentType<IModalProps>,
    PolicyModalMobile: React.ComponentType<IModalProps>
): React.FC<IModalProps> => {
    const HOC: React.FC<IModalProps> = (props) => {
        const isDesktop = useResponsive('(min-width: 768px)');
        return isDesktop ? <PolicyModalDesktop {...props} /> : <PolicyModalMobile {...props} />;
    };
    HOC.displayName = 'withPolicyBenefitsModal';
    return HOC;
};

export default withPolicyBenefitsModal;
