import React from 'react';
import { StyledContainer, StyledContentWrapper, StyledImgWrapper } from './styles';
import { IPolicyInfoCard } from './types';
import { Typography } from '@loophealth/loop-ui-web-library';
import { ImageView } from '../../atoms/ImageView';

const PolicyInfoDetails: React.FunctionComponent<IPolicyInfoCard> = ({
    label,
    icon,
    value,
    bgColor,
    textColor = 'primary',
    itemName
}) => {
    return (
        <StyledContainer $item={itemName ?? ''}>
            <StyledImgWrapper>
                <ImageView
                    src={icon}
                    backgroundColor={bgColor}
                    height="22px"
                    width="22px"
                    containerMinHeight="40px"
                    containerWidth="40px"
                    radius="50%"
                />
            </StyledImgWrapper>

            <StyledContentWrapper>
                <Typography variant="extraSmall" color='secondary'>{label}</Typography>
                <Typography variant="small" weight="semiBold" color={textColor}>
                    {value}
                </Typography>
            </StyledContentWrapper>
        </StyledContainer>
    );
};

export default PolicyInfoDetails;
