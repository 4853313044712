/* eslint-disable max-len */
import React from 'react';
import { StyledPaddingContainer, StyledWrapper } from './styles';
import { ITopUpAvailable } from './types';
import { useDispatch, useSelector } from 'react-redux';
import theme from '../../../theme';
import { ReduxState } from '../../../redux';
import TopupLoopDifference from '../TopupLoopDifference';
import TopupCoveredBenefits from '../TopupCoveredBenefits';
import TopUpFaqContainer from '../TopUpFaqContainer';
import TopupTemplate from '../TopupTemplate';
import { RecommendedConfig, getDeductible, getRecommendedTemplate, getSegmentPageName } from '../../../utils/common';
import TopupCustomization from '../TopupCustomization';
import { calculatePremium, resetCalculatedPremium } from '../../../topup-src/redux/slices/PolicyListSlice';
// import { FirebaseRemoteConfig } from '../../../adapters/provider';
import { checkParentalPolicyTopUp } from '../../../utils/PolicyServices';
import { FirebaseRemoteConfig } from '../../../topup-src/adapters/provider';
import useSegment from '../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';

const TopUpAvailable: React.FunctionComponent<ITopUpAvailable> = ({ policyData }) => {
    const dispatch = useDispatch();
    const trackPage = useSegment('page');
    const selfUserId = useSelector((state: ReduxState) => state.user?.userData?.data?.userId);
    const topUpAmount: any = useSelector((state: ReduxState) => state.TopUp_policyData.memberTopUp);
    const isLoadingTopUpAmount = useSelector((state: ReduxState) => state.TopUp_policyData.memberTopUp?.loading);
    const topupPolicyData = useSelector((state: ReduxState) => state.TopUp_policyData.topupPolicyDetails.data);
    const [selectedSI, setSelectedSI] = React.useState('');
    const [defaultConfig, setDefaultConfig] = React.useState<RecommendedConfig>();
    const [sumInsuredSlabs, setSumInsuredSlabs] = React.useState<number[]>([]);
    const [selectedMembers, setSelectedMembers] = React.useState<string[]>([]);
    const [totalSI, setTotalSI] = React.useState('');
    const [familyStructure, setFamilyStructure] = React.useState('');
    const selectedDeductible = getDeductible(Number(policyData.sumInsured));
    const defaultFirebaseConfig = FirebaseRemoteConfig.getValue('enrolmentConfig').asString();
    const userRecommendedTemplate = getRecommendedTemplate(selfUserId, defaultConfig);
    const policyId = useSelector((state: ReduxState) => state.TopUp_applicationState.topupPolicyId);
    const selectTopupBenefits = React.useRef<HTMLDivElement>(null);
    const scrollIntoBenefits = () => {
        document.body.style.overflow = 'auto';
        selectTopupBenefits.current?.scrollIntoView({ behavior: 'smooth' });
    };

    React.useEffect(() => {
        if (defaultFirebaseConfig) {
            setDefaultConfig(JSON.parse(defaultFirebaseConfig));
        }
    }, []);
    React.useEffect(() => {
        if (topUpAmount) {
            const currentPageAction = SEGMENT_ACTIONS.PAGE[getSegmentPageName(location.pathname) as any];
            trackPage({
                name: 'PROCEED_TO_BUY_RECOMMENDATION',
                properties: {
                    page_url:
                        (currentPageAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                    page_id:
                        (currentPageAction.properties?.page_id as (path: string) => string)(location.pathname) || '',
                    selected_members: selectedMembers,
                    sum_insured: selectedSI,
                    deductible: selectedDeductible,
                    annual_premium: topUpAmount?.annualPremium || 0
                }
            });
        }
    }, [topUpAmount]);

    React.useEffect(() => {
        dispatch(resetCalculatedPremium());
        const users: string[] = [];
        policyData.dependents.forEach((user) => {
            users.push(user.userId);
        });
        setSelectedMembers(users);
        const applicableSI = topupPolicyData?.slabs
            .filter((slab) => (slab.deductible as number) == selectedDeductible)
            .map((item: any) => item.sumInsured)
            .filter((value: any, index: any, self: string | any[]) => self.indexOf(value) === index)
            .sort(function(a: number, b: number) {
                return a - b;
            });
        let SI: any;
        if (applicableSI?.length) {
            SI = applicableSI[0] ?? 0;
            // setSelectedSI(SI);
            setSumInsuredSlabs(applicableSI);
            const selectedSlabs = topupPolicyData?.slabs.find(
                (slab) => (slab.deductible as number) == selectedDeductible && (slab.sumInsured as number) == SI
            );
            setFamilyStructure(selectedSlabs?.familyDefinition ?? '');
        }
        setTotalSI(SI + policyData.sumInsured);
    }, [topupPolicyData]);

    React.useEffect(() => {
        if (selectedSI) {
            dispatch(resetCalculatedPremium());
            dispatch(
                calculatePremium({
                    selfUserId,
                    sumInsured: parseInt(selectedSI),
                    deductible: selectedDeductible,
                    userIds: selectedMembers,
                    policyId: policyId,
                    isParentalPolicy: checkParentalPolicyTopUp(policyData as any)
                })
            );
        }
    }, [selectedSI]);

    return (
        <StyledWrapper>
            <TopupTemplate templateID={userRecommendedTemplate ?? 1} onClickExplore={scrollIntoBenefits} />
            <TopupCustomization
                policyData={policyData}
                selectedSI={selectedSI}
                setSelectedSI={setSelectedSI}
                totalSI={totalSI}
                setTotalSI={setTotalSI}
                familyStructure={familyStructure}
                setFamilyStructure={setFamilyStructure}
                selectedMembers={selectedMembers}
                setSelectedMembers={setSelectedMembers}
                selectedDeductible={selectedDeductible}
                sumInsuredSlabs={sumInsuredSlabs}
                memberTopUpPremium={topUpAmount?.annualPremium || 0}
                premiumLoading={isLoadingTopUpAmount}
                defaultConfiguration={defaultConfig}
            />
            <TopupLoopDifference />
            <TopupCoveredBenefits scrollRef={selectTopupBenefits} />
            <TopUpFaqContainer backgroundColor={theme.colors.lightGrey} />
            <StyledPaddingContainer />
        </StyledWrapper>
    );
};

export default TopUpAvailable;
