import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    /* width: 100%; */
    max-height: 216px;
    padding: 18px 100px 0px 100px;
    justify-content: space-between;
    background: linear-gradient(1.49deg, ${(p) => p.theme.colors.white} 25.77%, #dff1ed 145.35%);
    @media only screen and (max-width: 768px) {
        padding: 24px 20px;
        max-height: 260px;
    }
`;

export const StyledContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
`;

export const StyledIcon = styled.img`
    display: flex;
    @media (max-width: 768px) {
        display: none;
    }
`;

export const StyledText = styled.span<{
    $color: string;
    $fontDesktop: string;
    $fontMobile: string;
}>`
    color: ${(p) => p.$color};
    font-size: ${(p) => p.$fontDesktop};
    line-height: 20px;
    font-family: 'Work Sans';
    @media only screen and (max-width: 768px) {
        font-size: ${(p) => p.$fontMobile};
    }
`;

export const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 450px;
    margin: 32px 0px 40px 0px;
    gap: 20px;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        gap: 10px;
        margin: 16px 0px 20px 0px;
    }
`;
