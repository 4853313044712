import React from 'react';
import {
    StyledContainer,
    StyledContentWrapper,
    StyledEnrolledMemberContainer,
    StyledEnrolledMemberWrapper,
    StyledIcon,
    StyledArrowIcon,
    StyledDeleteIcon,
    StyledDeleteIconContainer
} from './styles';

import { IMembersDataProps, ISummaryCard } from './types';
import { InsuranceIdentity } from '../../atoms';
import PolicyInfoDetails from '../PolicyInfoDetails';
import { ArrowDownEmerald, PersonIcon, PersonImg, QuotesImg } from '../../../assets/img';
import theme from '../../../theme';
import useResponsive from '../PolicyInclusionsModal/hooks/useResponsive';
import EnrolledMemberTable from '../EnrolMembersCard/EnrolledMemberTable';
import EnrolledMemberModal from '../EnrolMembersCard/EnrolledMemberModal';
import { Typography } from '@loophealth/loop-ui-web-library';
import { formatAmount } from '../../../utils/common';
import { DeleteIcon } from '../../../assets/img';
import { useDispatch } from 'react-redux';
import { resetCalculatedPremium, resetSelectedTopup } from '../../../topup-src/redux/slices/PolicyListSlice';
import CancelTopUpModal from '../CancelTopUpModal';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';
import useSegment from '../../../utils/hooks/useSegment';

const SummaryCard: React.FunctionComponent<ISummaryCard> = ({ dependents, ...insuranceProps }) => {
    const dispatch = useDispatch();
    const isDesktop = useResponsive('(min-width: 768px)');
    const [isCollapsed, setIsCollapsed] = React.useState(false);
    const [showDependentModal, setShowDependentModal] = React.useState<boolean>(false);
    const [showCancelModalIsVisible, setShowCancelModalIsVisible] = React.useState<boolean>(false);
    const trackClick = useSegment('click');
    const processedProps: IMembersDataProps = {
        dependents,
        isCollapsed: isCollapsed,
        dependentsInsured: insuranceProps.coveredMembers
    };

    const handleIconClick = () => {
        const segmentAction = SEGMENT_ACTIONS.CLICK.REGISTERED_MEMBERS;
        trackClick({
            name: segmentAction.name,
            properties: {
                page_url: (segmentAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                page_id: (segmentAction.properties?.page_id as (path: string) => string)(location.pathname) || '',
                page_title: (segmentAction.properties?.page_title as (title: string) => string)('SUMMARY') || '',
                action_type:
                    (segmentAction.properties?.action_type as (action: string) => string)(
                        !isCollapsed ? 'VIEW' : 'HIDE'
                    ) || '',
                login_status: segmentAction.properties?.login_status
            }
        });
        setIsCollapsed(!isCollapsed);
        if (!isDesktop) setShowDependentModal(true);
    };

    const onRemoveTopup = () => {
        dispatch(resetSelectedTopup());
        dispatch(resetCalculatedPremium());
        setShowCancelModalIsVisible(false);
    };
    const handleRemoveTopUp = () => {
        setShowCancelModalIsVisible(true);
        const segmentAction = SEGMENT_ACTIONS.CLICK.REMOVE_TOPUP;
        trackClick({
            name: segmentAction.name,
            properties: {
                page_url: (segmentAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                page_id: (segmentAction.properties?.page_id as (path: string) => string)(location.pathname) || '',
                page_title: segmentAction.properties?.page_title,
                sum_insured: (segmentAction.properties?.sum_insured as (sumInsured: string) => string)(
                    insuranceProps.sumInsured ?? 0
                ),
                login_status: segmentAction.properties?.login_status
            }
        });
    };
    return (
        <StyledContainer>
            <InsuranceIdentity
                policyImage={insuranceProps.policyImage}
                policyName={insuranceProps.insuranceName}
                policyCategory={insuranceProps.insuranceCategory}
                familyStructure={insuranceProps.coveredMembers}
            />
            <StyledContentWrapper>
                <PolicyInfoDetails
                    icon={QuotesImg}
                    label="Sum Insured"
                    value={formatAmount(insuranceProps.sumInsured)}
                    bgColor={theme.colors.coverYellow}
                    itemName="sumInsured"
                />
                <PolicyInfoDetails
                    icon={PersonImg}
                    label="Eligible Members"
                    value={insuranceProps.coveredMembers}
                    bgColor={theme.colors.coverYellow}
                    itemName="coveredMembers"
                />
                {insuranceProps.amountPayable ? (
                    <PolicyInfoDetails
                        icon={QuotesImg}
                        label="Amount Payable "
                        value={formatAmount(insuranceProps.amountPayable)}
                        bgColor={theme.colors.coverYellow}
                        itemName="amountPayable"
                    />
                ) : null}
            </StyledContentWrapper>
            <StyledEnrolledMemberContainer>
                {isDesktop ? (
                    <EnrolledMemberTable data={processedProps} />
                ) : (
                    <EnrolledMemberModal
                        dependents={dependents}
                        isCollapsed={isCollapsed}
                        isVisible={showDependentModal}
                        setIsVisible={setShowDependentModal}
                        dependentsInsured={insuranceProps.coveredMembers}
                    />
                )}
                <StyledEnrolledMemberWrapper onClick={handleIconClick}>
                    <StyledIcon src={PersonIcon} />
                    <Typography variant="extraSmall" color="emerald" weight="semiBold">
                        {isCollapsed ? 'Hide Registered Members' : 'View Registered Members'}
                    </Typography>
                    <StyledArrowIcon $isCollapsed={isCollapsed} src={ArrowDownEmerald} />
                </StyledEnrolledMemberWrapper>
            </StyledEnrolledMemberContainer>

            {insuranceProps.isTopup ? (
                <StyledDeleteIconContainer>
                    <StyledDeleteIcon
                        src={DeleteIcon}
                        alt="Delete Topup"
                        onClick={handleRemoveTopUp}
                    />
                </StyledDeleteIconContainer>
            ) : null}

            <CancelTopUpModal
                isVisible={showCancelModalIsVisible}
                setIsVisible={setShowCancelModalIsVisible}
                showSummary={onRemoveTopup}
                pageTitle={'checkout'}
            />
        </StyledContainer>
    );
};

export default SummaryCard;
