import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import {
    addDependent,
    editMember,
    fetchTopUpOptions,
    updateSelectedTopUp,
    removeSelectedUserFromPolicy,
    fetchPolicyDetails,
    enrolSelectedUserToPolicy,
    fetchTopUpAvailabiltyStatus,
    enrolUserPolicyFamily
} from './thunks';
const enrolmentSlice = createSlice({
    name: 'enrolmentSlice',
    initialState,
    reducers: {
        resetState(state) {
            state.crudDependents = initialState.crudDependents;
            state.currentlySelectedPolicy = initialState.currentlySelectedPolicy;
            state.selectedMemberTopUp = initialState.selectedMemberTopUp;
            state.topUpOptions = initialState.topUpOptions;
            state.superTopUpPolicy = initialState.superTopUpPolicy;
            state.updateEnrolmentStatus = initialState.updateEnrolmentStatus;
            state.updateTopUpOption = initialState.updateTopUpOption;
            state.policyDetails = initialState.policyDetails;
            state.enrolUserFamily = initialState.enrolUserFamily;
        },
        setCurrentlySelectedPolicy(state, { payload }) {
            state.currentlySelectedPolicy = payload.currentlySelectedPolicy;
        },
        setSelectedMemberTopUp(state, { payload }) {
            state.selectedMemberTopUp = payload.selectedMemberTopUp;
        },
        resetCrudErrors(state) {
            state.crudDependents = initialState.crudDependents;
        },
        resetUpdateTopUpOption(state) {
            state.updateTopUpOption = initialState.updateTopUpOption;
        },
        updateEnrolmentStatus(state, { payload }) {
            if (state.currentlySelectedPolicy) {
                state.currentlySelectedPolicy.enrolmentStatus = payload;
            }
        }
    },
    extraReducers: {
        [fetchTopUpOptions.fulfilled as any]: (state, { payload }) => {
            state.topUpOptions.loading = false;
            state.topUpOptions.data = payload.topUpOptions;
            state.topUpOptions.error = null;
        },
        [fetchTopUpOptions.pending as any]: (state) => {
            state.topUpOptions.loading = true;
            state.topUpOptions.data = null;
            state.topUpOptions.error = null;
        },
        [fetchTopUpOptions.rejected as any]: (state, { error }) => {
            state.topUpOptions.loading = false;
            state.topUpOptions.data = null;
            state.topUpOptions.error = error;
        },
        [updateSelectedTopUp.pending as any]: (state) => {
            state.updateTopUpOption.loading = true;
        },
        [updateSelectedTopUp.rejected as any]: (state, { error, payload }) => {
            state.updateTopUpOption.loading = false;
            state.updateTopUpOption.error = payload?.message ? { message: payload.message } : error;
            state.updateTopUpOption.data = false;
        },
        [updateSelectedTopUp.fulfilled as any]: (state, { payload }) => {
            state.updateTopUpOption.loading = false;
            state.updateTopUpOption.error = null;
            state.updateTopUpOption.data = payload.updateTopUpOption.data;
            state.updateEnrolmentStatus.data = payload.updatedPolicy;
            state.currentlySelectedPolicy = payload.updatedPolicy;
        },
        [enrolSelectedUserToPolicy.pending as any]: (state) => {
            state.crudDependents.loading = true;
        },
        [enrolSelectedUserToPolicy.rejected as any]: (state, { error }) => {
            state.crudDependents.loading = false;
            state.crudDependents.error = error;
            state.crudDependents.data = null;
        },
        [enrolSelectedUserToPolicy.fulfilled as any]: (state, { payload }) => {
            state.crudDependents.loading = false;
            state.crudDependents.error = null;
            state.crudDependents.data = payload.crudDependents.data;
        },
        [removeSelectedUserFromPolicy.pending as any]: (state) => {
            state.crudDependents.loading = true;
        },
        [removeSelectedUserFromPolicy.rejected as any]: (state, { error }) => {
            state.crudDependents.loading = false;
            state.crudDependents.error = error;
            state.crudDependents.data = null;
        },
        [removeSelectedUserFromPolicy.fulfilled as any]: (state, { payload }) => {
            state.crudDependents.loading = false;
            state.crudDependents.error = null;
            state.crudDependents.data = payload.crudDependents.data;
        },
        [addDependent.pending as any]: (state) => {
            state.crudDependents.loading = true;
        },
        [addDependent.rejected as any]: (state, { error }) => {
            state.crudDependents.loading = false;
            state.crudDependents.data = null;
            state.crudDependents.error = error;
        },
        [addDependent.fulfilled as any]: (state, { payload }) => {
            state.crudDependents.loading = payload.crudDependents.loading;
            state.crudDependents.error = payload.crudDependents.error;
            state.crudDependents.data = payload.crudDependents.data;
            // inside data, we are getting the latest updated policy, after adding the dependent
            // so update the currently selected policy with the latest details
            state.currentlySelectedPolicy = payload.crudDependents.data;
            // TODO: we also need to update policyList here, after API issues are fixed
        },
        [editMember.pending as any]: (state) => {
            state.crudDependents.loading = true;
        },
        [editMember.rejected as any]: (state, { error }) => {
            state.crudDependents.loading = false;
            state.crudDependents.data = null;
            state.crudDependents.error = error;
        },
        [editMember.fulfilled as any]: (state, { payload }) => {
            state.crudDependents.loading = payload.crudDependents.loading;
            state.crudDependents.error = payload.crudDependents.error;
            state.crudDependents.data = payload.crudDependents.data;
            // inside data, we are getting the latest updated policy, after adding the dependent
            // so update the currently selected policy with the latest details
            state.currentlySelectedPolicy = payload.crudDependents.data;
            // TODO: we also need to update policyList here, after API issues are fixed
        },
        [fetchPolicyDetails.pending as any]: (state) => {
            state.policyDetails.loading = true;
            state.policyDetails.error = null;
            state.policyDetails.data = null;
        },
        [fetchPolicyDetails.rejected as any]: (state, { error }) => {
            state.policyDetails.error = error;
            state.policyDetails.data = null;
            state.policyDetails.loading = false;
        },
        [fetchPolicyDetails.fulfilled as any]: (state, { payload }) => {
            state.policyDetails.error = null;
            state.policyDetails.data = payload.policyDetails;
            state.policyDetails.loading = false;
        },
        [fetchTopUpAvailabiltyStatus.pending as any]: (state) => {
            state.superTopUpPolicy.loading = true;
            state.superTopUpPolicy.error = null;
            state.superTopUpPolicy.data = null;
        },
        [fetchTopUpAvailabiltyStatus.rejected as any]: (state, { error }) => {
            // if backend returns error, it fails
            state.superTopUpPolicy.error = error;
            state.superTopUpPolicy.data = null;
            state.superTopUpPolicy.loading = false;
        },
        [fetchTopUpAvailabiltyStatus.fulfilled as any]: (state, { payload }) => {
            state.superTopUpPolicy.error = null;
            state.superTopUpPolicy.data = payload.superTopUpPolicy.data?.data;
            state.superTopUpPolicy.loading = false;
        },
        [enrolUserPolicyFamily.pending as any]: (state) => {
            state.enrolUserFamily.loading = true;
            state.enrolUserFamily.error = null;
            state.enrolUserFamily.data = null;
        },
        [enrolUserPolicyFamily.rejected as any]: (state, { error }) => {
            state.enrolUserFamily.error = error;
            state.enrolUserFamily.data = null;
            state.enrolUserFamily.loading = false;
        },
        [enrolUserPolicyFamily.fulfilled as any]: (state, { payload }) => {
            state.enrolUserFamily.loading = false;
            state.enrolUserFamily.error = null;
            state.enrolUserFamily = payload.enrolUserFamily.data;
        },
        'common/cleanup': (state) => {
            state.currentlySelectedPolicy = initialState.currentlySelectedPolicy;
            state = initialState;
        }
    }
});
export default enrolmentSlice.reducer;
export {
    addDependent,
    fetchTopUpAvailabiltyStatus,
    fetchTopUpOptions,
    editMember,
    updateSelectedTopUp,
    removeSelectedUserFromPolicy,
    fetchPolicyDetails
};
export const {
    setCurrentlySelectedPolicy,
    setSelectedMemberTopUp,
    resetCrudErrors,
    resetState,
    resetUpdateTopUpOption,
    updateEnrolmentStatus
} = enrolmentSlice.actions;
