import { ITabItem } from './types';

export enum IPolicyTabs {
    Inclusions = 'Inclusions',
    Exclusion = 'Exclusion'
}
export const tabItems: ITabItem[] = [
    {
        id: IPolicyTabs.Inclusions,
        value: 'What’s Included'
    },
    {
        id: IPolicyTabs.Exclusion,
        value: 'What’s not included'
    }
];
