import React from 'react';
import { TopUpDetailItemContainer } from './styles';
import { ITopUpDetailItem } from './types';

const TopUpDetailItem: React.FunctionComponent<ITopUpDetailItem> = ({
    color,
    borderBottom,
    borderTop,
    ...props
}) => {
    return (
        <TopUpDetailItemContainer
            $borderTop={borderTop}
            $color={color}
            $borderBottom={borderBottom}
        >
            {props.children}
        </TopUpDetailItemContainer>
    );
};

export default TopUpDetailItem;
