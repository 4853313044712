import styled from 'styled-components';
import theme from '../../../theme';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.white};
    padding: 40px 60px;
    align-items: center;
    @media only screen and (max-width: 768px) {
        flex-direction: column;

        padding: 40px 20px 20px 20px;
    }
`;

export const TraditionalContainer = styled.div<{
    $backgroundColor?: string;
    $margin?: string;
    $padding?: string;
    $borderRadius: string;
}>`
    background-color: ${(p) => p.$backgroundColor || theme.colors.lightGreen};
    padding: ${(p) => p?.$padding || '50px 0px 50px 20px'};
    display: flex;
    flex-direction: column;
    border-radius: ${(p) => p?.$borderRadius || '10px'};
    margin: ${(p) => p.$margin};
    width: 32%;
    max-height: 420px;
    &:last-child {
        margin: 0;
    }
    @media only screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 50px;
    }
`;

export const TraditionalContainerLoop = styled.div<{
    $backgroundColor?: string;
    $margin?: string;
    $padding?: string;
    $borderRadius: string;
}>`
    background-color: ${(p) => p.$backgroundColor || theme.colors.lightGreen};
    padding: ${(p) => p?.$padding || '50px 0px 50px 20px'};
    display: flex;
    flex-direction: column;
    border-radius: ${(p) => p?.$borderRadius || '10px'};
    margin: ${(p) => p.$margin};
    width: 32%;
    //height: 415px;
    margin-top: -50px;
    &:last-child {
        margin: 0;
    }
    @media only screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 50px;
    }
`;

export const CoverageContainer = styled.div<{ $margin?: string; $direction?: string }>`
    display: flex;
    flex-direction: ${(p) => p.$direction ?? 'row'};
    justify-content: center;
    align-items: center;
    /* height: 100px; */
    margin: ${(p) => p.$margin};
    width: 100%;
`;

export const TopUpDetailContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0px 20px 0px 0px;
    width: 36%;
    @media only screen and (max-width: 768px) {
        margin: 0px;
    }
`;

export const TopUpDetailItemContainer = styled.div<{
    $borderTop?: string;
    $borderBottom?: string;
    $color?: string;
}>`
    display: flex;
    align-items: center;
    width: 100%;
    border-top: ${(p) => p.$borderTop};
    border-bottom: ${(p) => p.$borderBottom};
    color: ${(p) => p.$color};
`;

export const TopUpOfferContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

export const StyledImg = styled.img`
    width: 90px;
`;

export const StyledPoweredWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledDividerContainer = styled.div`
    margin: 27px 0px;
    border: 1px solid ${theme.colors.loopEmerald};
    opacity: 0.3;
    @media only screen and (max-width: 768px) {
        display: none;
    }
`;

export const TopUpFeatureContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 120px;
    @media only screen and (max-width: 768px) {
        margin: 0px;
        display: none;
    }
`;

export const StyledHeaderWrapper = styled.div`
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    @media only screen and (max-width: 768px) {
        align-items: flex-start;
    }
`;

export const TopUpFeatureContainerResponsive = styled.div`
    display: none;
    flex-direction: column;
    width: 100%;
    padding: 20px 16px;
    gap: 24px;
    border-radius: 12px;
    margin: 20px 0px 0px 0px;
    background-color: ${theme.colors.loopEmerald};
    @media only screen and (max-width: 768px) {
        display: flex;
    }
`;

export const StyledItemContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 4px;
    @media only screen and (max-width: 768px) {
    }
`;
export const StyledItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    @media only screen and (max-width: 768px) {
    }
`;

export const StyledLoopItemCard = styled.div`
    display: flex;
    padding: 12px 16px;
    background-color: ${theme.colors.white};
    border-radius: 12px;
    @media only screen and (max-width: 768px) {
    }
`;

export const StyledOtherItemCard = styled.div`
    display: flex;
    padding: 12px 16px;
    background-color: ${theme.colors.darkTheme};
    border-radius: 12px;
    @media only screen and (max-width: 768px) {
    }
`;

export const StyledHeaderText = styled.div`
    display: flex;
    width: 60%;
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

export const StyledInnerHeaderText = styled.span<{
    $color: string;
}>`
    color: ${(p) => p.$color};
    font-size: ${(p) => p.theme.fontSizes.landing};
    line-height: 40px;
    font-family: 'Work Sans';
    @media only screen and (max-width: 768px) {
        font-size: ${(p) => p.theme.fontSizes.title};
        line-height: 30px;
    }
`;
