import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    min-height: 200px;
    max-height: 500px;
    overflow: scroll;
    width: 700px;
    @media (max-width: 768px) {
        overflow-y: auto;
        margin: 30px 0px 10px 0px;
        width: 100%;
    }
`;

export const StyledHeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
    border-bottom: 1px solid ${(p) => p.theme.colors.surface0};
    justify-content: space-around;
`;

export const StyledContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 80%;
    min-height: 500px;
`;

export const StyledPolicyCoverageIncExcItem = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${(p) => p.theme.colors.platinum};
    padding: 8px 0px;
    gap: 16px;
`;

export const StyledPolicyCoverageIncExcItemHeaderText = styled.div`
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
    flex: 1;

    & > :first-child {
        flex: 1;
    }
    & > :nth-child(2) {
        width: fit-content;
        cursor: pointer;
        padding: 0px 16px;
    }
`;

export const StyledPolicyCoverageIncExcItemLogo = styled.img`
    width: 20px;
    height: 20px;
    aspect-ratio: 1;
`;

export const StyledPolicyCoverageIncExcItemHeader = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 2px;
`;

export const StyledPolicyIncExcList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const StyledPolicyIncExcBody = styled.div`
    display: flex;
    gap: 24px;
`;

export const StyledPolicyIncExcBodyItem = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    gap: 28px;
    flex: 1;
    width: 350px;
    background: ${(p) => p.theme.colors.surface0};
    @media (max-width: 768px) {
        width: 100%;
        margin-top: 24px;
    }
`;

export const StyledPolicyIncExcBodyItemHeader = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
`;

export const StyledPolicyIncExcTitleIcon = styled.img`
    width: 32px;
    height: 32px;
    aspect-ratio: 1;
`;

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
