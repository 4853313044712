import styled from 'styled-components';

export const StyledContainer = styled.div<{
    $item: string;
}>`
    display: flex;
    grid-area: ${(p) => p.$item};
    gap: 10px;
    @media only screen and (max-width: 768px) {
    }
`;

export const StyledContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

export const StyledImgWrapper = styled.div`
    display: flex;
    @media only screen and (max-width: 768px) {
        display: none;
    }
`;
