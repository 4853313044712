import styled from 'styled-components';

export const WidgetContainer = styled.div<{
    $margin?: string;
    $padding?: string;
    $height?: string | null;
    $bgColor?: string;
}>(
    (p) => `
display: flex;
flex-direction: column;
justify-content: center;
margin: ${p.$margin};
background-color: ${p.$bgColor};
border-radius: 20px;
padding: ${p.$padding || '10px 20px 20px 20px'};
// max-height: ${p.$height || '200px'};
flex: 1;
@media only screen and (max-width: 768px) {
    margin:0px;
}
`
);
