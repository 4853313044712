import React from 'react';
import { StyledContainer } from './styles';
import { STEPPER_META_SUPER_TOPUP } from '../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../redux';
import { useHistory, useLocation } from 'react-router-dom';
import { IPolicy } from '../../../redux/slices/PolicyListSlice/types';
import useSegment from '../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';
import { LoopStepper } from '@loophealth/loop-ui-web-library';
import { SuperTopUpSteps } from '../../../utils/constants/StepperConstants';
import { setCurrentStepperStep } from '../../../redux/slices/StepperSlice';

export const Stepper: React.FunctionComponent = () => {
    const history = useHistory();
    const location = useLocation();
    const policyList: IPolicy[] = useSelector((state: ReduxState) => state.policyList.policyList.data) || [];
    const dispatch = useDispatch();
    const currentSelectedStep = useSelector((state: ReduxState) => state.stepperData.currentStepperStep);
    const stepperMetaData = STEPPER_META_SUPER_TOPUP;
    const trackClick = useSegment('click');
    const segmentAction = SEGMENT_ACTIONS.CLICK.ENROLMENT_STEPPER_STEP;

    const getGMCPolicy = () => (policyList ? policyList.find((policy: IPolicy) => policy.policyType === 'GMC') : null);
    const changeStepper = (stepId: number) => {
        if (stepId > currentSelectedStep) return;

        if (getGMCPolicy()?.policyType === 'GMC' && getGMCPolicy()?.enrolmentStatus === 'ENROLED' && stepId === 1) {
            return;
        }
        trackClick({
            name: segmentAction.name,
            properties: {
                describe: segmentAction.properties?.describe || '',
                step: (segmentAction.properties?.step as (step: number) => number)(stepId) || '',
                login_status: segmentAction.properties?.login_status,
                page_title: (segmentAction.properties?.page_title as (pageTitle: string) => string)(
                    stepperMetaData[stepId].name
                )
            }
        });
        dispatch(setCurrentStepperStep({ currentStepperStep: stepId }));
        history.push(stepperMetaData[stepId - 1].stepUrl ?? '', { previousUrl: location.pathname });
    };

    return (
        <StyledContainer>
            <LoopStepper steps={SuperTopUpSteps} currentStep={currentSelectedStep} onStepperClicked={changeStepper} />
        </StyledContainer>
    );
};

export default Stepper;
