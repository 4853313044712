import styled from 'styled-components';

export const StyledContainer = styled.div`
    min-height: 70px;
    height: 70px;
    width: 100%;
    max-width: 100%;
    border-bottom: 1px solid ${(p) => p.theme.colors.border};
    background-color: ${(p) => p.theme.colors.white};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 80px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    @media (max-width: 768px) {
        padding: 0px 20px;
    }
`;
export const SignoutButton = styled.div(
    (p) => `
    color: ${p.theme.colors.loopEmerald};
    font-size: ${p.theme.fontSizes.mediumFont};
    font-weight: 700;
    cursor: pointer;
    border-radius: 8px;
    padding: 10px 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin:0px 18px;
    &:hover {
        text-decoration: underline;
    }
    @media (max-width: 768px) {
       padding: 7px 10px;
    }
`
);
export const SignoutIcon = styled.img`
    @media (min-width: 768px) {
        display: none;
    }
`;
export const SignoutText = styled.div`
    @media (max-width: 768px) {
        /* display: none; */
    }
`;
export const StyledLogo = styled.img`
    max-width: 140px;
    height: auto;
    cursor: pointer;
    width: 60px;
    @media (max-width: 768px) {
        height: 20px;
    }
`;
export const SignoutWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledTalkBtnWrapper = styled.div`
    max-width: 200px;
    @media (max-width: 768px) {
        display: none;
    }
`;

export const StyledLogoutBtnWrapper = styled.div`
    max-width: 90px;
`;
