import styled from 'styled-components';

export const StyledContainer = styled.div(
    (p) => `
    max-width: 46%;
    min-width: 418px;
    height: 160px;
    padding: 24px 16px;
    margin: 15px 0px;
    border-radius: 12px;
    position: relative;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: ${p.theme.colors.white};
    border: 1px solid ${p.theme.colors.seafoamGray};
    @media (max-width: 768px) {
        max-width: 90%;
        min-width: 0px;
        height: auto;
        margin: 0px;
        padding: 0px;
    }
}
`
);

export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-transform: capitalize;
    min-width: 70px;
    @media (max-width: 768px) {
        width: 144px;
    }
`;

export const StyledContentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        margin-top: 10px;
        box-sizing: border-box;
        padding: 0px 16px;
    }
`;

export const StyledNameContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 786px) {
        padding: 24px 0px 0px 16px;
    }
`;

export const MarginContainer = styled.div`
    margin-right: 10px;
`;

export const AbsoluteContainer = styled.div`
    position: absolute;
    top: 15px;
    right: 8px;
    margin: 10px 15px;
`;

export const StyledDeleteImage = styled.img`
    height: 20px;
    width: 20px;
    cursor: pointer;
    margin-left: 12px;
`;

export const StyledEditImage = styled.img`
    height: 20px;
    width: 20px;
    cursor: pointer;
    @media (max-width: 768px) {
        display: none;
    }
`;

export const StyledAlignContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 29px;
    @media screen and (orientation: portrait) {
        width: 100%;
        justify-content: space-between;
        padding: 8px 0px;
        box-sizing: border-box;
    }
`;

export const StyledEditBar = styled.div`
    display: none;
    @media (max-width: 786px) {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        border-top: 1px solid ${(p) => p.theme.colors.seafoamGray};
        padding: 12px;
        align-items: center;
        cursor: pointer;
    }
`;

export const StyledEditIcon = styled.img`
    height: 16px;
    width: 16px;
    margin-left: 4px;
`;
