/* eslint-disable max-len */
import styled from 'styled-components';
import { IChip } from './types';

export const StyledChip = styled.span<{
    variant: IChip['variant'];
    backgroundColor: IChip['backgroundColor'];
    borderRadius: IChip['borderRadius'];
    color: IChip['color'];
    $showTag: boolean;
}>(
    (p) => `
    ${
    p.variant === 'outlined'
        ? `
        border: 1px solid ${p.theme.colors.muted};
    `
        : ''
}
    ${p.backgroundColor ? `background-color: ${p.backgroundColor};` : ''}
    ${
    p.borderRadius
        ? `border-radius: ${typeof p.borderRadius === 'number' ? `${p.borderRadius}px` : p.borderRadius};`
        : ''
}
    max-width: 100%;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    cursor: default;
    outline: 0;
    text-decoration: none;
    padding: 0;
    vertical-align: middle;
    box-sizing: border-box;
    cursor:pointer;
    border-opacity: 0.7;
    ${p.$showTag ? `` : `height: 48px;`}
    ${
    p.variant === 'filled'
        ? `
        background-color: ${p.backgroundColor ? p.backgroundColor : 'rgba(0, 0, 0, 0.08)'};
        border: 0;
        height: 100%;
    `
        : ''
}
 @media (max-width: 768px) {
    align-items: flex-start;
}
`
);

export const StyledLabel = styled.span<{
    color: IChip['color'];
    padding?: string;
}>(
    (p) => `
    ${p.color ? `color: ${p.color};` : ''}
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    ${p.padding ? `padding: ${p.padding};` : ''}
`
);

export const StyledRecommendedTag = styled.span(
    (p) => `
    ${p.color ? `color: ${p.color};` : ''}
    background-color: ${p.theme.colors.coverYellow};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width:100%;
    padding:4px 0px;
    display:flex;
    border-radius:16px 16px 0px 0px;
    align-items: center;
    justify-content: center;
     @media (max-width: 768px) {
        //width: 25px;
        //border-radius: 15px 0px 0px 0px;
    }
}
`
);

export const StyledShowTag = styled.div`
    display: flex;
    @media (max-width: 768px) {
        display none;
    }
`;
