import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteMemberModal, ManageMemberModal, EnrolDependents } from '..';
import { ReduxState } from '../../../redux';
import {
    removeSelectedUserFromPolicy,
    resetCrudErrors,
    setCurrentlySelectedPolicy
} from '../../../redux/slices/EnrolmentSlice';
import { IDependent } from '../../../redux/slices/PolicyListSlice/types';
import { capitalizeFirstLetter, replaceFamilyStructureText } from '../../../utils/common';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';
import useSegment from '../../../utils/hooks/useSegment';
import { familyDefinition, getDependentMappings, IMappings } from '../../../utils/mapping';
import { StyledContainer, StyledAlertContainer } from './styles';
import { IDependentsToDisplay, IEnrolMembers, IRelation } from './types';
import ManageNominees from '../ManageNomineesComponent';
import { getDependentsOnSelectedParentType } from './EnrolMemberService';
import { IRelationship } from '../EnrolDependents/types';
import { Alerts, Toast } from '@loophealth/loop-ui-web-library';
import { checkParentalPolicy } from '../../../utils/PolicyServices';
import { DO_NOT_DELETE_MEMBERS } from '../../../utils/constants/CompanyConstants';

const initialFormData = {
    firstName: '',
    lastName: '',
    mobileNumber: '',
    doB: new Date(),
    gender: '',
    realtionship: '',
    dependentId: ''
};
const EnrolMembers: React.FunctionComponent<IEnrolMembers> = ({
    familyStructure,
    dependents,
    nomineeConsumedPercentage,
    setNomineeConsumedPercentage
}) => {
    const trackClick = useSegment('click');
    const dispatch = useDispatch();
    const loopToast = Toast.useToast();
    const policyId = useSelector((state: ReduxState) => state.enrolment.currentlySelectedPolicy?.policyId || '');
    const isNomineeEnabledForCompany = useSelector(
        (state: ReduxState) => state.stepperData.nomineeConfig.data.isEnabled
    );
    const isNomineeConfigLoading = useSelector((state: ReduxState) => state.stepperData.nomineeConfig.loading);
    const companyId = useSelector((state: ReduxState) => state.user?.userData?.data?.employer);
    const policyList = useSelector((state: ReduxState) => state.policyList.policyList.data);
    const userId = useSelector((state: ReduxState) => state.user?.userData?.data?.userId || '');
    const [editMemberForm, setEditMemberForm] = React.useState<typeof initialFormData>(initialFormData);
    const [currentMapping, setCurrentMapping] = React.useState<null | IMappings>(null);
    const [targetMapping, setTargetMapping] = React.useState<null | IMappings>(null);
    const [filteredDependents, setFilteredDependents] = React.useState<null | IDependentsToDisplay>(null);
    const [category, setCategory] = React.useState<[] | string[]>([]);
    const [selectedParentType, setSelectedParentType] = React.useState('');
    const crudActivity = useSelector((state: ReduxState) => state.enrolment.crudDependents);
    const [currentActivity, setCurrentActivity] = React.useState<null | 'ADD' | 'EDIT' | 'DELETE'>(null);
    const currentlySelectedPolicy = useSelector((state: ReduxState) => state.enrolment.currentlySelectedPolicy);
    const [selectedParentOption, setSelectedParentOption] = React.useState<string>('');
    const [editMemberModal, setEditMemberModal] = React.useState<boolean>(false);
    const [addMemberModal, setAddMemberModal] = React.useState<boolean>(false);
    const [deleteMemberModal, setDeleteMemberModal] = React.useState<boolean>(false);
    const [dependentIdToDelete, setDependentIdToDelete] = React.useState<string>('');
    const [selectedDependentRelation, setSelectedDependentRelation] = React.useState<string>('');
    const [selectedRelation, setSelectedRelation] = React.useState<IRelation>('');

    const isDeleteHidden = DO_NOT_DELETE_MEMBERS.includes(companyId);

    React.useEffect(() => {
        if (crudActivity.data && currentActivity && !crudActivity.error) {
            if (currentActivity === 'ADD') setAddMemberModal(false);
            else if (currentActivity === 'EDIT') setEditMemberModal(false);
            else if (currentActivity === 'DELETE') setDeleteMemberModal(false);
            const action = currentActivity === 'ADD' ? 'added' : currentActivity === 'EDIT' ? 'edited' : 'deleted';
            loopToast?.success(`${selectedRelation} details ${action} successfully`, '', {
                variant: 'dark'
            });
            setCurrentActivity(null);
        }
    }, [crudActivity]);

    React.useEffect(() => {
        const updatedSelectedPolicy = (policyList || [])?.filter((policy) => policy.policyId === policyId)[0];
        dispatch(setCurrentlySelectedPolicy({ currentlySelectedPolicy: updatedSelectedPolicy }));
    }, [policyList]);

    React.useEffect(() => {
        let dependent: IDependentsToDisplay = {};
        const spouseSelf = ['spouse', 'self'];
        const children = dependents.filter((rel) => rel.relationship.toLowerCase() === 'Child'.toLowerCase());
        const selfSpouse = dependents.filter((rel) => spouseSelf.includes(rel.relationship.toLowerCase()));
        const parents = dependents.filter((rel) => ['parent'].includes(rel.relationship.toLowerCase()));
        const parentsInLaw = dependents.filter((rel) => ['parent-in-law'].includes(rel.relationship.toLowerCase()));
        dependent = { selfSpouse, children, parents, parentsInLaw };
        setFilteredDependents(dependent);
    }, [dependents, familyStructure]);

    // get latest target mappings, when familyStructure changes
    React.useEffect(() => {
        // note, dependents length in mapping will always be 1 less.
        // this is because mappings do not consider 'self' count
        if (familyStructure?.length) {
            setTargetMapping(familyDefinition[familyStructure]);
            const dependentTypes = familyStructure.split(' + ');
            setCategory(dependentTypes);
        }
    }, [dependents, familyStructure]);

    // get latest current mappings, when dependents change
    React.useEffect(() => {
        setCurrentMapping(getDependentMappings(dependents, familyStructure));
    }, [dependents, familyStructure]);
    const confirmDeleteDependent = (dependentId: string, relationship: string) => {
        if (relationship == 'Parents') {
            relationship = 'Parent';
        }
        if (relationship == 'Parents-in-law' || relationship == 'Parent-in-laws') {
            relationship = 'Parent-in-law';
        }

        const segmentAction = SEGMENT_ACTIONS.CLICK.ENROLMENT_DELETE_MEMBER_CARD_CONFIRM;
        trackClick({
            name: segmentAction.name,
            properties: {
                describe: (segmentAction.properties?.describe as (depId: string) => string)(dependentId) || '',
                dependent_id: (segmentAction.properties?.dependent_id as (depId: string) => string)(dependentId) || '',
                relation_type:
                    (segmentAction.properties?.relation_type as (relation: string) => string)(
                        getRelation(relationship)
                    ) || ''
            }
        });
        dispatch(
            removeSelectedUserFromPolicy({
                userId: dependentId,
                policyId: policyId || '',
                companyId: companyId || '',
                selfId: userId,
                relationship
            })
        );
        //
    };
    const getDependent = (relationship: string) => {
        const dependentFound = dependents.filter((dep) => dep?.relationship?.toLowerCase() === relationship)[0];
        if (dependentFound) {
            return dependentFound;
        }
        return null;
    };
    const onAddMemberClick = (dependentRelation: string) => {
        setCurrentActivity('ADD');
        setSelectedDependentRelation(dependentRelation);
        setSelectedRelation(dependentRelation as any);
        if (targetMapping && dependents.length <= targetMapping.totalDependents + 1) {
            setSelectedRelation(getRelation(dependentRelation));

            const segmentAction = SEGMENT_ACTIONS.CLICK.ENROLMENT_ADD_MEMBER_CARD;
            trackClick({
                name: segmentAction.name,
                properties: {
                    relation_type:
                        (segmentAction.properties?.relation_type as (relation: string) => string)(
                            getRelation(dependentRelation)
                        ) || '',
                    login_status: segmentAction.properties?.login_status,
                    page_title: segmentAction.properties?.page_title
                }
            });
            dispatch(resetCrudErrors());
            setAddMemberModal(true);
        }
    };
    const onMemberDeleteClick = (id: string, dependentRelation: string) => {
        setCurrentActivity('DELETE');
        const segmentAction = SEGMENT_ACTIONS.CLICK.ENROLMENT_DELETE_MEMBER_CARD;
        trackClick({
            name: segmentAction.name,
            properties: {
                describe: (segmentAction.properties?.describe as (depId: string) => string)(id) || '',
                dependent_id: (segmentAction.properties?.dependent_id as (depId: string) => string)(id) || '',
                relation_type:
                    (segmentAction.properties?.relation_type as (relation: string) => string)(
                        getRelation(dependentRelation)
                    ) || '',
                login_status: segmentAction.properties?.login_status,
                page_title: segmentAction.properties?.page_title
            }
        });
        setSelectedRelation(getRelation(dependentRelation));
        setDependentIdToDelete(id);
        dispatch(resetCrudErrors());
        setDeleteMemberModal(true);
    };
    const onMemberEditClick = (dep: IDependent) => {
        setCurrentActivity('EDIT');
        setSelectedDependentRelation(dep.relationship);
        const segmentAction = SEGMENT_ACTIONS.CLICK.ENROLMENT_EDIT_MEMBER_CARD;
        trackClick({
            name: segmentAction.name,
            properties: {
                describe: (segmentAction.properties?.describe as (depId: string) => string)(dep.userId) || '',
                dependent_id: (segmentAction.properties?.dependent_id as (depId: string) => string)(dep.userId) || '',
                login_status: segmentAction.properties?.login_status,
                page_title: segmentAction.properties?.page_title,
                relation_type:
                    (segmentAction.properties?.relation_type as (relation: string) => string)(dep.relationship) || ''
            }
        });
        const relation: any = dep?.relationship?.charAt(0).toUpperCase() + dep?.relationship?.slice(1) || '';
        setSelectedRelation(relation);
        setEditMemberForm({
            firstName: dep.firstName,
            lastName: dep.lastName,
            mobileNumber: dep.mobile,
            doB: dep?.doB ? new Date(dep.doB * 1000) : new Date(),
            gender: dep?.gender,
            realtionship: dep?.relationship?.charAt(0).toUpperCase() + dep?.relationship?.slice(1),
            dependentId: dep.userId
        });
        dispatch(resetCrudErrors());
        setEditMemberModal(true);
    };

    const getFilteredDependents = (category: string) => {
        const children = ['1 Kid', '2 Kids', '3 Kids', '4 Kids'];
        const oneOfParentsOrInLawsOptions = ['Parents OR parents-in-law', 'Parents OR parent-in-laws'];
        const anyOfParentsOrInLawsOptions = ['Parents (including in-laws)'];

        if (category === 'Spouse' || category === 'Self') return filteredDependents?.selfSpouse || [];
        else if (children.includes(category)) return filteredDependents?.children || [];
        else if (category === 'Parents' || category === 'Parents Only') return filteredDependents?.parents || [];
        else if (category === 'Parents-in-law') return filteredDependents?.parentsInLaw || [];
        else if (oneOfParentsOrInLawsOptions.includes(category)) {
            if (filteredDependents?.parents && filteredDependents?.parents?.length > 0) {
                return filteredDependents?.parents || [];
            }
            return filteredDependents?.parentsInLaw || [];
        } else if (anyOfParentsOrInLawsOptions.includes(category)) {
            return [...(filteredDependents?.parents ?? []), ...(filteredDependents?.parentsInLaw ?? [])];
        } else return [];
    };

    const getTitle = (index: number) => {
        if (category.length === 1 && currentlySelectedPolicy && !checkParentalPolicy(currentlySelectedPolicy)) {
            return 'Your details';
        } else if (category.length === 2 || index === 1) {
            // For Self + Spouse title
            return `You and Your Spouse`;
        } else if (category.length > 4 && index === 3) {
            // For Parents + Parents-in-law title
            return `${category[3]} + ${category[4]}`;
        } else if (index === 2) {
            // For Children
            return `${targetMapping?.dependents?.[index - 1].number} Children`;
        } else {
            // For Parents
            return targetMapping?.dependents?.[index - 1]?.number
                ? targetMapping?.dependents?.[index - 1]?.number + ' ' + category[index]
                : category[index];
        }
    };

    const getRelation = (relationship: string): IRelation => {
        const children = ['1 Kid', '2 Kids', '3 Kids', '4 Kids'];
        const oneOfParentsOrInLawsOptions = [
            'Parents OR parents-in-law',
            'Parents OR parent-in-laws',
            'Parents Only',
            'Parents (including in-laws)',
            'Parents'
        ];
        if (relationship === 'Spouse') {
            return 'Spouse';
        } else if (children.includes(relationship)) {
            return 'Child';
        } else if (oneOfParentsOrInLawsOptions.includes(relationship)) {
            if (selectedParentOption === 'Parent') {
                return 'Parent';
            }
            return 'Parent-in-law';
        } else if (relationship === 'Parents-in-law' || relationship === 'Parent-in-laws') {
            return 'Parent-in-law';
        } else {
            return relationship as IRelation;
        }
    };

    return (
        <StyledContainer>
            { isDeleteHidden &&
                <StyledAlertContainer>
                    <Alerts.TextAlert
                        variant='success'
                        title='This portal allows only additions and edits; deletions of dependents is not permitted.'
                        closable={false}
                    />
                </StyledAlertContainer>
            }
            <>
                {Array.from(Array(category.length ?? 0), (_element, index) => {
                    if (category.length === 1 || category[index] !== 'Self' || index !== 0) {
                        return (
                            <EnrolDependents
                                key={index}
                                title={capitalizeFirstLetter(replaceFamilyStructureText(getTitle(index)))}
                                category={category[index]}
                                filteredDependents={getFilteredDependents(category[index])}
                                currentMapping={currentMapping}
                                targetMapping={targetMapping}
                                onAddMemberClick={() => onAddMemberClick(category[index])}
                                onMemberEditClick={(dep: IDependent) => onMemberEditClick(dep)}
                                onMemberDeleteClick={(id: string) => onMemberDeleteClick(id, category[index])}
                                relationship={getRelation(category[index])}
                                selectedRelationship={(
                                    relation: 'Self' | 'Spouse' | 'Child' | 'Parent' | 'Parent-in-law' | ''
                                ) => setSelectedParentType(relation)}
                                setSelectedParentOption={setSelectedParentOption}
                            />
                        );
                    }
                })}
                {isNomineeEnabledForCompany && !isNomineeConfigLoading && (
                    <Toast.ToastContainer
                        maxWidth="800px"
                        expires={7000}
                        closeOnClick={true}
                        variant="light"
                        position="top-center"
                    >
                        <ManageNominees
                            userId={userId}
                            policyId={policyId}
                            consumedPercentage={nomineeConsumedPercentage}
                            setConsumedPercentage={setNomineeConsumedPercentage}
                        />
                    </Toast.ToastContainer>
                )}
            </>

            <DeleteMemberModal
                setIsVisible={setDeleteMemberModal}
                isVisible={deleteMemberModal}
                confirmDelete={confirmDeleteDependent}
                dependentId={dependentIdToDelete}
                selectedRelationship={selectedRelation}
            />

            <ManageMemberModal
                isEditMode={true}
                {...(editMemberForm as any)}
                firstName={editMemberForm.firstName}
                lastName={editMemberForm.lastName}
                self={getDependent('self')}
                spouse={getDependent('spouse')}
                shouldShowSelf={dependents.filter((dep) => dep?.relationship?.toLowerCase() === 'self').length <= 0}
                currentMapping={currentMapping}
                targetMapping={targetMapping}
                isVisible={editMemberModal}
                setIsVisible={setEditMemberModal}
                selectedRelationship={selectedRelation}
                selectedDependentRelation={selectedDependentRelation}
            />

            {!editMemberModal && (
                <ManageMemberModal
                    isEditMode={false}
                    self={getDependent('self')}
                    spouse={getDependent('spouse')}
                    shouldShowSelf={dependents.filter((dep) => dep?.relationship?.toLowerCase() === 'self').length <= 0}
                    existingDependents={getDependentsOnSelectedParentType(dependents, selectedParentType)}
                    currentMapping={currentMapping}
                    targetMapping={targetMapping}
                    isVisible={addMemberModal}
                    setIsVisible={setAddMemberModal}
                    selectedRelationship={selectedParentType as IRelationship}
                    selectedDependentRelation={selectedDependentRelation}
                />
            )}
        </StyledContainer>
    );
};

export default EnrolMembers;
