import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 500px;
    text-align: center;
`;

export const StyledDescriptionContainer = styled.div`
    background-color: ${(p) => p.theme.colors.backgroundSurface};
    margin: 32px;
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 8px;
    border-radius: 8px;
    box-sizing: border-box;
    @media (max-width: 768px) {
        margin: 24px 20px 32px 20px;
    }
`;

export const StyledTitle = styled.div`
    font-size: ${(p) => p.theme.fontSizes.title1};
    font-weight: 500;
    line-height: 28px;
    color: ${(p) => p.theme.colors.loopEmerald};
    margin-top: 24px;
    @media (max-width: 768px) {
        font-size: ${(p) => p.theme.fontSizes.heading1};
        padding: 5px 20px;
    }
`;

export const StyledButtonContainer = styled.div`
    padding: 16px 20px;
    border-top: 1px solid ${(p) => p.theme.colors.seafoamGray};
`;

export const StyledImgBackground = styled.div`
    display: flex;
    width: 500px;
    height: 178px;
    margin-bottom: 24px;
    align-items: center;
    justify-content: center;
    background-color: ${(p) => p.theme.colors.mutedTransparent};
    @media (max-width: 768px) {
        width: auto;
        height: 128px;
    }
`;

export const StyledIcon = styled.img`
    width: 196px;
    height: 196px;
    @media (max-width: 768px) {
        width: 141px;
        height: 141px;
    }
`;
