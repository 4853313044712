import React from 'react';
import { Typography } from '@loophealth/loop-ui-web-library';
import { StyledWarningWrapper } from './styles';

export const EnrolmentDateAlert: React.FunctionComponent = () => {
    return (
        <StyledWarningWrapper>
            <Typography variant="small" color="error" weight="bold">
                Registration period has ended
            </Typography>
            <Typography variant="small" color="error">
                You cannot register in any policies or make changes to existing policies. Please reach out to your HR
                for any assistance.
            </Typography>
        </StyledWarningWrapper>
    );
};
