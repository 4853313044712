import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    StyledContainer,
    StyledButtonContainer,
    StyledIcon,
    StyledImgBackground,
    StyledClose,
    StyledBodyContainer,
    StyledDivider,
    StyledWrapper,
    StyledIconContainer,
    StyledTagWrapper
} from './styles';
import { ReduxState } from '../../../redux';
import Lottie from 'lottie-react';
import { LoopButton, LoopTag, Modal, Typography } from '@loophealth/loop-ui-web-library';
import { ArrowRightEmerald, CloseIcon, FilledStar, ShieldIcon, SuccessAnimation } from '../../../assets/img';
import { ISummaryModal } from './types';
import { INTERNAL_ROUTES } from '../../../utils/constants';
import { useHistory } from 'react-router';
import useSegment from '../../../utils/hooks/useSegment';
import { getDaysLeft, getFullInsuranceName, getPolicyCardTrackObject, getSumInsured } from '../../../utils/common';
import { setCurrentlySelectedPolicy } from '../../../redux/slices/EnrolmentSlice';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';
import useResponsive from '../PolicyInclusionsModal/hooks/useResponsive';
import { redirectToMobileStore } from '../../pages/SPAContainer/pages/DashboardPage/utils';
import { REDIRECT_URL } from '../../../utils/constants/RedirectConstants';
import DownloadAppModal from '../DownloadAppCard/DownloadAppModal';

const EnrolSuccessModal: React.FunctionComponent<ISummaryModal> = ({
    isVisible,
    setIsVisible,
    enrolPolicy,
    policiesLeft
}) => {
    const history = useHistory();
    const trackClick = useSegment('click');
    const dispatch = useDispatch();
    const currentlySelectedPolicy = useSelector((state: ReduxState) => state.enrolment.currentlySelectedPolicy);
    const isDesktop = useResponsive('(min-width: 768px)');
    const isCompleted = enrolPolicy ? false : true;
    const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
    const handleSubmit = () => {
        if (enrolPolicy) {
            trackClick(getPolicyCardTrackObject(enrolPolicy));
            if (!(getDaysLeft(enrolPolicy.enrolmentDueDate) === 'OVERDUE')) {
                dispatch(setCurrentlySelectedPolicy({ currentlySelectedPolicy: enrolPolicy }));
                history.push(INTERNAL_ROUTES.enrolment, { previousUrl: location.pathname });
            }
        }
        setIsVisible(false);
    };
    const handleDownloadClick = () => {
        const segmentAction = SEGMENT_ACTIONS.CLICK.DASHBOARD_PAGE_DOWNLOAD_APP;
        trackClick({
            name: segmentAction.name,
            properties: {
                page_url: (segmentAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                page_id: (segmentAction.properties?.page_id as (path: string) => string)(location.pathname) || '',
                login_status: segmentAction.properties?.login_status,
                banner_position: (segmentAction.properties?.banner_position as (position: string) => string)('Bottom')
            }
        });
        if (isDesktop) {
            setIsModalVisible(true);
        } else {
            redirectToMobileStore(REDIRECT_URL.STORE);
        }
    };
    return (
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} disablePadding={true} isModalClosable={false}>
            <StyledContainer>
                <StyledImgBackground>
                    <StyledClose src={CloseIcon} onClick={() => setIsVisible(false)} />
                    <Lottie
                        animationData={SuccessAnimation}
                        loop={true}
                        alt="Enrolment Success"
                        style={{ width: '200px' }}
                    />
                </StyledImgBackground>
                <StyledTagWrapper>
                    <LoopTag variant="default" iconSrc={FilledStar} iconOrder={'left'}>
                        Congratulations!
                    </LoopTag>
                </StyledTagWrapper>
                {isCompleted ? (
                    <StyledBodyContainer>
                        <StyledWrapper>
                            <Typography variant="large" weight="bold">
                                You’re all done!
                            </Typography>
                            <Typography variant="medium" weight="medium" color="secondary">
                                You have successfully registered for all your policies. Now download the Loop App to
                                enjoy your benefits.
                            </Typography>
                            <LoopButton variant="filled" size="small" onClick={handleDownloadClick}>
                                Download Loop App
                            </LoopButton>
                        </StyledWrapper>
                    </StyledBodyContainer>
                ) : (
                    <StyledBodyContainer>
                        <StyledWrapper>
                            <Typography variant="large" weight="bold">
                                Registration Successful!
                            </Typography>
                            {currentlySelectedPolicy && (
                                <Typography variant="medium" weight="medium" color="secondary">
                                    {'You now have '}
                                    <Typography variant="medium" weight="bold">
                                        Rs. {getSumInsured(currentlySelectedPolicy.sumInsured, '')} coverage
                                    </Typography>
                                    {'  active under your '}
                                    <Typography variant="medium" weight="bold">
                                        {getFullInsuranceName(currentlySelectedPolicy.policyType)}
                                    </Typography>
                                </Typography>
                            )}
                        </StyledWrapper>
                        <StyledDivider />
                        <StyledButtonContainer>
                            <StyledIconContainer>
                                <StyledIcon src={ShieldIcon} />
                            </StyledIconContainer>
                            <StyledWrapper>
                                <Typography variant="small" weight="semiBold">
                                    {policiesLeft} {policiesLeft > 1 ? 'policies' : 'policy'} pending registration
                                </Typography>
                                <LoopButton
                                    variant="filled"
                                    size="small"
                                    iconOrder="right"
                                    iconSrc={ArrowRightEmerald}
                                    onClick={handleSubmit}
                                >
                                    Complete My registration
                                </LoopButton>
                            </StyledWrapper>
                        </StyledButtonContainer>
                    </StyledBodyContainer>
                )}
            </StyledContainer>
            <DownloadAppModal isVisible={isModalVisible} setIsVisible={setIsModalVisible} />
        </Modal>
    );
};

export default EnrolSuccessModal;
