import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const StyledContainerUNAvailable = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledContainerAvailable = styled.div`
    width: 100%;
    display: flex;
`;

export const StyledLoaderContainer = styled.div`
    position: absolute;
    top: 30%;
    left: 50%;
    text-align: center;
    display: flex;
    gap: 30px;
    flex-direction: column;
    @media only screen and (max-width: 768px) {
        left: 10%;
    }
`;
