import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: grid;
    grid-template:
        'stepStatus stepStatus'
        'headingWrapper buttonWrapper'
        'detailsWrapper detailsWrapper';
    background-color: ${(p) => p.theme.colors.white};
    border-radius: 12px;
    flex-direction: column;
    gap: 16px;
    padding: 32px;
    /* width: 100%; */
    @media only screen and (max-width: 768px) {
        grid-template:
            'stepStatus'
            'headingWrapper'
            'detailsWrapper'
            'buttonWrapper';
    }
`;

export const StyledWrapper = styled.div`
    display: flex;
    width: 190px;
    height: 48px;
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

export const StyledButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    grid-area: buttonWrapper;
    @media only screen and (max-width: 768px) {
        justify-content: normal;
        padding-top: 12px;
        border-top: 1px dashed ${(p) => p.theme.colors.seafoamGray};
    }
`;

export const StyledIcon = styled.img`
    display: flex;
    grid-area: mobileIcon;
    @media (max-width: 786px) {
        height: 44px;
        width: 44px;
    }
`;

export const StyledModalContainer = styled.div`
    display: flex;
    width: 100%;
    max-height: 500px;
    overflow-y: scroll;
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;
    ::-webkit-scrollbar {
        display: none;
    }
`;

export const StyledModalContent = styled.div`
    display: flex;
    width: 100%;
    background-color: ${(p) => p.theme.colors.white};
    gap: 16px;
`;

export const StyledPhoneImg = styled.img`
    display: flex;
    width: 350px;
`;

export const StyledQRImg = styled.img`
    display: flex;
    width: 154px;
    margin-top: 12px;
    margin-left: auto;
    margin-right: auto;
`;

export const StyledQRWrapper = styled.div`
    display: flex;
    width: 100%;
    gap: 8px;
    border-radius: 12px;
    background-color: ${(p) => p.theme.colors.mutedTransparent};
    flex-direction: column;
    padding: 28px;
`;

export const StyledHighlightIcon = styled.img`
    left: 56%;
    top: -50%;
    position: absolute;
`;

export const StyledHeaderWrapper = styled.div`
    display: flex;
    position: relative;
`;

export const StyledAppsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: -18px;
`;

export const StyledAppIcon = styled.img`
    display: flex;
`;
export const StyledPlayIcon = styled.img`
    display: flex;
    width: 20px;
`;

export const StyleLoopTagWrapper = styled.div`
    grid-area: stepStatus;
    margin-bottom: 4px;
`;

export const StyledContentWrapper = styled.div`
    display: flex;
    grid-area: detailsWrapper;
`;

export const StyledHeadingWrapper = styled.div`
    display: flex;
    gap: 8px;
    grid-area: headingWrapper;
    align-items: center;
`;

export const StyledModalWrapper = styled.div`
    display: flex;
    width: 100%;
`;

