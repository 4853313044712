import React, { useState } from 'react';
import { LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import {
    StyledPolicyCoverageIncExcItem,
    StyledPolicyCoverageIncExcItemHeader,
    StyledPolicyCoverageIncExcItemHeaderText,
    StyledPolicyCoverageIncExcItemLogo
} from './styles';
import { IPolicyCoverageIncExcListItem } from './types';
import { ArrowDownEmerald, ArrowUpEmerald, CrossCircleIcon, SuccessCheckIcon } from '../../../assets/img';

const PolicyCoverageIncExcItem: React.FC<IPolicyCoverageIncExcListItem> = ({ term, isExclusion }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const toggleExpanded = () => setIsExpanded((isExpanded) => !isExpanded);
    const isExpandable = !!(!isExclusion && term.value);

    return (
        <StyledPolicyCoverageIncExcItem>
            <StyledPolicyCoverageIncExcItemHeader>
                <StyledPolicyCoverageIncExcItemLogo src={isExclusion ? CrossCircleIcon : SuccessCheckIcon} />
                <StyledPolicyCoverageIncExcItemHeaderText>
                    <Typography variant="small">{term.label}</Typography>
                    {isExpandable && (
                        <LoopButton
                            variant="text"
                            size="small"
                            onClick={toggleExpanded}
                            iconSrc={isExpanded ? ArrowUpEmerald : ArrowDownEmerald}
                            iconOrder="right"
                        />
                    )}
                </StyledPolicyCoverageIncExcItemHeaderText>
            </StyledPolicyCoverageIncExcItemHeader>
            {isExpandable && isExpanded && (
                <Typography variant="extraSmall" color="secondary">
                    {term.value}
                </Typography>
            )}
        </StyledPolicyCoverageIncExcItem>
    );
};

export default PolicyCoverageIncExcItem;
