import styled from 'styled-components';

export const EnrollMembersContainer = styled.div`
    display: flex;
    margin-bottom: 16px;
    width: 100%;
    flex-wrap: wrap;
    gap: 24px;
    @media (max-width: 768px) {
        width: 100%;
        justify-content: flex-start;
        flex-direction: column;
        gap: 12px;
        background-color: ${(p) => p.theme.colors.white};
        padding: 24px 20px;
        margin: 0px;
    }
`;

export const StyledDependentText = styled.div(
    (p) => `
    color: ${p.theme.colors.black};
    font-size: ${p.theme.fontSizes.body};
    line-height: 18px;
    font-weight: bold;
    display:flex;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin: 24px 0px 16px 20px;
    }
`
);

export const StyledDependentSubText = styled.div(
    (p) => `
    color: ${p.theme.colors.mutedBG};
    font-size: ${p.theme.fontSizes.body};
    line-height: 17px;
    font-weight: 500;
    margin-left: 8px;
    @media (max-width: 768px) {
        margin: 0px;
        line-height: 20px;
    }
`
);

export const StyledWarning = styled.div`
    border-radius: 10px;
    max-width: 860px;
    margin: 8px 0px;
    @media (max-width: 768px) {
        margin: 0px 20px;
    }
`;

export const StyledSpacing = styled.div`
    margin: 20px 0px 0px 0px;
    @media (max-width: 768px) {
        margin: 0px 20px 12px 20px;
    }
`;
