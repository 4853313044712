import React from 'react';
import { Check, LoopLogo } from '../../../assets/img';
import theme from '../../../theme';
import { ImageView } from '../../atoms/ImageView';
import { TextView } from '../../atoms/TextView';
import { CoverageContainer, TraditionalContainerLoop, TopUpOfferContainer } from './styles';

const TopUpGivenCoverage: React.FunctionComponent = () => {
    const commonImgProps = {
        width: '20px',
        containerWidth: '32px',
        containerMinHeight: '32px',
        backgroundColor: theme.colors.teamTurquoise,
        src: Check,
        radius: '50%'
    };
    const commonTextProps = {
        fontSize: '16px',
        fontWeight: '400',
        margin: '0px 0px 0px 15px'
    };
    return (
        <TraditionalContainerLoop
            $margin="0px 15px 0px 0px"
            $backgroundColor={theme.colors.loopEmerald}
            $borderRadius={'10px'}
            $padding={'40px 0px'}
        >
            <TopUpOfferContainer>
                <ImageView height="40px" src={LoopLogo} />
            </TopUpOfferContainer>
            <CoverageContainer $margin="40px 0px 60px 0px">
                <ImageView {...commonImgProps} />
                <TextView {...commonTextProps}>Covered from Day 1</TextView>
            </CoverageContainer>
            <CoverageContainer $margin="0px 0px 60px 0px">
                <ImageView {...commonImgProps} />
                <TextView {...commonTextProps}>Yes, upto 90 years</TextView>
            </CoverageContainer>
            <CoverageContainer $margin="0px 0px 60px 0px">
                <ImageView {...commonImgProps} />
                <TextView {...commonTextProps}>
                    None at all&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </TextView>
            </CoverageContainer>
            <CoverageContainer $margin="10px 0px 60px 0px">
                <ImageView {...commonImgProps} />
                <TextView {...commonTextProps}>
                    Hassle-free with <br /> 24*7 support
                </TextView>
            </CoverageContainer>
        </TraditionalContainerLoop>
    );
};

export default TopUpGivenCoverage;
