import React from 'react';
import { TextView } from '../../atoms/TextView';
import { ITopupCallback } from './types';
import theme from '../../../theme';
import useFormInput from '../../../utils/hooks/useFormInput';
import {
    ButtonContainer,
    Container,
    StyledCheckboxSection,
    StyledImg,
    StyledContainer,
    StyledInfoContainer,
    StyledErrorText
} from './styles';
import { CallIcon } from '../../../assets/img';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../redux';
import { LoopButton, Modal, Typography } from '@loophealth/loop-ui-web-library';
import { CountryCodeCard, Input } from '../../atoms';
import { postTopUpUserData, selectTopUpUserData } from '../../../topup-src/redux/slices/TopUpLandingSlice';
import { validateMobile } from '../../../topup-src/utils/common';
import useSegment from '../../../utils/hooks/useSegment';
import { setShowCallbackToolTip } from '../../../topup-src/redux/slices/ApplicationStateSlice';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';

const TopupCallback: React.FunctionComponent<ITopupCallback> = ({ visible, setVisible }) => {
    const trackClick = useSegment('click');
    const dispatch = useDispatch();
    const userData = useSelector((state: ReduxState) => state.TopUp_user?.userData?.data);
    const error = '';
    const topUpUserData = useSelector(selectTopUpUserData);
    const emailInput = useFormInput(userData?.email);
    const mobileInput = useFormInput(userData?.mobile);
    const onGetCallBack = () => {
        const mobile = mobileInput.value;
        const email = emailInput.value;
        const segmentAction = SEGMENT_ACTIONS.CLICK.SCHEDULE_A_CALLBACK;
        trackClick({
            name: segmentAction.name,
            properties: {
                page_title: segmentAction.properties?.page_title,
                login_status: segmentAction.properties?.login_status,
                page_url: (segmentAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                page_id: (segmentAction.properties?.page_id as (path: string) => string)(location.pathname) || '',
                mobile: mobile,
                email_entered: email
            }
        });
        dispatch(
            postTopUpUserData({
                email,
                mobile,
                event: 'explore_topup'
            })
        );
        setVisible(false);
        dispatch(setShowCallbackToolTip({ showCallbackToolTip: true }));
    };
    return (
        <>
            <Modal
                isVisible={visible}
                title={'We’re here for all your queries!'}
                setIsVisible={() => setVisible(!visible)}
            >
                <StyledContainer>
                    <TextView fontSize="18px" margin={'0px 0px 30px 0px'} color={theme.colors.loopEmerald}>
                        Our insurance expert will contact you within 24 hours.
                    </TextView>
                    <TextView fontSize="16px" color={theme.colors.beyondGrey}>
                        Your Contact No.
                    </TextView>
                    <Container $margin="12px 0px 0px 0px">
                        <Container $margin="0px">
                            <CountryCodeCard label="+91" width="76px" margin="0px 8px 0px 0px" />
                            <Input
                                placeholder={'Mobile Number'}
                                {...mobileInput}
                                name={'phone'}
                                maxLen={10}
                                type={'number'}
                                setBorder={false}
                            />
                        </Container>
                    </Container>
                    <TextView
                        fontSize={'14px'}
                        textAlign={'end'}
                        color={theme.colors.error}
                        margin={'12px 0px 0px 0px'}
                    >
                        {mobileInput.value?.length > 9
                            ? validateMobile(mobileInput.value)
                                ? ''
                                : 'Enter Valid Phone'
                            : null}
                    </TextView>
                    {!!error.length && <StyledErrorText>{error}</StyledErrorText>}
                    <Container $margin="20px 0px 0px 0px">
                        <ButtonContainer>
                            <LoopButton
                                onClick={onGetCallBack}
                                variant={
                                    topUpUserData.loading === true || !validateMobile(mobileInput.value)
                                        ? 'disabled'
                                        : 'filled'
                                }
                            >
                                {topUpUserData.loading === true ? 'Please wait...' : 'Get a Call Back'}
                            </LoopButton>
                        </ButtonContainer>
                    </Container>
                    <StyledCheckboxSection>
                        <TextView
                            fontSize="16px"
                            margin="20px 24px 0px 0px"
                            lineHeight={'20px'}
                            color={theme.colors.loopEmerald}
                        >
                            OR Call us right away!
                        </TextView>
                        <StyledInfoContainer>
                            <StyledImg src={CallIcon} />
                            <Typography variant="medium" color="emerald">
                                080-3783-6789
                            </Typography>
                        </StyledInfoContainer>
                    </StyledCheckboxSection>
                </StyledContainer>
            </Modal>
        </>
    );
};

export default TopupCallback;
