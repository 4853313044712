import styled from 'styled-components';
import theme from '../../../theme';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 60px 20px;
    align-items: center;
    letter-spacing: -0.02em;
    background-color: ${theme.colors.loopEmerald};
    @media only screen and (max-width: 768px) {
        padding: 32px 20px;
        gap: 30px;
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const StyledContainerWrapper = styled.div`
    display: flex;
    width: 100%;
    margin: 20px 0px;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        margin: 0px;
    }
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    width: 260px;
    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const VerticalLine = styled.div`
    width: 1px;
    height: 64px;
    background-color: ${theme.colors.borderLine};
    margin-top: 38px;
    margin-bottom: 38px;
    margin-left: 20px;
    margin-right: 20px;
    @media only screen and (max-width: 768px) {
        display: none;
    }
`;

export const StyledHeaderText = styled.div`
    display: flex;
    width: 60%;
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

export const StyledInnerHeaderText = styled.span<{
    $color: string;
}>`
    color: ${(p) => p.$color};
    font-size: ${(p) => p.theme.fontSizes.landing};
    line-height: 40px;
    font-family: 'Work Sans';
    @media only screen and (max-width: 768px) {
        font-size: ${(p) => p.theme.fontSizes.title};
        line-height: 30px;
    }
`;
