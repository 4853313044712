import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: row;
    min-height: 100px;

    @media (max-width: 768px) {
        flex-direction: column;
        margin-bottom: 100px;
    }
`;

export const StyledMenuContainer = styled.div`
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    position: sticky;
    width: 22%;
    top: 32px;
    @media (max-width: 768px) {
        padding: 0px;
        width: 100%;
    }
`;
export const StyledContentContainer = styled.div`
    display: flex;
    margin: 0px 0px 50px 0px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    @media (max-width: 768px) {
        width: 100%;
        flex-direction: column;
    }
`;
export const NavigatorContainer = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    @media (max-width: 768px) {
        max-width: 100%;
        min-width: 0;
        position: fixed;
        right: 0;
        width: 100%;
    }
`;

export const StyledDivContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 25px;
    margin-top: 45px;
    padding-bottom: 20px;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;
export const StyledTitleText = styled.div(
    (p) => `
    color: ${p.theme.colors.muted};
    font-size: ${p.theme.fontSizes.body};
    text-transform: uppercase;
    font-weight: 700;
`
);

export const StyledContentWrapper = styled.div`
    display: flex;
    padding: 60px 80px;
    width: 100%;
    justify-content: center;
    @media (max-width: 768px) {
        padding: 0px;
        width: 100%;
        margin: 0px;
        flex-direction: column;
    }
`;

export const StyledStickyContainer = styled.div`
    border-top: 1px solid ${(p) => p.theme.colors.seafoamGray};
    background-color: ${(p) => p.theme.colors.white};
    position: sticky;
    bottom: 0px;
    display: flex;
    justify-content: flex-end;
    padding: 20px 70px;
    width: 100%;
    @media (max-width: 768px) {
        padding: 20px 16px;
    }
`;

export const StyledButtonContainer = styled.div`
    width: 314px;
    margin-right: 130px;
    @media (max-width: 768px) {
        width: 100%;
        margin-right: 40px;
    }
`;
