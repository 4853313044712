import React from 'react';
import { DropdownIcon } from '../../../assets/img';
import theme from '../../../theme';
import { ImageView } from '../ImageView';
import { Container, FaqContainer, FaqDescription, FaqTitle } from './styles';
import { IFaqItem } from './types';

const ExpandableFaqItem: React.FunctionComponent<IFaqItem> = ({
    title,
    description,
    margin,
    bgColor = theme.colors.lightGrey
}) => {
    const [isCollapsed, setIsCollapsed] = React.useState(false);
    return (
        <Container $bgColor={bgColor} $margin={margin} onClick={() => setIsCollapsed(!isCollapsed)}>
            <FaqContainer>
                <FaqTitle>{title}</FaqTitle>
                <ImageView
                    rotate={isCollapsed ? '180deg' : '0deg'}
                    src={DropdownIcon}
                    containerMinHeight="18px"
                    width="40%"
                />
            </FaqContainer>
            {isCollapsed && <FaqDescription>{description}</FaqDescription>}
        </Container>
    );
};

export default ExpandableFaqItem;
