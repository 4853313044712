import styled from 'styled-components';

export const StyledPaddingContainer = styled.div`
    width: 100%;
    padding-bottom: 84px;
    @media (max-width: 768px) {
        padding-bottom: 40px;
    }
`;

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
