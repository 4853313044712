import { ITheme } from './types';

const theme: ITheme = {
    colors: {
        black: '#2D3748',
        white: '#ffffff',
        border: '#D4E3EB',
        muted: '#A3A3A3',
        error: '#EB5757',
        primary: '#1BB4A4',
        secondary: '#F7F8FA',
        primaryBG: '#18A294',
        activeBG: '#F1F35',
        secondaryBG: 'rgba(212, 227, 235, 0.2)',
        errorBG: '#FAECEB',
        mutedBG: '#718096',
        coverYellow: '#FDD506',
        planRed: '#FF8080',
        teamTurquoise: '#36D6C3',
        primaryTransparent: 'rgba(27, 180, 164, 0.2)',
        mutedTransparent: '#E6EFED',
        darkTheme: '#0D7963',
        consultViolet: '#A586EF',
        loopEmerald: '#025F4C',
        coverUpGreen: '#BCDD33',
        borderLine: 'rgba(255, 255, 255, 0.5)',
        lightGreen: '#F2F7F6',
        lightGrey: '#F6F6F6',
        midGrey: '#858484',
        beyondGrey: '#595959',
        darkGreen: '#054F40',
        purple: '#a586ef',
        seafoamGray: '#CBD6D3',
        platinum: '#EEEEEE',
        surface0: '#F8F9FA',
        backgroundSurface: '#F8F9FA',
        secondaryText: '#7F8785'
    },
    fontSizes: {
        landing: '30px',
        title: '22px',
        heading: '18px',
        body: '14px',
        sectionHeading: '10px',
        label: '12px',
        subHeading: '16px',
        landingTitle: '54px',
        largeFont: '45px',
        innerheading: '22px',
        mediumFont: '16px',
        title1: '24px',
        heading1: '20px'
    },
    typography: {
        s1SubHeader: {
            'font-family': 'Work Sans',
            'font-size': '24px',
            'line-height': '32px',
            'font-weight': 400
        },
        b1Body: {
            'font-family': 'Work Sans',
            'font-size': '18px',
            'line-height': '28px',
            'font-weight': 400
        }
    }
};

export const colorLibrary = [
    '#066066',
    '#cb3c3a',
    '#7f2341',
    '#009cc3',
    '#81a269',
    '#d67358',
    '#685f80',
    '#b2ab67',
    '#7cb9b2',
    '#877d8e'
];

export default theme;
