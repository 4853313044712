import React from 'react';
import {
    Container,
    StyledLoaderContainer,
    ButtonContainer,
    StyledButtonWrapper,
    StyledHeaderWrapper,
    StyledInnerHeaderText
} from './styles';
import theme from '../../../theme';
import ExpandableFaqItem from '../../atoms/ExpandableFaqItem';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../redux';
import { Loader } from '../../atoms';
import { IFAQ } from './types';

import { LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';
import { getSegmentPageName } from '../../../utils/common';
import useSegment from '../../../utils/hooks/useSegment';
import TopupCallback from '../TopupCallback';

const TopUpFaqContainer: React.FunctionComponent<IFAQ> = ({
    backgroundColor = theme.colors.white
}): React.ReactElement => {
    const trackClick = useSegment('click');
    const currentPageAction = SEGMENT_ACTIONS.PAGE[getSegmentPageName(location.pathname) as any];
    const faqData: any = useSelector((state: ReduxState) => state.TopUp_LandingPage.TopUpDataFaq.data);
    const isLoading = useSelector((state: ReduxState) => state.TopUp_LandingPage.TopUpDataFaq?.loading);
    const [callbackIsVisible, setCallbackIsVisible] = React.useState(false);
    const handleCallExpertClick = () => {
        setCallbackIsVisible(true);
        const segmentAction = SEGMENT_ACTIONS.CLICK.CALL_AN_EXPERT;
        trackClick({
            name: segmentAction.name,
            properties: {
                page_title: segmentAction.properties?.page_title,
                login_status: segmentAction.properties?.login_status,
                page_url: (currentPageAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                page_id: (currentPageAction.properties?.page_id as (path: string) => string)(location.pathname) || '',
                cta_id: (segmentAction.properties?.cta_id as (cta: number) => number)(2)
            }
        });
    };
    return (
        <Container $bgColor={backgroundColor}>
            <StyledHeaderWrapper>
                <StyledInnerHeaderText $color={theme.colors.loopEmerald}>
                    Frequently asked questions
                </StyledInnerHeaderText>
            </StyledHeaderWrapper>
            {isLoading ? (
                <StyledLoaderContainer>
                    <Loader />
                </StyledLoaderContainer>
            ) : (
                <>
                    {faqData?.map((item: { title: string; description: string }, index: number) => (
                        <ExpandableFaqItem
                            key={index}
                            title={item.title}
                            description={item.description}
                            margin={'0px 0px 24px 0px'}
                            bgColor={backgroundColor ? theme.colors.white : backgroundColor}
                        />
                    ))}
                </>
            )}
            <ButtonContainer>
                <Typography variant="title3" color="emerald">
                    Got more questions? We’re there for you!
                </Typography>
                <StyledButtonWrapper>
                    <LoopButton variant="filled" onClick={() => handleCallExpertClick()}>
                        Call an expert
                    </LoopButton>
                </StyledButtonWrapper>
            </ButtonContainer>
            <TopupCallback visible={callbackIsVisible} setVisible={setCallbackIsVisible} />
        </Container>
    );
};

export default TopUpFaqContainer;
