import React, { useEffect, useState } from 'react';
import {
    StyledAlignment,
    StyledButtonContainer,
    StyledFormTip,
    StyledModalContainer,
    StyledStickyContainer
} from './styles';
import { StyledInputGroup, StyledRow, StyledSpan } from '../ManageMemberModal/styles';
import { IAddNomineeModalProps, NomineeFormInput } from './types';
import { capitalizeFirstLetter } from '../../../utils/common';
import { Form, LoopButton, Modal, Typography } from '@loophealth/loop-ui-web-library';
import { availableGender, relationshipvalues } from './utils';

const AddNomineeModal: React.FunctionComponent<IAddNomineeModalProps> = ({
    isVisible,
    setIsVisible,
    onSubmit,
    consumedPercentage,
    selectedNominee
}) => {
    const isEditMode = !!selectedNominee;
    const [availablePercentage, setAvailablePercentage] = React.useState(100 - consumedPercentage);
    const initialFormState = (): NomineeFormInput => {
        return {
            firstName: { data: selectedNominee?.firstName || '', error: '' },
            lastName: { data: selectedNominee?.lastName || '', error: '' },
            gender: { data: selectedNominee?.gender || '', error: '' },
            relationship: { data: selectedNominee?.relationship || '', error: '' },
            percentage: { data: selectedNominee?.percentage || 0, error: '' },
            dob: { data: selectedNominee?.dob || new Date(), error: '' }
        };
    };
    const [loading, toggleLoading] = useState(false);
    const [nomineeData, setNomineeData] = useState<NomineeFormInput>(initialFormState());

    useEffect(() => {
        let available = 100 - consumedPercentage;
        if (selectedNominee) {
            available += selectedNominee.percentage;
        }
        setAvailablePercentage(available);
    }, [consumedPercentage, selectedNominee]);

    useEffect(() => {
        setNomineeData(initialFormState());
    }, [selectedNominee]);

    const handleSubmit = () => {
        const mandatoryFields = Object.keys(nomineeData);
        let hasErrors = false;
        let errors = nomineeData;
        toggleLoading(true);
        mandatoryFields.map((field) => {
            if (
                !nomineeData[field].data ||
                (typeof nomineeData[field].data === 'string' && !nomineeData[field].data.toString().trim().length)
            ) {
                hasErrors = true;
                errors = {
                    ...errors,
                    [field]: { ...nomineeData[field], error: `${capitalizeFirstLetter(field)} cannot be empty.` }
                };
                return;
            }
            if (field === 'percentage') {
                if (nomineeData[field].data > availablePercentage) {
                    hasErrors = true;
                    errors = {
                        ...errors,
                        [field]: {
                            ...nomineeData[field],
                            error: `You cannot assign upto ${availablePercentage}% sum insured to this nominee.`
                        }
                    };
                }
                return;
            }
            errors = {
                ...errors,
                [field]: { ...nomineeData[field], error: `` }
            };
        });
        if (hasErrors) {
            setNomineeData(errors);
            toggleLoading(false);
            return;
        }
        onSubmit({
            firstName: nomineeData.firstName.data.toString().trim(),
            lastName: nomineeData.lastName.data.toString().trim(),
            gender: nomineeData.gender.data,
            relationship: nomineeData.relationship.data,
            percentage: nomineeData.percentage.data,
            dob: nomineeData.dob.data
        });
        toggleLoading(false);
        setIsModalVisible(false);
        setNomineeData(initialFormState());
    };
    const setIsModalVisible = (isVisible: boolean) => {
        if (!isVisible) {
            setNomineeData(initialFormState());
        }
        setIsVisible(isVisible);
    };
    const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const firstName = e.target.value;
        setNomineeData({ ...nomineeData, firstName: { data: firstName, error: '' } });
    };
    const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const lastName = e.target.value;
        setNomineeData({ ...nomineeData, lastName: { data: lastName, error: '' } });
    };
    const handleDOBChange = (date: Date) => {
        setNomineeData({ ...nomineeData, dob: { data: date, error: '' } });
    };
    const handleRelationshipSelectChange = (relationship: string) => {
        setNomineeData({ ...nomineeData, relationship: { data: relationship, error: '' } });
    };
    const handleGenderChange = (gender: string) => {
        setNomineeData({ ...nomineeData, gender: { data: gender, error: '' } });
    };
    const handlePercentageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNomineeData({
            ...nomineeData,
            percentage: { data: parseInt(e.target.value), error: '' }
        });
    };
    return (
        <Modal
            title={`${isEditMode ? 'Edit' : 'Add'} Nominee`}
            isVisible={isVisible}
            setIsVisible={setIsModalVisible}
            disablePadding={true}
            overflow="visible"
        >
            <StyledModalContainer>
                <>
                    <StyledRow>
                        <StyledInputGroup>
                            <StyledAlignment>
                                <Typography variant="small" weight="semiBold">
                                    First Name
                                </Typography>
                            </StyledAlignment>
                            <Form.Input
                                placeholder="Enter First Name"
                                onChange={handleFirstNameChange}
                                value={nomineeData.firstName.data}
                                error={nomineeData.firstName.error}
                            />
                        </StyledInputGroup>
                        <StyledInputGroup>
                            <StyledAlignment>
                                <Typography variant="small" weight="semiBold">
                                    Last Name
                                </Typography>
                                <StyledSpan>*</StyledSpan>
                            </StyledAlignment>
                            <Form.Input
                                placeholder="Enter Last Name"
                                onChange={handleLastNameChange}
                                value={nomineeData.lastName.data}
                                error={nomineeData.lastName.error}
                            />
                        </StyledInputGroup>
                    </StyledRow>
                    <StyledRow>
                        <StyledInputGroup>
                            <StyledAlignment>
                                <Typography variant="small" weight="semiBold">
                                    Relationship
                                </Typography>
                                <StyledSpan>*</StyledSpan>
                            </StyledAlignment>
                            <Form.Dropdown
                                placeholder="Select Relationship"
                                listItems={relationshipvalues}
                                onClick={handleRelationshipSelectChange}
                                selectedItem={nomineeData.relationship.data}
                                error={nomineeData.relationship.error}
                            />
                        </StyledInputGroup>
                        <StyledInputGroup>
                            <StyledAlignment>
                                <Typography variant="small" weight="semiBold">
                                    Date of Birth (DD/MM/YYYY)
                                </Typography>
                                <StyledSpan>*</StyledSpan>
                            </StyledAlignment>
                            <Form.DatePicker
                                value={nomineeData.dob.data}
                                onChange={handleDOBChange}
                                maxDate={new Date()}
                                placeholder="Enter DOB"
                                iconOrder="left"
                                size="medium"
                                fontSize="small"
                                error={nomineeData.dob.error}
                            />
                        </StyledInputGroup>
                    </StyledRow>
                    <StyledRow>
                        <StyledInputGroup>
                            <StyledAlignment>
                                <Typography variant="small" weight="semiBold">
                                    Gender
                                </Typography>
                                <StyledSpan>*</StyledSpan>
                            </StyledAlignment>
                            <Form.Dropdown
                                placeholder="Select Gender"
                                listItems={availableGender}
                                onClick={handleGenderChange}
                                selectedItem={nomineeData.gender.data}
                                error={nomineeData.gender.error}
                            />
                        </StyledInputGroup>
                        <StyledInputGroup>
                            <StyledAlignment>
                                <Typography variant="small" weight="semiBold">
                                    Percentage of Sum Insured
                                </Typography>
                                <StyledSpan>*</StyledSpan>
                            </StyledAlignment>
                            <Form.Input
                                placeholder="E.g 25"
                                onChange={handlePercentageChange}
                                value={nomineeData.percentage.data ? nomineeData.percentage.data.toString() : ''}
                                error={nomineeData.percentage.error}
                            />
                            {!nomineeData.percentage.error.length && (
                                <StyledFormTip>
                                    <Typography variant="small" color="secondary">
                                        You can assign {availablePercentage}% of sum insured to this nominee
                                    </Typography>
                                </StyledFormTip>
                            )}
                        </StyledInputGroup>
                    </StyledRow>
                </>
            </StyledModalContainer>
            <StyledStickyContainer>
                <StyledButtonContainer>
                    <LoopButton variant="filled" onClick={handleSubmit} isLoading={loading}>
                        {`${isEditMode ? 'Edit' : 'Add'} Nominee`}
                    </LoopButton>
                </StyledButtonContainer>
            </StyledStickyContainer>
        </Modal>
    );
};

export default AddNomineeModal;
