import React from 'react';
import { AddMemberCard, MemberCard } from '..';
import { IDependent } from '../../../redux/slices/PolicyListSlice/types';
import { colorLibrary } from '../../../theme';
import {
    StyledDependentText,
    EnrollMembersContainer,
    StyledDependentSubText,
    StyledWarning,
    StyledSpacing
} from './styles';
import { IEnrolDependents, IRadioItem, IRelationship } from './types';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../redux';
import { defaultDateFormat } from '../../../utils/common';
import {
    getDependentCountUtil,
    getRelationAgeUtil,
    getRelationshipUtil,
    oneOfParentsOrInLawsOptions,
    parentOptions
} from './utils';
import { Alerts, Form, Typography } from '@loophealth/loop-ui-web-library';
import { WarningIcon } from '../../../assets/img';

const EnrolDependents: React.FunctionComponent<IEnrolDependents> = ({
    title,
    currentMapping,
    targetMapping,
    filteredDependents,
    category,
    onAddMemberClick,
    onMemberEditClick,
    onMemberDeleteClick,
    relationship,
    selectedRelationship,
    setSelectedParentOption
}) => {
    const inferredParentId = parentOptions.map(({ id }) => id).includes(filteredDependents[0]?.relationship)
        ? filteredDependents[0]?.relationship
        : parentOptions[0].id;
    const [selectedParentId, setSelectedParentId] = React.useState(inferredParentId);
    const preValidations = useSelector((state: ReduxState) => state.enrolment.policyDetails);
    const [selectedOption, setSelectedOption] = React.useState<null | IRadioItem>(
        parentOptions?.find((itm) => itm.id === inferredParentId) || null
    );
    const [switchedOption, setSwitchedOption] = React.useState<boolean>(false);

    React.useEffect(() => {
        setSelectedParentOption && selectedOption && setSelectedParentOption(selectedOption.value);
    }, [selectedOption]);

    React.useEffect(() => {
        if (switchedOption) setSwitchedOption(false);
    }, [filteredDependents]);

    const onRadioClick = (itm: IRadioItem) => {
        if (!filteredDependents.length) {
            setSelectedParentId(itm.id);
            setSelectedOption(itm);
        }
        setSwitchedOption(true);
    };

    const WarningDescription = () => {
        return (
            <Typography variant="small" color="error">
                {`Please remove ${selectedOption?.value} details to insure 
                ${parentOptions?.find((itm) => itm.id !== inferredParentId)?.value}. The policy permits insuring either
                parents or parents-in-law, not both.`}
            </Typography>
        );
    };

    return (
        <>
            {category !== 'Parents-in-law' ? (
                <StyledDependentText>
                    {title}
                    <StyledDependentSubText>
                        {getRelationAgeUtil(relationship, preValidations, title)}
                    </StyledDependentSubText>
                </StyledDependentText>
            ) : null}
            {oneOfParentsOrInLawsOptions.includes(category) && (
                <>
                    <StyledSpacing>
                        <Form.RadioButton
                            radioItems={parentOptions}
                            selectedItem={selectedOption}
                            onClick={onRadioClick}
                        />
                    </StyledSpacing>
                    {filteredDependents.length && switchedOption ? (
                        <StyledWarning>
                            <Alerts.StatusAlert
                                text={<WarningDescription />}
                                variant="over"
                                iconSrc={WarningIcon}
                            ></Alerts.StatusAlert>
                        </StyledWarning>
                    ) : null}
                </>
            )}
            <EnrollMembersContainer>
                {filteredDependents &&
                    filteredDependents.map((dep: IDependent, index: number) => {
                        return (
                            <MemberCard
                                key={dep.userId}
                                firstName={dep.firstName ? dep.firstName : ''}
                                lastName={dep.lastName ? dep.lastName : ''}
                                relationship={dep.relationship ? dep.relationship : 'N/A'}
                                doB={dep.doB ? defaultDateFormat(dep.doB) : 'N/A'}
                                gender={dep.gender ? dep.gender : 'N/A'}
                                mobile={dep.mobile ? dep.mobile : 'N/A'}
                                onMemberDelete={() => onMemberDeleteClick(dep.userId)}
                                onMemberEdit={() => onMemberEditClick(dep)}
                                color={colorLibrary[index % colorLibrary.length]}
                            />
                        );
                    })}
                {Array.from(
                    Array(
                        Math.max(
                            getDependentCountUtil(
                                'target',
                                getRelationshipUtil(category),
                                currentMapping,
                                targetMapping,
                                selectedParentId
                            )?.number -
                                getDependentCountUtil(
                                    'current',
                                    getRelationshipUtil(category),
                                    currentMapping,
                                    targetMapping,
                                    selectedParentId
                                )?.number,
                            0
                        )
                    ),
                    (_element, index) => {
                        return (
                            <AddMemberCard
                                key={index}
                                onClick={() => {
                                    selectedRelationship(relationship as IRelationship);
                                    onAddMemberClick();
                                }}
                                relationship={
                                    !oneOfParentsOrInLawsOptions.includes(category)
                                        ? getRelationshipUtil(category)
                                        : selectedParentId
                                }
                            />
                        );
                    }
                )}
            </EnrollMembersContainer>
        </>
    );
};

export default EnrolDependents;
