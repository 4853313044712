import styled from 'styled-components';
import SVG from 'react-inlinesvg';

export const StyledContainer = styled.div`
    display: flex;
    width: 100%;
    padding: 70px 52px 70px 100px;
    align-items: center;
    @media (max-width: 550px) {
        gap: 20px;
        flex-direction: column-reverse;
        padding: 40px;
        background-color: ${(p) => p.theme.colors.secondaryBG};
    }
`;

export const StyledInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 20px;
    height: 412px;
    justify-content: flex-end;
    @media (max-width: 550px) {
        align-items: flex-start;
        justify-content: flex-start;
        height: 256px;
        width: 100%;
    }
`;

export const StyledImageWrapper = styled.div`
    display: flex;
    width: 42%;
    @media (max-width: 550px) {
        width: 100%;
    }
`;

export const StyledSliderWrapper = styled.div`
    display: flex;
    gap: 8px;
    width: 100%;
    @media (max-width: 550px) {
        justify-content: center;
        margin-top: 30px;
    }
`;

export const StyledIcon = styled(SVG)<{ $fillActive: boolean }>`
    width: 8px;
    height: 8px;
    cursor: pointer;
    & circle {
        pointer-events: all;
        fill: ${(p) => (p.$fillActive ? '#025F4C' : '#C0D7D2')};
    }
`;

export const StyledButtonRow = styled.div`
    width: 80%;
    display: flex;
    margin-top: 10px;
    gap: 10px;
    @media (max-width: 550px) {
        position: fixed;
        bottom: 10px; 
    }
`;
export const StyledButtonContainer = styled.div`
   width: 100%;
`;
export const StyledTagWrapper = styled.div`
    display: flex;
    width: 100%;
`;

export const StyledSliderImage = styled.img`
    width: 600px;
    @media (max-width: 550px) {
        width: 100%;
    }
`;
