import { IStepperSlice } from './types';

export const initialState: IStepperSlice = {
    nomineeConfig: {
        data: {
            isEnabled: false,
            maximumNomineeSelection: 5,
            hrEmail: ''
        },
        loading: true,
        error: null
    },
    currentStepperStep: 1
};
