import React from 'react';
// import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { StyledWelcomeContainer } from './styles';
// import { setShowWelcomeScreen } from '../../../../../redux/slices/ApplicationStateSlice';
import { INTERNAL_ROUTES } from '../../../../../utils/constants';
import useSegment from '../../../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../../../utils/constants/SegmentActionConstants';
import { WelcomeCard } from '../../../../containers';
// import { getGender, capitalizeFirstLetter } from '../../../../../utils/common';

const WelcomePage: React.FunctionComponent = () => {
    const history = useHistory();
    // const dispatch = useDispatch();
    const location = useLocation();
    const trackClick = useSegment('click');
    // const userData = useSelector((state: any) => state.user?.userData.data);
    const goNext = () => {
        const segmentAction = SEGMENT_ACTIONS.CLICK.WELCOME_PAGE_PROCEED;
        trackClick({
            name: segmentAction.name,
            properties: {
                describe: segmentAction.properties?.describe,
                page_title: segmentAction.properties?.page_title,
                login_status: segmentAction.properties?.login_status
            }
        });

        // dispatch(setShowWelcomeScreen({ showWelcomeScreen: false }));
        history.push(INTERNAL_ROUTES.dashboard, { previousUrl: location.pathname });
    };
    return (
        <StyledWelcomeContainer>
            <WelcomeCard onProceed={goNext} />
        </StyledWelcomeContainer>
    );
};

export default WelcomePage;
