import { IStepperMeta, ISteps } from '../../components/atoms/Stepper/types';
import { INTERNAL_ROUTES } from './RouteConstants';

export const STEPPER_META_SUPER_TOPUP: IStepperMeta[] = [
    {
        id: 1,
        name: 'Register Members',
        stepUrl: INTERNAL_ROUTES.enrolment,
        btnCTA: 'Continue Registration',
        stepNo: 1,
        description: `Verify your details and add your dependents.`
    },
    {
        id: 2,
        name: 'Choose Your Plan (optional)',
        stepUrl: INTERNAL_ROUTES.topup,
        btnCTA: 'Proceed with Top-Up',
        stepNo: 2,
        description: `Select from a range of options`
    },
    {
        id: 3,
        name: 'Complete Registration',
        stepUrl: INTERNAL_ROUTES.summary,
        btnCTA: 'Proceed to Pay',
        stepNo: 3,
        description: `Secure your family’s coverage`
    }
];

export const SuperTopUpSteps: ISteps[] = [
    {
        title: 'Register Members',
        description: 'Verify your details and add your dependents.'
    },
    {
        title: 'Choose Your Plan',
        description: 'Select from a range of options'
    },
    {
        title: 'Complete Registration',
        description: 'Secure your family’s coverage'
    }
];
