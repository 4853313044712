import React from 'react';
import { useHistory } from 'react-router';
import {
    StyledContainer,
    StyledContainerWrapper,
    StyledLeftContainer,
    StyledRightContainer,
    StyledImage,
    ButtonContainer
} from './styles';
import { MAIN_ROUTES } from '../../../utils/constants/RouteConstants';
import { LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import { shapeRight } from '../../../assets/img';

const TopUpUnAvailable: React.FunctionComponent = () => {
    const history = useHistory();
    const redirectToHome = () => {
        history.push(MAIN_ROUTES.app);
    };
    return (
        <StyledContainer>
            <StyledContainerWrapper>
                <StyledLeftContainer>
                    <Typography variant="title1">Oops! Looks like you don’t have a GMC policy with Loop </Typography>

                    <ButtonContainer>
                        <div>
                            <LoopButton onClick={redirectToHome} variant="filled">
                                Go back to Home Page
                            </LoopButton>
                        </div>
                    </ButtonContainer>
                </StyledLeftContainer>
                <StyledRightContainer>
                    <StyledImage src={shapeRight} />
                </StyledRightContainer>
            </StyledContainerWrapper>
        </StyledContainer>
    );
};

export default TopUpUnAvailable;
