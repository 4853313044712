import styled from 'styled-components';

export const StyledContainer = styled.div`
    width: 246px;
    max-width: 246px;
    height: 402px;
    max-height: 402px;
    @media (max-width: 768px) {
        height: 100%;
        max-height: 100%;
        width: 100%;
        max-width: 100%;
        margin-bottom: 30px;
        padding: 20px;
    }
`;
