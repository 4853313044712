import React from 'react';
import {
    StyledContainer,
    StyledContent,
    StyledContentContainer,
    StyledDeleteImage,
    StyledEditImage,
    StyledNameContainer,
    AbsoluteContainer,
    MarginContainer,
    StyledAlignContainer
} from '../MemberCard/styles';
import { NameIcon } from '../../atoms';
import { DeleteIcon, NewEditIcon } from '../../../assets/img';
import { INomineeCard } from './types';
import { Typography } from '@loophealth/loop-ui-web-library';

const NomineeMemberCard: React.FunctionComponent<INomineeCard> = ({
    firstName,
    lastName,
    relationship,
    doB,
    gender,
    percentage,
    onMemberDelete,
    onMemberEdit,
    color
}) => {
    return (
        <StyledContainer>
            <AbsoluteContainer>
                <StyledEditImage onClick={onMemberEdit} src={NewEditIcon} />
                {relationship?.toLowerCase() !== 'self' && (
                    <StyledDeleteImage onClick={onMemberDelete} src={DeleteIcon} />
                )}
            </AbsoluteContainer>
            <StyledNameContainer>
                <MarginContainer>
                    <NameIcon firstName={firstName} lastName={lastName || ''} color={color} />
                </MarginContainer>

                <StyledContent>
                    <Typography variant="extraSmall" weight="semiBold" color="secondary">
                        Name
                    </Typography>
                    <Typography variant="small" weight="semiBold">
                        {firstName + ' ' + (lastName || '')}
                    </Typography>
                </StyledContent>
            </StyledNameContainer>
            <StyledContentContainer>
                <StyledAlignContainer>
                    <StyledContent>
                        <Typography variant="extraSmall" weight="semiBold" color="secondary">
                            Relationship
                        </Typography>
                        <Typography variant="small" weight="semiBold">
                            {relationship}
                        </Typography>
                    </StyledContent>
                    <StyledContent>
                        <Typography variant="extraSmall" weight="semiBold" color="secondary">
                            DOB
                        </Typography>
                        <Typography variant="small" weight="semiBold">
                            {doB}
                        </Typography>
                    </StyledContent>
                </StyledAlignContainer>
                <StyledAlignContainer>
                    <StyledContent>
                        <Typography variant="extraSmall" weight="semiBold" color="secondary">
                            Gender
                        </Typography>
                        <Typography variant="small" weight="semiBold">
                            {gender}
                        </Typography>
                    </StyledContent>
                    <StyledContent>
                        <Typography variant="extraSmall" weight="semiBold" color="secondary">
                            Percentage SI
                        </Typography>
                        <Typography variant="small" weight="semiBold">
                            {percentage}
                        </Typography>
                    </StyledContent>
                </StyledAlignContainer>
            </StyledContentContainer>
        </StyledContainer>
    );
};

export default NomineeMemberCard;
