import React from 'react';
import { StyledContainer, StyledLogo, SignoutWrapper, StyledLogoutBtnWrapper } from './styles';
import { LoopLogo } from '../../../assets/img';
import { IHeader } from './types';
import { LoopButton } from '@loophealth/loop-ui-web-library';
import useResponsive from '../../containers/PolicyInclusionsModal/hooks/useResponsive';

const Header: React.FunctionComponent<IHeader> = ({ signout, redirectToMain }) => {
    const isDesktop = useResponsive('(min-width: 768px)');
    return (
        <StyledContainer>
            <StyledLogo src={LoopLogo} alt={'Loop Health Logo'} onClick={redirectToMain} />
            <SignoutWrapper>
                <StyledLogoutBtnWrapper>
                    <LoopButton
                        onClick={signout}
                        variant={isDesktop ? 'outline' : 'text'}
                        textColor={isDesktop ? 'emerald' : 'error'}
                        borderColor={isDesktop ? '#025F4C' : ''}
                        size={isDesktop ? 'small' : 'medium'}
                    >
                        Sign Out
                    </LoopButton>
                </StyledLogoutBtnWrapper>
            </SignoutWrapper>
        </StyledContainer>
    );
};

export default Header;
