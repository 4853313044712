import React from 'react';
import {
    StyleLoopTagWrapper,
    StyledButtonWrapper,
    StyledContainer,
    StyledContentWrapper,
    StyledHeadingWrapper,
    StyledIcon,
    StyledWrapper
} from './styles';
import { IDownloadAppCard } from './types';
import { LoopButton, LoopTag, Typography } from '@loophealth/loop-ui-web-library';
import { PhoneIcon } from '../../../assets/img';
import useResponsive from '../PolicyInclusionsModal/hooks/useResponsive';
import DownloadAppModal from './DownloadAppModal';
import { redirectToMobileStore } from '../../pages/SPAContainer/pages/DashboardPage/utils';
import { REDIRECT_URL } from '../../../utils/constants/RedirectConstants';
import useSegment from '../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';

const DownloadAppCard: React.FunctionComponent<IDownloadAppCard> = ({ stepNumber }) => {
    const isDesktop = useResponsive('(min-width: 768px)');
    const trackClick = useSegment('click');
    const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
    const handleDownloadClick = () => {
        const segmentAction = SEGMENT_ACTIONS.CLICK.DASHBOARD_PAGE_DOWNLOAD_APP;
        trackClick({
            name: segmentAction.name,
            properties: {
                page_url: (segmentAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                page_id: (segmentAction.properties?.page_id as (path: string) => string)(location.pathname) || '',
                login_status: segmentAction.properties?.login_status,
                banner_position: (segmentAction.properties?.banner_position as (position: string) => string)('Bottom')
            }
        });
        if (isDesktop) {
            setIsModalVisible(true);
        } else {
            redirectToMobileStore(REDIRECT_URL.STORE);
        }
    };

    return (
        <StyledContainer>
            <StyleLoopTagWrapper>
                <LoopTag variant="error">STEP {stepNumber}: PENDING</LoopTag>
            </StyleLoopTagWrapper>

            <StyledHeadingWrapper>
                <StyledIcon src={PhoneIcon} />
                <Typography variant="medium" weight="bold">
                    Download the Loop App
                </Typography>
            </StyledHeadingWrapper>

            <StyledButtonWrapper>
                <StyledWrapper>
                    <LoopButton onClick={handleDownloadClick} variant="filled">
                        Download Loop App
                    </LoopButton>
                </StyledWrapper>
            </StyledButtonWrapper>

            <StyledContentWrapper>
                <Typography variant="medium" color="secondary">
                    Access all your insurance documents and your personal doctor with your activated membership
                </Typography>
            </StyledContentWrapper>
            <DownloadAppModal isVisible={isModalVisible} setIsVisible={setIsModalVisible} />
        </StyledContainer>
    );
};

export default DownloadAppCard;
