import React from 'react';
import { IMembersDataModalProps } from './types';
import { Modal, Typography } from '@loophealth/loop-ui-web-library';
import { StyledEnrolledMemberContent, StyledModalContainer, StyledModalContent } from './styles';
import EnrolledMemberRow from './EnrolledMemberRow';
import { getEnroledMembersString } from './utils';
const EnrolledMemberModal: React.FunctionComponent<IMembersDataModalProps> = ({
    dependents,
    isCollapsed,
    isVisible,
    setIsVisible,
    dependentsInsured,
    isTopUpPolicy
}) => {
    return (
        <StyledEnrolledMemberContent $isCollapsed={isCollapsed}>
            <Modal title={'Registered Members'} isVisible={isVisible} setIsVisible={setIsVisible}>
                <StyledModalContainer>
                    <StyledModalContent>
                        <Typography variant="small" color="secondary" weight="semiBold">
                            {getEnroledMembersString(dependents?.length, dependentsInsured, isTopUpPolicy ?? false)}
                            {' eligible members registered'}
                        </Typography>
                        {dependents.map((dep, index) => (
                            <EnrolledMemberRow key={index} index={index} {...dep} />
                        ))}
                    </StyledModalContent>
                </StyledModalContainer>
            </Modal>
        </StyledEnrolledMemberContent>
    );
};

export default EnrolledMemberModal;
