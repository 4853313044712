import React from 'react';
import { IModalProps } from './types';
import {
    StyledPolicyIncExcBody,
    StyledPolicyIncExcBodyItem,
    StyledPolicyIncExcBodyItemHeader,
    StyledPolicyIncExcTitleIcon,
    StyledPolicyIncExcList
} from './styles';
import { Typography } from '@loophealth/loop-ui-web-library';
import PolicyCoverageIncExcItem from './PolicyCoverageIncExcItem';
import { CheckIcon, CrossIcon } from '../../../assets/img';

const PolicyModalDesktop: React.FunctionComponent<IModalProps> = ({ data }) => {
    return (
        <StyledPolicyIncExcBody>
            <StyledPolicyIncExcBodyItem>
                <StyledPolicyIncExcBodyItemHeader>
                    <StyledPolicyIncExcTitleIcon src={CheckIcon} />
                    <Typography variant="small" weight="semiBold">
                        Inclusions
                    </Typography>
                </StyledPolicyIncExcBodyItemHeader>
                {data?.inclusion.length ? (
                    <StyledPolicyIncExcList>
                        {data?.inclusion.map((term) => (
                            <PolicyCoverageIncExcItem key={term.label} term={term} />
                        ))}
                    </StyledPolicyIncExcList>
                ) : (
                    <Typography variant="small">No inclusions to show</Typography>
                )}
            </StyledPolicyIncExcBodyItem>
            <StyledPolicyIncExcBodyItem>
                <StyledPolicyIncExcBodyItemHeader>
                    <StyledPolicyIncExcTitleIcon src={CrossIcon} />
                    <Typography variant="small" weight="semiBold">
                        Exclusions
                    </Typography>
                </StyledPolicyIncExcBodyItemHeader>
                {data?.exclusion.length ? (
                    <StyledPolicyIncExcList>
                        {data?.exclusion.map((term) => (
                            <PolicyCoverageIncExcItem key={term.label} isExclusion={true} term={term} />
                        ))}
                    </StyledPolicyIncExcList>
                ) : (
                    <Typography variant="small">No exclusions to show</Typography>
                )}
            </StyledPolicyIncExcBodyItem>
        </StyledPolicyIncExcBody>
    );
};

export default PolicyModalDesktop;
