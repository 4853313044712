/* eslint-disable quotes */
import React from 'react';
import { Button, RadioButton } from '../../atoms';
import { ICancelTopUpModal } from './types';

import {
    StyledContainer,
    StyledContainerWrapper,
    StyledContainerUL,
    StyledBtnContainerWrapper,
    StyledFeatureWrapper,
    StyledRowAlignment,
    StyledBtnWrapper,
    StyledChatSpan
} from './styles';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';
import useSegment from '../../../utils/hooks/useSegment';
import { getSegmentPageName } from '../../../utils/common';
import { useLocation } from 'react-router-dom';
import theme from '../../../theme';
import { ImageView } from '../../atoms/ImageView';
import { FEEDBACK_CONSTANTS } from '../../../utils/constants/FeedbackConstants';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../redux';
import { handleChatCTAClickUtil, handleSelectTopUpUtil, handleUnSelectTopUpUtil, saveTopUpFeedbackUtil } from './utils';
import { LoopButton, Modal } from '@loophealth/loop-ui-web-library';
import { TextView } from '../../atoms/TextView';
import { openFreshChat } from '../../../utils/Freshchat';
import { InsuranceImg, QuotesImg } from '../../../assets/img';

const CancelTopUpModal: React.FunctionComponent<ICancelTopUpModal> = ({
    isVisible,
    setIsVisible,
    showSummary,
    selectedSI,
    pageTitle
}) => {
    const trackClick = useSegment('click');
    const location = useLocation();
    const [feedback, setFeedback] = React.useState('');
    const currentPageAction = SEGMENT_ACTIONS.PAGE[getSegmentPageName(location.pathname) as any];
    const userId = useSelector((state: ReduxState) => state.user.userData.data?.userId || '');

    const handleSelectTopUp = () => {
        handleSelectTopUpUtil(trackClick, currentPageAction, selectedSI, pageTitle);
        setIsVisible(!isVisible);
    };

    const handleUnSelectTopUp = () => {
        saveTopUpFeedbackUtil(feedback, userId);
        handleUnSelectTopUpUtil(trackClick, currentPageAction, selectedSI, pageTitle, feedback);
        showSummary(false);
    };

    const handleChatCTAClick = () => {
        openFreshChat();
        handleChatCTAClickUtil(trackClick, currentPageAction);
    };

    return (
        <Modal title={''} isVisible={isVisible} setIsVisible={setIsVisible}>
            <StyledContainer>
                <StyledContainerWrapper>
                    <TextView fontSize="24px" margin="10px 0px 0px 0px" color={theme.colors.loopEmerald}>
                        You are proceeding without extra health cover...
                    </TextView>
                </StyledContainerWrapper>
                <StyledFeatureWrapper>
                    <StyledContainerUL>
                        <ImageView
                            src={QuotesImg}
                            backgroundColor={theme.colors.planRed}
                            height="26px"
                            width="26px"
                            containerMinHeight="42px"
                            containerWidth="42px"
                            radius="21px"
                        />
                        <TextView fontSize="14px" margin="0px 0px 0px 12px" color={theme.colors.loopEmerald}>
                            The most cost-effective way <br />
                            to get more coverage
                        </TextView>
                    </StyledContainerUL>
                    <StyledContainerUL>
                        <ImageView
                            src={InsuranceImg}
                            backgroundColor={theme.colors.coverYellow}
                            height="26px"
                            width="26px"
                            containerMinHeight="42px"
                            containerWidth="42px"
                            radius="21px"
                        />
                        <TextView fontSize="14px" color={theme.colors.loopEmerald} margin="0px 0px 0px 12px">
                            Loop guarantees best-in-class
                            <br />
                            benefits
                        </TextView>
                    </StyledContainerUL>
                </StyledFeatureWrapper>
                <StyledContainerWrapper>
                    <TextView fontSize="14px" color={theme.colors.beyondGrey} margin="20px 0px 20px 10px">
                        <b>Still don’t want a top up?</b> Please tell us why
                    </TextView>
                    <StyledRowAlignment>
                        {FEEDBACK_CONSTANTS.map((options, index) => {
                            return (
                                <RadioButton
                                    key={index}
                                    id={options}
                                    name={options}
                                    selectedId={feedback}
                                    onClick={() => setFeedback(options)}
                                    disabled={false}
                                />
                            );
                        })}
                    </StyledRowAlignment>
                </StyledContainerWrapper>
                <StyledBtnContainerWrapper>
                    <StyledBtnWrapper>
                        <Button
                            type="primary"
                            disabled={feedback.length ? false : true}
                            onClick={handleUnSelectTopUp}
                            fullWidth={true}
                        >
                            Yes, I don’t want a top up
                        </Button>
                    </StyledBtnWrapper>
                    <StyledBtnWrapper>
                        <LoopButton variant="outline" onClick={handleSelectTopUp}>
                            Go back to select a top up
                        </LoopButton>
                    </StyledBtnWrapper>
                </StyledBtnContainerWrapper>
                <StyledBtnContainerWrapper>
                    <TextView fontSize="16px" color={theme.colors.beyondGrey}>
                        {'Got a question?  '}
                        <StyledChatSpan onClick={() => handleChatCTAClick()}>
                            <TextView fontWeight="600" fontSize="16px" color={theme.colors.loopEmerald}>
                                Talk to an expert →
                            </TextView>
                        </StyledChatSpan>
                    </TextView>
                </StyledBtnContainerWrapper>
            </StyledContainer>
        </Modal>
    );
};

export default CancelTopUpModal;
