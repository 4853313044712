import { IPolicy } from '../../../../../redux/slices/PolicyListSlice/types';
import { getDaysLeft, redirect } from '../../../../../utils/common';
import { PARENTAL_POLICY } from '../../../../../utils/constants';

export const redirectToMobileStore = (url: string): void => {
    redirect(url);
};
export enum EnrolmentStatus {
    PENDING = 'pending',
    ENROLED = 'enroled'
}

export const calculateAPAmount = (annualPremium: number, premium: number, gst: number): number => {
    if (annualPremium > 0) {
        return annualPremium;
    } else {
        return premium + gst;
    }
};
export const sortPolicies = (list: IPolicy[]): IPolicy[] => {
    const sortedList = list.map((policy) => {
        if (PARENTAL_POLICY.includes(policy.familyStruture)) {
            return { ...policy, policyType: 'PARENTAL' };
        }
        return policy;
    });

    const typeOrder: { [key: string]: number } = {
        GMC: 0,
        SUPER_TOP_UP: 1,
        PARENTAL: 2,
        GPA: 3,
        GTL: 4
    };

    sortedList.sort((a, b) => {
        return typeOrder[a.policyType] - typeOrder[b.policyType];
    });

    return sortedList;
};

export const showEnrolmentWarning = (list: IPolicy[]): boolean => {
    for (const policy of list) {
        const daysLeft = getDaysLeft(policy.enrolmentDueDate);
        if (daysLeft !== 'OVERDUE') {
            return false;
        }
    }
    return true;
};

export const getNextPolicyToEnroll = (list: IPolicy[]): IPolicy | null => {
    for (const policy of list) {
        if (policy.enrolmentStatus === 'PENDING') {
            return policy;
        }
    }
    return null;
};
