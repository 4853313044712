import React from 'react';
import { CoveredMemberContainer, MemberNameContainer } from './styles';
import { IMemberIdentity } from './types';

import { colorLibrary } from '../../../theme';
import { capitalizeFirstLetter } from '../../../utils/common';
import { Typography } from '@loophealth/loop-ui-web-library';
import { NameIcon } from '../../atoms';
const MemberIdentity: React.FunctionComponent<IMemberIdentity> = ({
    firstName,
    lastName,
    relationship,
    index,
    bgColor,
    age
}) => {
    return (
        <CoveredMemberContainer $color={bgColor || ''}>
            <NameIcon
                firstName={firstName + ' ' + lastName}
                lastName=""
                color={colorLibrary[index % colorLibrary.length]}
            />
            <MemberNameContainer>
                <Typography variant="small" color="primary" weight="semiBold">
                    {capitalizeFirstLetter(firstName || '') + ' ' + capitalizeFirstLetter(lastName || '')}
                </Typography>

                <Typography variant="extraSmall" color="secondary" weight="medium">
                    {`${capitalizeFirstLetter(relationship || '')} , ${age ?? ''} years`}
                </Typography>
            </MemberNameContainer>
        </CoveredMemberContainer>
    );
};

export default MemberIdentity;
