import styled from 'styled-components';

export const StyledContainer = styled.div`
    box-shadow: 0px -2px 14px rgba(168, 196, 206, 0.1);
    border-radius: 16px;
    background-color: ${(p) => p.theme.colors.white};
    max-width: 100%;
    min-width: 908px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    @media (max-width: 768px) {
        box-shadow: 0px;
        border-radius: 0;
        min-width: 100%;
        background-color: ${(p) => p.theme.colors.backgroundSurface};
        gap: 0px;
    }
`;

export const StyledBackground = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    @media (max-width: 768px) {
        background-color: ${(p) => p.theme.colors.white};
    }
`;

export const StyledContent = styled.div`
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    max-width: 100%;
    padding: 0px 30px;
    @media (max-width: 768px) {
        padding: 0px;
    }
`;

export const StyledContentWrapper = styled.div`
    /* width: 100%; */
    display: flex;
    justify-content: space-between;
    padding: 30px;
    @media (max-width: 768px) {
        flex-direction: column;
        gap: 14px;
        padding: 20px 20px 10px 20px;
        border-radius: 0px 0px 16px 16px;
        border-bottom: 1px solid ${(p) => p.theme.colors.error};
    }
`;

export const StyledEnrolmentInfoWrapper = styled.div<{ $isCollapsed: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media (max-width: 768px) {
        flex-direction: row;
        gap: 0px;
        padding-top: 8px;
        ${(p) => (p.$isCollapsed ? '' : `border-top: 1px dashed ${p.theme.colors.seafoamGray};`)}
    }
`;

export const StyledEnrolmentDateContainer = styled.div`
    display: flex;
    gap: 8px;
    @media (max-width: 768px) {
        gap: 0px;
    }
`;

export const StyledDueDateContainer = styled.div`
    display: flex;
    flex-direction: row;
    @media (max-width: 768px) {
    }
`;

export const StyledDueDate = styled.div`
    font-size: ${(p) => p.theme.fontSizes.body};
    font-weight: 500;
    line-height: 20px;
    color: ${(p) => p.theme.colors.secondaryText};
    @media (max-width: 768px) {
        font-size: ${(p) => p.theme.fontSizes.label};
        color: ${(p) => p.theme.colors.error};
        text-transform: capitalize;
        line-height: 18px;
        font-weight: 600;
    }
`;

export const StyledCalendarIcon = styled.img`
    display: none;
    @media (max-width: 768px) {
        display: block;
        width: 16px;
        height: 16px;
        margin-right: 4px;
    }
`;

export const StyledEnrolmentDate = styled.div`
    font-size: ${(p) => p.theme.fontSizes.body};
    font-weight: 600;
    line-height: 20px;
    color: ${(p) => p.theme.colors.error};
    @media (max-width: 768px) {
        font-size: ${(p) => p.theme.fontSizes.label};
        color: ${(p) => p.theme.colors.beyondGrey};
        font-weight: 400;
        line-height: 18px;
        margin: 0px 8px 0px 4px;
    }
`;

export const StyledDaysLeft = styled.div`
    font-size: ${(p) => p.theme.fontSizes.body};
    font-weight: 500;
    line-height: 20px;
    color: ${(p) => p.theme.colors.error};
    @media (max-width: 768px) {
        font-size: ${(p) => p.theme.fontSizes.label};
        font-weight: 600;
        line-height: 18px;
    }
`;

export const StyledPolicyInfoWrapper = styled.div`
    display: flex;
    padding: 0px 30px;
    @media (max-width: 768px) {
        display: none;
    }
`;

export const StyledPolicyInfoMobile = styled.div<{ $isCollapsed: boolean }>`
    display: none;
    @media (max-width: 768px) {
        padding: 0px 20px;
        display: ${(p) => (p.$isCollapsed ? 'none' : 'flex')};
        flex-direction: column;
        gap: 20px;
    }
`;

export const StyledMemberInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media (max-width: 768px) {
        gap: 4px;
    }
`;

export const StyledSumInsuredInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media (max-width: 768px) {
        display: none;
    }
`;

export const StyledSumInsuredInfoMobile = styled.div`
    display: none;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
`;

export const StyledDivider = styled.div`
    width: 1px;
    margin: 0px 24px;
    background-color: ${(p) => p.theme.colors.seafoamGray};
    @media (max-width: 768px) {
        display: none;
    }
`;

export const StyledHorizontalDivider = styled.div(
    (p) => `
    background-color: ${p.theme.colors.platinum};
    height: 4px;
    width: 100%;
    @media (max-width: 768px) {
        display: none;
    }
`
);
