import styled from 'styled-components';

export const StyledContainer = styled.div`
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

export const StyledAlertContainer = styled.div`
    display: flex;
    border-radius: 8px;
    background: #5959591A;
    margin-bottom: 24px;
    width: 100%;
`;
