import React from 'react';
import { StyledDivider, StyledMemberInfo, StyledSumInsuredInfo, StyledSumInsuredInfoMobile } from './styles';
import { IPolicyInfo } from './types';
import { Typography } from '@loophealth/loop-ui-web-library';

const PolicyInfo: React.FunctionComponent<IPolicyInfo> = ({ dependentsInsured, sumInsured }) => {
    return (
        <>
            <StyledSumInsuredInfoMobile>
                <Typography variant="extraSmall" weight="semiBold" color="secondary">
                    SUM INSURED
                </Typography>
                <Typography variant="small" weight="semiBold">
                    {sumInsured}
                </Typography>
            </StyledSumInsuredInfoMobile>
            <StyledMemberInfo>
                <Typography variant="extraSmall" weight="semiBold" color="secondary">
                    ELIGIBLE MEMBERS
                </Typography>
                <Typography variant="small" weight="semiBold">
                    {dependentsInsured}
                </Typography>
            </StyledMemberInfo>
            <StyledDivider />
            <StyledSumInsuredInfo>
                <Typography variant="extraSmall" weight="semiBold" color="secondary">
                    SUM INSURED
                </Typography>
                <Typography variant="small" weight="semiBold">
                    {sumInsured}
                </Typography>
            </StyledSumInsuredInfo>
        </>
    );
};

export default PolicyInfo;
