import React from 'react';
import { useSelector } from 'react-redux';
import {
    StyledContainer,
    StyledDescriptionContainer,
    StyledButtonContainer,
    StyledIcon,
    StyledImgBackground,
    StyledTitle
} from './styles';
import { ReduxState } from '../../../redux';
import { LoopButton, Modal, Toast, Typography } from '@loophealth/loop-ui-web-library';
import { HighFiveIllustration } from '../../../assets/img';
import { ISummaryModal } from './types';
import { getDescription, getDescriptionTitle, getTitle } from './utils';
import { updateUserPolicyEnrolmentStatus } from '../../../adapters';
import { INTERNAL_ROUTES } from '../../../utils/constants';
import { useHistory } from 'react-router';
import useSegment from '../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';

const SummaryModal: React.FunctionComponent<ISummaryModal> = ({ isVisible, setIsVisible }) => {
    const history = useHistory();
    const toast = Toast.useToast();
    const trackClick = useSegment('click');
    const currentlySelectedPolicy = useSelector((state: ReduxState) => state.enrolment.currentlySelectedPolicy);
    const userData = useSelector((state: ReduxState) => state.user.userData);
    const [loading, setLoading] = React.useState<boolean>(false);

    const handleSubmit = async () => {
        try {
            const segmentAction = SEGMENT_ACTIONS.CLICK.COMPLETE_REGISTRATION;
            trackClick({
                name: segmentAction.name,
                properties: {
                    policy_id:
                        (segmentAction.properties?.policy_id as (policy: string) => string)(
                            currentlySelectedPolicy?.policyId || ''
                        ) || '',
                    login_status: segmentAction.properties?.login_status,
                    page_title: segmentAction.properties?.page_title
                }
            });
            setLoading(true);
            const enrolmember = await updateUserPolicyEnrolmentStatus(
                userData.data.userId,
                currentlySelectedPolicy?.policyId || ''
            );
            setLoading(false);
            if (enrolmember.toLowerCase() === 'enroled') {
                history.push(INTERNAL_ROUTES.dashboard, {
                    previousUrl: location.pathname,
                    policyType: currentlySelectedPolicy?.policyType
                });
            } else {
                setIsVisible(false);
                toast?.error('Something went wrong, Please retry');
            }
        } catch (error) {
            setIsVisible(false);
            toast?.error((error as Error).message || 'Something went wrong');
        }
    };

    return (
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} disablePadding={true}>
            <StyledContainer>
                <StyledImgBackground>
                    <StyledIcon src={HighFiveIllustration} />
                </StyledImgBackground>

                <StyledTitle>{getTitle()}</StyledTitle>

                <StyledDescriptionContainer>
                    <Typography variant="medium" weight="medium">
                        {getDescriptionTitle()}
                    </Typography>
                    <Typography variant="small" color="secondary">
                        {getDescription()}
                    </Typography>
                </StyledDescriptionContainer>

                <StyledButtonContainer>
                    <LoopButton variant="filled" onClick={handleSubmit} isLoading={loading}>
                        Complete My registration
                    </LoopButton>
                </StyledButtonContainer>
            </StyledContainer>
        </Modal>
    );
};

export default SummaryModal;
