import styled from 'styled-components';
import theme from '../../../theme';
export const Container = styled.div`
    display: flex;
    flex-direction: row;
    padding: 90px 120px;
    background-color: ${theme.colors.lightGrey};
    @media (max-width: 768px) {
        padding: 30px 20px;
        flex-direction: column;
    }
`;

export const BenefitTitleContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 400px;
    position: sticky;
    top: 72px;
    @media (max-width: 768px) {
        position: initial;
        margin-bottom: 20px;
    }
`;
export const HiddenContainer = styled.div`
    visibility: hidden;
`;
export const StyledTopUpContainer = styled.div`
    display: flex;
    /* padding: 0px 90px; */
    flex: 1.8;
    flex-direction: column;
`;

export const StyledCoverWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
    gap: 10px;
    @media (max-width: 768px) {
        margin-bottom: 10px;
    }
`;

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    /* margin: 20px 0px 30px 0px; */
`;

export const StyledInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    background-color: ${theme.colors.mutedTransparent};
    padding: 12px;
    border-radius: 12px;
`;

//

export const StyledBaseSIContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
`;

export const StyledBaseSISapn = styled.span`
    display: flex;
    padding: 18px;
    background-color: ${theme.colors.white};
    border-radius: 12px;
    color: ${theme.colors.beyondGrey};
`;

export const StyledPlusContainer = styled.div`
    display: flex;
    width: 50px;
    margin-top: 25px;
    justify-content: space-around;
    font-size: 20px;
`;

export const StyledTopSIContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
`;

export const StyledTotalSIContainer = styled.div`
    display: flex;
    width: 100%;
    background-color: ${theme.colors.white};
    border-radius: 12px;
    color: ${theme.colors.beyondGrey};
    justify-content: space-between;
    padding: 24px;
    margin: 30px 0px;
`;

export const StyledSelect = styled.select<{
    $src: string;
    $error: boolean;
}>`
    width: 100%;
    font-weight: normal;
    font-size: ${(p) => p.theme.fontSizes.body};
    color: ${(p) => p.theme.colors.loopEmerald};
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: ${(p) => p.theme.colors.white} url(${(p) => p.$src}) no-repeat scroll 95% center;
    ${(p) => (p.$error ? `border: 1px solid ${p.theme.colors.error}` : '')}
    border-radius: 12px;
    padding: 18px;
    border: 1px solid rgba(255, 255, 255, 0.8);
    cursor: pointer;
    font-family: 'Work Sans';
`;

export const StyledNameContainer = styled.div`
    display: flex;
    margin-bottom: 50px;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 17px;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: initial;
        margin-bottom: 10px;
    }
`;
export const StyledSIContainer = styled.div`
    width: 100%;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
`;
export const StyledSITabsWrapper = styled.div`
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 768px) {
        gap: 10px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
    }
`;
export const InfoTagImage = styled.img`
    margin-left: 8px;
    position: relative;
    top: 0;
    cursor: pointer;
    bottom: 0;
    display: flex;
`;
export const TopupsumInsuredValueContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 16px;
    width: 100%;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;
export const TopupsumInsuredTabViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        margin-bottom: 30px;
    }
`;

export const StyledInternalTopContainer = styled.div<{
    $borderRadius: string;
}>`
    background-color: ${(p) => p.theme.colors.loopEmerald};
    padding: 16px;
    border-radius: ${(p) => p.$borderRadius || '16px'};
    display: flex;
    height: 110px;
    justify-content: space-between;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 200px;
    }
`;

export const StyledDetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    margin-right: 10px;
    @media (max-width: 768px) {
        flex-direction: column;
        text-align: center;
        margin-top: 20px;
        align-items: flex-start;
    }
`;

export const StyledAPContainerSpan = styled.span`
    font-size: 28px;
    color: ${(p) => p.theme.colors.coverYellow};
    position: relative;
`;

export const StyledLoaderWrapper = styled.div`
    position: absolute;
    top: 40px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    min-height: 30px;
    @media (max-width: 768px) {
        position: initial;
    }
`;

export const StyledSup = styled.sup`
    font-size: ${(p) => p.theme.fontSizes.body};
    top: 7px;
    position: relative;
    margin-right: 3px;
`;

export const StyledSub = styled.sub`
    font-size: ${(p) => p.theme.fontSizes.body};
    top: -7px;
    position: relative;
    margin-left: 2px;
`;

export const StyledTaxContainer = styled.div`
    display: flex;
    border-radius: 0px 0px 16px 16px;
    background-color: ${(p) => p.theme.colors.platinum};
    padding: 16px;
    align-items: center;
    margin-bottom: 40px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 20px;
    gap: 20px;
    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
        justify-content: center;
    }
`;
export const StyledChatSpan = styled.span`
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-color: ${(p) => p.theme.colors.loopEmerald};
    @media (max-width: 768px) {
        display: none;
    }
`;

export const StyledContainer = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    @media (max-width: 768px) {
        align-items: center;
    }
`;

export const StyledButtonContainer = styled.div`
    display: none;
    @media (max-width: 768px) {
        width: 100%;
        display: flex;
    }
`;

export const StyledButtonWrapper = styled.div`
    width: 300px;
    display: flex;
    @media (max-width: 768px) {
        width: 100%;
    }
`;
export const StyledInformationItemTooltipIcon = styled.img`
    width: 20px;
    height: 20px;
    object-fit: contain;
`;

export const StyledDottedDivider = styled.div`
    width: 100%;
    display: none;
    @media (max-width: 768px) {
        display: flex;
        border-style: dashed;
        border-width: 1px;
        border-color: ${theme.colors.seafoamGray};
        margin: 20px 0px 24px 0px;
    }
`;

export const StyledHeaderTextWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 50px;
    @media (max-width: 768px) {
        justify-content: flex-start;
        margin-bottom: 40px;
    }
`;
