import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import {
    StyledNoPolicyText,
    StyledContainer,
    StyledInsuranceContainer,
    StyledEnrolmentHeaderContainer,
    StyledResponsiveText,
    StyledSendIcon,
    StyledEnrolmentCardsContainer,
    StyleAlertWarning
} from './styles';
import { ReduxState } from '../../../../../redux';
import { setCurrentlySelectedPolicy } from '../../../../../redux/slices/EnrolmentSlice';
import { IPolicy } from '../../../../../redux/slices/PolicyListSlice/types';
import {
    insuranceCardDateFormat,
    getDaysLeft,
    getPolicyCardTrackObject,
    getSumInsured,
    getAvailableTopup,
    checkIfTopUpAvailable,
    capitalizeFirstLetter,
    formatAmount
} from '../../../../../utils/common';
import { ErrorContainer, Loader } from '../../../../atoms';
import { INTERNAL_ROUTES, InsuranceTypeConstants } from '../../../../../utils/constants';
import useSegment from '../../../../../utils/hooks/useSegment';
import { fetchUserPolicyList } from '../../../../../redux/slices/PolicyListSlice';
import { fetchUserDependents } from '../../../../../redux/slices/PolicyListSlice/thunks';
import { resetOrderState } from '../../../../../topup-src/redux/slices/PolicyListSlice';
import {
    checkForShowSuperTopupCardCondition,
    checkTopUpAvailableCompany,
    getEnrolledPolicyCompany,
    getGMCPolicyCompany,
    getSuperTopUpPolicyCompany,
    showPolicyMsgDashboard
} from '../../../../../utils/PolicyServices';
import WelcomeCard from '../../../../atoms/WelcomeCard';
import theme from '../../../../../theme';
import { BellIcon, TickSquareIcon } from '../../../../../assets/img';
import { EnrolMembersCard, DownloadAppCard } from '../../../../containers';
import { calculateAPAmount, getNextPolicyToEnroll, showEnrolmentWarning, sortPolicies } from './utils';
import { Alerts } from '@loophealth/loop-ui-web-library';
import { EnrolmentDateAlert } from './EnrolmentDateAlert';
import { LocationState } from '../../../../../topup-src/utils/types';
import EnrolSuccessModal from '../../../../containers/EnrolSuccessModal';

const DashboardPage: React.FunctionComponent = () => {
    const trackClick = useSegment('click');
    const history = useHistory();
    const location = useLocation<LocationState>();
    const dispatch = useDispatch();
    // const toast = Toast.useToast();
    const isLoading = useSelector((state: ReduxState) => state.policyList.policyList.loading);
    const userName = useSelector((state: ReduxState) => state.user?.userData?.data);
    const error = useSelector((state: ReduxState) => state.policyList.policyList.error);
    const policyList: IPolicy[] = useSelector((state: ReduxState) => state.policyList.policyList.data) || [];
    const sortPolicyList = sortPolicies(policyList);
    const nextPolicy = getNextPolicyToEnroll(sortPolicyList);
    const enrolledDonePolicyList = sortPolicyList.filter(
        (policy) => policy.enrolmentStatus?.toLowerCase() === 'enroled'
    );
    const policiesLeft = policyList.length - enrolledDonePolicyList.length;
    const userId = useSelector((state: ReduxState) => state.user?.userData?.data?.userId);
    const checkSuperTopUpAvailable = checkTopUpAvailableCompany();
    const superTopUpPolicy = getSuperTopUpPolicyCompany();
    const getGMCPolicy = getGMCPolicyCompany(policyList);
    const getEnrolledGMCPolicies = getEnrolledPolicyCompany(policyList);
    const superTopUpAvailable = checkIfTopUpAvailable(checkSuperTopUpAvailable, getGMCPolicy);
    const checkShowSuperTopUpCard = checkForShowSuperTopupCardCondition(policyList, superTopUpAvailable);
    const redirectToEnrolment = (policy: IPolicy) => {
        dispatch(setCurrentlySelectedPolicy({ currentlySelectedPolicy: policy }));
        history.push(INTERNAL_ROUTES.enrolment, { previousUrl: location.pathname });
    };
    const isSuperTopupPurchased = !!policyList?.find(
        (pol) => pol.policyType === 'SUPER_TOP_UP' && pol.enrolmentStatus === 'ENROLED'
    );

    React.useEffect(() => {
        if (userId) {
            dispatch(fetchUserPolicyList({ userId }));
            dispatch(fetchUserDependents({ userId }));
            dispatch(resetOrderState());
        }
    }, [userId]);
    const handleCardClick = (policy: IPolicy) => {
        trackClick(getPolicyCardTrackObject(policy));
        if (policy?.policyType === 'SUPER_TOP_UP') {
            // TODO: Enrolment Status to be handled in backend. handling null for now
            if (checkSuperTopUpAvailable === 'NOT_SELECTED' || policy?.enrolmentStatus != 'ENROLED') {
                history.push(INTERNAL_ROUTES.topup, { previousUrl: location.pathname });
            }
            if (getEnrolledGMCPolicies.length) {
                const enroledPolicy = { ...{}, ...getEnrolledGMCPolicies[0] };
                enroledPolicy.enrolmentStatus = 'PENDING';
                dispatch(setCurrentlySelectedPolicy({ currentlySelectedPolicy: enroledPolicy }));
            }
            return;
        }
        if (!(getDaysLeft(policy.enrolmentDueDate) === 'OVERDUE')) {
            redirectToEnrolment(policy);
        }
    };

    const totalSteps =
        policyList?.length + 1 + (checkShowSuperTopUpCard && superTopUpPolicy?.enrolmentStatus === 'ENROLED' ? 1 : 0);

    React.useEffect(() => {
        const policyTypeExists = location.state && location?.state?.policyType !== undefined;
        const policyName = InsuranceTypeConstants[location?.state?.policyType?.toUpperCase().trim() ?? 'GMC'];
        if (policyTypeExists && !isLoading) {
            setEnrolSuccessModal(true);
            window.plotline('track', 'CONFIRMATION', { policy: policyName });
            // toast?.success(`${policiesLeft + 1} more steps to go `, `Successfully registered in ${policyName}`);
            history.replace({
                ...location,
                state: {}
            });
        }
    }, [location, isLoading]);

    const [enrolSuccessModal, setEnrolSuccessModal] = React.useState<boolean>(false);
    return (
        <>
            <WelcomeCard
                loggedInUser={capitalizeFirstLetter(userName?.firstName ?? '')}
                showAppDownloadCTA={!isLoading && policiesLeft === 0}
            />
            <StyledContainer>
                {isLoading ? (
                    <Loader />
                ) : error?.message.length ? (
                    <ErrorContainer message={error.message} />
                ) : (
                    <>
                        <StyledEnrolmentHeaderContainer>
                            <StyledResponsiveText
                                $color={theme.colors.beyondGrey}
                                $fontDesktop={theme.fontSizes.title}
                                $fontMobile={theme.fontSizes.heading}
                            >
                                Kickstart Your Loop Journey
                            </StyledResponsiveText>
                            <StyledResponsiveText
                                $color={theme.colors.beyondGrey}
                                $fontDesktop={theme.fontSizes.subHeading}
                                $fontMobile={theme.fontSizes.heading}
                            >
                                <StyledSendIcon src={TickSquareIcon} />
                                {totalSteps - (policiesLeft + 1)} of {totalSteps} steps completed
                            </StyledResponsiveText>
                        </StyledEnrolmentHeaderContainer>
                        {showEnrolmentWarning(sortPolicyList) ? (
                            <StyleAlertWarning>
                                <Alerts.StatusAlert text={<EnrolmentDateAlert />} variant="over" iconSrc={BellIcon} />
                            </StyleAlertWarning>
                        ) : null}

                        <StyledEnrolmentCardsContainer>
                            {sortPolicyList?.map((policy, index) => (
                                <StyledInsuranceContainer key={`EnrolPending-${index}`}>
                                    <EnrolMembersCard
                                        policyId={policy.policyId}
                                        onClick={() => handleCardClick(policy)}
                                        insuranceCategory={policy?.policyType?.toUpperCase()}
                                        insuranceName={policy.policyName}
                                        policyImage={policy.policyImage}
                                        isEnrolPending={!(getDaysLeft(policy.enrolmentDueDate) === 'OVERDUE')}
                                        sumInsured={formatAmount(policy.sumInsured)}
                                        dependentsInsured={policy.familyStruture}
                                        enrolmentDueDate={
                                            policy?.enrolmentDueDate
                                                ? insuranceCardDateFormat(policy.enrolmentDueDate)
                                                : 'N/A'
                                        }
                                        policyEndDate={
                                            policy?.policyEndDate
                                                ? insuranceCardDateFormat(policy?.policyEndDate?._seconds)
                                                : 'N/A'
                                        }
                                        annualPremium={calculateAPAmount(
                                            superTopUpPolicy?.annualPremium ?? 0,
                                            superTopUpPolicy?.digitPremiumWithoutGST ?? 0,
                                            superTopUpPolicy?.gst ?? 0
                                        )}
                                        daysLeft={getDaysLeft(policy.enrolmentDueDate)}
                                        dependents={policy.dependents}
                                        availableTopUp={getAvailableTopup(policy.availableTopUp)}
                                        topUpAdded={getSumInsured(policy.topUpAdded, '')}
                                        superTopupStatus={checkSuperTopUpAvailable}
                                        SI={policy.sumInsured}
                                        enrolmentStatus={policy?.enrolmentStatus as string}
                                        currentStep={index + 1}
                                        addTopUpClick={() => handleCardClick(superTopUpPolicy as IPolicy)}
                                        isSuperTopupPurchased={isSuperTopupPurchased}
                                    />
                                </StyledInsuranceContainer>
                            ))}
                            {checkShowSuperTopUpCard && superTopUpPolicy?.enrolmentStatus === 'ENROLED' ? (
                                <StyledInsuranceContainer key={`EnrolPending-${4}`}>
                                    <EnrolMembersCard
                                        policyId={superTopUpPolicy?.policyId}
                                        onClick={() => handleCardClick(superTopUpPolicy as IPolicy)}
                                        insuranceCategory={superTopUpPolicy?.policyType?.toUpperCase() ?? ''}
                                        insuranceName={superTopUpPolicy?.policyName ?? ''}
                                        policyImage={superTopUpPolicy?.policyImage ?? ''}
                                        isEnrolPending={
                                            !(getDaysLeft(superTopUpPolicy?.enrolmentDueDate ?? 0) === 'OVERDUE')
                                        }
                                        sumInsured={formatAmount(superTopUpPolicy?.sumInsured)}
                                        dependentsInsured={''}
                                        enrolmentDueDate={
                                            getEnrolledGMCPolicies[0]?.enrolmentDueDate
                                                ? insuranceCardDateFormat(getEnrolledGMCPolicies[0].enrolmentDueDate)
                                                : 'N/A'
                                        }
                                        policyEndDate={
                                            superTopUpPolicy?.policyEndDate
                                                ? insuranceCardDateFormat(superTopUpPolicy?.policyEndDate?._seconds)
                                                : 'N/A'
                                        }
                                        annualPremium={getEnrolledGMCPolicies[0]?.annualPremium}
                                        daysLeft={getDaysLeft(getEnrolledGMCPolicies[0]?.enrolmentDueDate ?? 0)}
                                        dependents={getEnrolledGMCPolicies[0]?.dependents ?? []}
                                        availableTopUp={getAvailableTopup(
                                            getEnrolledGMCPolicies[0]?.availableTopUp ?? 0
                                        )}
                                        topUpAdded={getSumInsured(getEnrolledGMCPolicies[0]?.topUpAdded ?? 0, '')}
                                        superTopupStatus={checkSuperTopUpAvailable}
                                        enrolmentStatus={superTopUpPolicy?.enrolmentStatus ?? 'PENDING'}
                                        currentStep={totalSteps - 1}
                                        insurerName={superTopUpPolicy.insurerName}
                                        addTopUpClick={() => handleCardClick(superTopUpPolicy as IPolicy)}
                                    />
                                </StyledInsuranceContainer>
                            ) : null}
                            <DownloadAppCard stepNumber={totalSteps} />
                        </StyledEnrolmentCardsContainer>

                        <StyledNoPolicyText>{showPolicyMsgDashboard(sortPolicyList.length)}</StyledNoPolicyText>
                    </>
                )}
                <EnrolSuccessModal
                    isVisible={enrolSuccessModal}
                    setIsVisible={setEnrolSuccessModal}
                    enrolPolicy={nextPolicy}
                    policiesLeft={policiesLeft}
                />
            </StyledContainer>
        </>
    );
};

export default DashboardPage;
