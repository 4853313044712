import React from 'react';
import { StyledLoaderContainer } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../redux';
import { Loader, Typography } from '@loophealth/loop-ui-web-library';
import {
    fetchPolicyBenefitsDetails,
    fetchTopUpPolicyDetails
} from '../../../topup-src/redux/slices/PolicyListSlice/thunks';
import { fetchFaqsData } from '../../../topup-src/redux/slices/TopUpLandingSlice/thunk';
import TopUpUnAvailable from '../TopUpUnAvailable';
import TopUpAvailable from '../TopUpAvailable';

const TopUpPage: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state: ReduxState) => state.TopUp_policyData.policyList.loading);
    const topUpPolicyDetailsLoading = useSelector(
        (state: ReduxState) => state.TopUp_policyData.topupPolicyDetails.loading
    );
    const policyData: any = useSelector((state: ReduxState) => state.TopUp_policyData.policyList.data);
    const basePolicyData = policyData?.baseGmcPolicyData;
    const policyId = useSelector((state: ReduxState) => state.TopUp_applicationState.topupPolicyId);
    const error = useSelector((state: ReduxState) => state.TopUp_policyData.policyList.error);
    React.useEffect(() => {
        if (policyId) {
            dispatch(fetchTopUpPolicyDetails({ policyId }));
            dispatch(fetchFaqsData());
        }
    }, [policyId]);

    React.useEffect(() => {
        if (basePolicyData?.policyId && !isLoading) {
            dispatch(
                fetchPolicyBenefitsDetails({ policyId: basePolicyData?.policyId, slabId: basePolicyData?.slabId })
            );
        }
    }, [basePolicyData]);

    // const policyActive = getDaysLeftTopUp(basePolicyData?.policyEndDate, 'endDate');
    // const userDob = useSelector((state: ReduxState) => state.TopUp_user?.userData?.data?.dob);
    return (
        <>
            {isLoading || topUpPolicyDetailsLoading ? (
                <StyledLoaderContainer>
                    <Loader />
                    <Typography variant="large" color="secondary">
                        Please wait while we fetch your <br /> policy details..
                    </Typography>
                </StyledLoaderContainer>
            ) : error?.message.length ? (
                <TopUpUnAvailable/>
            ) : (
                <>
                    {basePolicyData &&
                    Object.keys(basePolicyData).length !== 0 &&
                    basePolicyData.sumInsured >= 200000 &&
                    !isLoading ? (
                            <TopUpAvailable policyData={basePolicyData} />
                        ) : (
                            <TopUpUnAvailable />
                        )}
                </>
            )}
        </>
    );
};
export default TopUpPage;
