import styled from 'styled-components';

export const StyledNameContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const StyledInsuranceImage = styled.img`
    height: 50px;
    width: 50px;
    @media (max-width: 786px) {
        height: 44px;
        width: 44px;
    }
`;

export const StyledInsuranceNameContainer = styled.div<{
    $isPhoneResponsive: boolean;
}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 16px;
    @media (max-width: 786px) {
        margin-left: 12px;
        align-items: ${(p) => (p.$isPhoneResponsive ? 'center' : 'flex-start')};
    }
`;

export const StyledInsuranceName = styled.div(
    (p) => `
    max-width: 400px;
    color: ${p.theme.colors.beyondGrey};
    font-size: ${p.theme.fontSizes.subHeading};
    font-weight: 700;
    @media (max-width: 786px) {
        font-size: ${p.theme.fontSizes.mediumFont};
        font-weight: 600;
    }   
`
);

export const StyledInsuranceCategory = styled.div(
    (p) => `
    color: ${p.theme.colors.secondaryText};
    font-size: ${p.theme.fontSizes.body};
    margin-top: 8px;
    font-weight: 400;
    @media (max-width: 786px) {
        font-size: ${p.theme.fontSizes.label};
    }   
`
);

export const StyledDetailsContainer = styled.div<{
    $isPhoneResponsive: boolean;
}>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    @media (max-width: 786px) {
        flex-direction: ${(p) => (p.$isPhoneResponsive ? 'column' : 'row')};
        gap: ${(p) => (p.$isPhoneResponsive ? '10px' : '0px')};
        align-items: center;
        width: ${(p) => (p.$isPhoneResponsive ? '100%' : 'initial')};
    }
`;

export const StyledIcon = styled.img<{ $isInverted: boolean }>`
    display: none;
    @media (max-width: 786px) {
        display: block;
        width: 35px;
        height: 35px;
        cursor: pointer;
        ${(p) => (p.$isInverted ? 'transform: scaleY(-1);' : '')}
    }
`;
