import styled from 'styled-components';

export const ImageContainer = styled.img<{ $rotate?: string }>(
    (p) => `
    width: ${p.width ? p.width : '100%'};
    height: ${p.height ? p.height : '100%'};
    transform: rotate(${p.$rotate ? p.$rotate : '0deg'});
`
);

export const ImageViewContainer = styled.div<{
    $containerMinWidth?: string | null;
    $containerMinHeight?: string | null;
    $backgroundColor?: string;
    $radius?: string;
    $marginLeft?: string;
    $marginRight?: string;
    $width?: string | null;
    $margin?: string;
}>(
    (p) => `
    ${p.$containerMinWidth ? `min-width: ${p.$containerMinWidth}` : `width: ${p.$width}`};                        
    height: ${p.$containerMinHeight};
    border-radius: ${p.$radius};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${p.$backgroundColor ? p.$backgroundColor : 'rgba(0, 0, 0, 0)'};
    margin-left: ${p.$marginLeft || '0px'};
    margin-right: ${p.$marginRight || '0px'};
    margin: ${p.$margin};
`
);
