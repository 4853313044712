import React from 'react';
import theme from '../../../theme';
import { ImageView } from '../../atoms/ImageView';
import { TextView } from '../../atoms/TextView';
import { WidgetContainer } from './styles';
import { IBenefitWidget } from './types';

export const BenefitWidget: React.FunctionComponent<IBenefitWidget> = ({
    src,
    marginStyle,
    title,
    subTitle,
    height,
    backgroundColor,
    paddingStyle,
    containerWidth = '64px',
    containerHeight = '64px',
    iconWidth = '40px',
    iconHeight = '40px',
    titleFont,
    subTitleFont,
    widgetBackgroundColor = theme.colors.lightGrey
}) => {
    return (
        <WidgetContainer
            $margin={marginStyle}
            $padding={paddingStyle}
            $height={height}
            $bgColor={widgetBackgroundColor}
        >
            <ImageView
                src={src}
                backgroundColor={backgroundColor ? backgroundColor : theme.colors.coverUpGreen}
                height={iconHeight}
                width={iconWidth}
                containerMinHeight={containerHeight}
                containerWidth={containerWidth}
                radius="32px"
            />
            <TextView
                margin="16px 0px 16px 0px"
                fontSize={titleFont || '22px'}
                fontWeight={'400'}
                color={theme.colors.loopEmerald}
            >
                {title}
            </TextView>
            <TextView
                fontSize={subTitleFont || '16px'}
                margin="0px 0px 28px 0px"
                color={theme.colors.beyondGrey}
                fontWeight={'400'}
            >
                <div dangerouslySetInnerHTML={{ __html: subTitle }} />
            </TextView>
        </WidgetContainer>
    );
};
