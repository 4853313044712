import React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './redux';
import Theme from './theme';
import App from './App';
import './index.css';
import { persistor } from './redux/store';
import { Loader } from './components/atoms';
import { initializeSegment } from './segment';
import { IUserData } from './types';
declare global {
    interface Window {
        recaptchaVerifier: firebase.auth.RecaptchaVerifier;
        confirmationResult: firebase.auth.ConfirmationResult | null;
        analytics: any;
        plotline: (...args: (string | Record<string, string>)[]) => void;
        fcWidget: {
            init: (config: any) => void;
            open: () => void;
            destroy: () => void;
            isLoaded: () => boolean;
            on: (a: any, b: any) => void;
            user: IUserData;
            // add more methods and properties as needed
        };
    }
}

initializeSegment();

ReactDOM.render(
    <ReduxProvider store={store}>
        <ThemeProvider theme={Theme}>
            {/** TODO: Show splash page here while data is being loaded **/}
            <PersistGate loading={<Loader />} persistor={persistor}>
                <Router>
                    <App />
                </Router>
            </PersistGate>
        </ThemeProvider>
    </ReduxProvider>,
    document.getElementById('root')
);
