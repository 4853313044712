import styled from 'styled-components';

export const StyledMenuContainer = styled.div`
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    position: sticky;
    width: 22%;
    top: 32px;
    padding-top: 60px;
    @media (max-width: 768px) {
        padding: 0px;
        width: 100%;
    }
`;

export const NavigatorContainer = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    @media (max-width: 768px) {
        max-width: 100%;
        min-width: 0;
        position: fixed;
        right: 0;
        width: 100%;
    }
`;

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: row;
    min-height: 100px;
    @media (max-width: 768px) {
        flex-direction: column;
        margin-bottom: 100px;
    }
`;

export const StyledContainerWrapper = styled.div`
    display: flex;
    padding-left: 50px;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    @media (max-width: 768px) {
        padding: 0px;
        width: 100%;
        margin: 0px;
        flex-direction: column;
    }
`;

export const StyledContentContainer = styled.div`
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    width: 78%;
    display: flex;
    @media (max-width: 768px) {
        padding: 0px;
        width: 100%;
        margin: 0px;
        margin: 20px 0px;
    }
`;
