import { Dispatch, SetStateAction } from 'react';
import { addNomineeAPI, deleteNomineeAPI, editNomineeAPI } from '../../../adapters';
import { parseResponse } from '../../../utils/common';
import { IToastContext, NomineeData } from './types';

export enum MemberClickAction {
    EDIT,
    DELETE
}

export const relationshipvalues = [
    { name: 'Father', value: 'Father' },
    { name: 'Mother', value: 'Mother' },
    { name: 'Sibling', value: 'Sibling' },
    { name: 'Spouse', value: 'Spouse' }
];

export const availableGender = [
    { name: 'Male', value: 'male' },
    { name: 'Female', value: 'female' }
];

const onMemberEdit = async (
    selectedNominee: NomineeData | undefined,
    nominee: NomineeData,
    userId: string,
    policyId: string,
    toggleManageNomineeModalVisible: Dispatch<SetStateAction<boolean>>,
    setSelectedNominee: Dispatch<SetStateAction<NomineeData | undefined>>,
    refreshNominees: () => Promise<void>,
    toast: IToastContext | null
): Promise<void> => {
    try {
        if (!selectedNominee || !selectedNominee.id) {
            return;
        }
        const [error] = await parseResponse(
            editNomineeAPI({
                id: selectedNominee.id,
                dob: nominee.dob,
                gender: nominee.gender,
                name: nominee.firstName + ' ' + nominee.lastName,
                percentage: nominee.percentage,
                relationship: nominee.relationship,
                policyId: policyId,
                userId
            })
        );
        if (error) {
            toast?.error((error as Error).message, 'Something went wrong, Please try Again');
            return;
        }
        toggleManageNomineeModalVisible(false);
        await refreshNominees();
        setSelectedNominee(undefined);
        toast?.success(``, 'Nominee edited successfully', {
            variant: 'dark'
        });
    } catch (e) {
        toast?.error((e as Error).message, 'Something went wrong, Please try Again');
    }
};

export const onMemberAddUtil = async (
    selectedNominee: NomineeData | undefined,
    nominee: NomineeData,
    userId: string,
    policyId: string,
    toggleManageNomineeModalVisible: Dispatch<SetStateAction<boolean>>,
    setSelectedNominee: Dispatch<SetStateAction<NomineeData | undefined>>,
    refreshNominees: () => Promise<void>,
    consumedPercentage: number,
    toast: IToastContext | null
): Promise<void> => {
    try {
        if (selectedNominee) {
            await onMemberEdit(
                selectedNominee,
                nominee,
                userId,
                policyId,
                toggleManageNomineeModalVisible,
                setSelectedNominee,
                refreshNominees,
                toast
            );
            return;
        }
        await addNomineeAPI({
            dob: nominee.dob,
            gender: nominee.gender,
            name: nominee.firstName + ' ' + nominee.lastName,
            percentage: nominee.percentage,
            relationship: nominee.relationship,
            policyId,
            userId
        });
        await refreshNominees();
        toast?.success(
            consumedPercentage != 100
                ? `Please add another nominee to assign ${100 - consumedPercentage} of the total sum insured`
                : '100% of sum insured assigned to nominees',
            'Nominee added successfully',
            {
                variant: 'dark'
            }
        );
    } catch (e) {
        toast?.error((e as Error).message, 'Something went wrong, Please try Again');
    }
};

export const onMemberDeleteUtil = async (
    selectedNominee: NomineeData | undefined,
    toggleDeleteMemberModalVisible: Dispatch<SetStateAction<boolean>>,
    setSelectedNominee: Dispatch<SetStateAction<NomineeData | undefined>>,
    refreshNominees: () => Promise<void>,
    toast: IToastContext | null
): Promise<void> => {
    try {
        if (!selectedNominee || !selectedNominee.id) {
            return;
        }
        const [error] = await parseResponse(deleteNomineeAPI(selectedNominee.id));
        if (error) {
            // showApiFailureToast(toast, error.toString());
            return;
        }
        toggleDeleteMemberModalVisible(false);
        await refreshNominees();
        setSelectedNominee(undefined);
        toast?.success(``, 'Nominee deleted successfully', {
            variant: 'dark'
        });
    } catch (e) {
        toast?.error((e as Error).message, 'Something went wrong, Please try Again');
    }
};

export const getPercentageConsumed = (nomineesData: NomineeData[]): number => {
    return nomineesData
        .map((v) => v.percentage)
        .reduce((accumulated, currentValue) => {
            return currentValue + accumulated;
        }, 0);
};
