import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTopMargin, StyledContent, StyledErrorText, StyledResendButon } from './styles';
import { loginWithMobile, verifyUserOTP, clearUserError } from '../../../redux/slices/UserSlice';
import { Button, Loader } from '../../atoms';
import { LoginVerify } from '../../containers';
import { IOTPPage } from './types';
import { FirebaseCaptcha } from '../../../../../../adapters/provider';
import useSegment from '../../../../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../../../../utils/constants/SegmentActionConstants';

const OTPPage: React.FunctionComponent<IOTPPage> = ({ mobile, goBack }) => {
    const dispatch = useDispatch();
    const trackTask = useSegment('task');
    const trackClick = useSegment('click');
    const [otp, setOtp] = React.useState('');
    const userData = useSelector((state: any) => state.user.userData);
    const enteredMobile = useSelector((state: any) => state.user.userMobile.data || '');
    React.useEffect(() => {
        if (!userData.loading && !userData.error && userData.data) {
            const segmentAction = SEGMENT_ACTIONS.TASK.LOGIN_SUCCESS;
            trackTask({
                name: segmentAction.name,
                properties: {
                    login_method: (segmentAction.properties?.login_method as (loginMethod: string) => string)(
                        userData.loginMethodType
                    )
                }
            });
        } else if (!userData.loading && userData.error) {
            const segmentAction = SEGMENT_ACTIONS.TASK.LOGIN_FAIL;
            trackTask({
                name: segmentAction?.name ?? '',
                properties: {
                    login_method: (segmentAction.properties?.login_method as (loginMethod: string) => string)(
                        userData.loginMethodType
                    ),
                    page_title: segmentAction.properties?.page_title,
                    login_status: segmentAction.properties?.login_status,
                    page_url: segmentAction.properties?.page_url,
                    page_id: segmentAction.properties?.page_id
                }
            });
        }
    }, [userData]);
    const goBackClick = () => {
        goBack();
    };
    const verifyOTP = () => {
        const segmentAction = SEGMENT_ACTIONS.CLICK.LOGIN_VERIFY_OTP_BUTTON;
        trackClick({
            name: segmentAction.name,
            properties: {
                page_title: segmentAction.properties?.page_title,
                mobile: (segmentAction.properties?.mobile as (num: string) => string)(enteredMobile) || '',
                login_status: segmentAction.properties?.login_status
            }
        });
        dispatch(clearUserError());
        if (window.confirmationResult?.confirm && otp) {
            dispatch(verifyUserOTP({ otp, enteredMobile }));
        } else {
            goBackClick();
        }
    };
    const resendOTP = () => {
        const segmentAction = SEGMENT_ACTIONS.CLICK.RESEND_OTP;
        trackClick({
            name: segmentAction.name,
            properties: {
                page_title: segmentAction.properties?.page_title,
                mobile: (segmentAction.properties?.mobile as (num: string) => string)(enteredMobile) || '',
                login_status: segmentAction.properties?.login_status
            }
        });
        dispatch(clearUserError());
        window.recaptchaVerifier = new FirebaseCaptcha.RecaptchaVerifier('resend-otp', {
            size: 'invisible'
        });
        if (mobile) dispatch(loginWithMobile(mobile));
    };
    return (
        <StyledContent>
            <LoginVerify showMobileForm={true} phoneNumber={mobile} getOtp={setOtp} goBack={goBack} />
            {userData.error && <StyledErrorText>{userData.error.message}</StyledErrorText>}
            <StyledTopMargin>
                <Button type="primary" disabled={!(otp.length === 6)} onClick={verifyOTP} fullWidth={true}>
                    {userData.loading ? <Loader inverted /> : 'Verify'}
                </Button>
                <StyledResendButon id={'resend-otp'} onClick={resendOTP}>
                    Resend OTP
                </StyledResendButon>
            </StyledTopMargin>
        </StyledContent>
    );
};

export default OTPPage;
