import styled from 'styled-components';
import theme from '../../../theme';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    background-color: ${theme.colors.darkTheme};
    border-radius: 24px;
    padding: 0px 30px;
    align-items: center;
    position: absolute;
    bottom: -70px;
    width: 80%;
    margin: 0 auto;
    @media only screen and (max-width: 768px) {
        padding: 20px;
        flex-direction: column;
        position: initial;
    }
`;

export const VerticalLine = styled.div`
    width: 1px;
    height: 64px;
    background-color: ${theme.colors.borderLine};
    margin-top: 38px;
    margin-bottom: 38px;
    margin-left: 20px;
    margin-right: 20px;
    @media only screen and (max-width: 768px) {
        display: none;
    }
`;

export const WidgetItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    justify-content: center;
    @media only screen and (max-width: 768px) {
        /* flex-direction: column; */
        /* text-align: center; */
        margin-bottom: 12px;
    }
`;

export const StyledTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 200px;
`;

export const WidgetTitleText = styled.span<{ $color: string; $fontSize: string }>(
    (p) => `
        font-size: ${p.$fontSize};
        color: ${p.$color};
        @media only screen and (max-width: 768px) {
            margin-top: 15px;
        }
        
    `
);
