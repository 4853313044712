import { useState, useEffect } from 'react';

const useResponsive = (query: string): boolean => {
    const [matches, setMatches] = useState<boolean>(false);

    useEffect(() => {
        const media: MediaQueryList = window.matchMedia(query);
        const updateMatches = () => {
            if (media.matches !== matches) {
                setMatches(media.matches);
            }
        };
        updateMatches();

        const listener = () => {
            updateMatches();
        };
        media.addEventListener('change', listener);
        return () => {
            media.removeEventListener('change', listener);
        };
    }, [matches, query]);

    return matches;
};

export default useResponsive;
