import styled from 'styled-components';
import SVG from 'react-inlinesvg';
export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 500px;
    text-align: center;
`;

export const StyledBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 20px 28px 28px;
    gap: 16px;
`;

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 18px;
    width: 100%;
`;

export const StyledTagWrapper = styled.div`
    display: flex;
    padding: 28px 0px 0px 28px;
`;

export const StyledIconContainer = styled.div`
    display: flex;
    border-right: 1px solid ${(p) => p.theme.colors.mutedTransparent};
    align-items: center;
    padding-right: 20px;
    margin-right: 20px;
`;

export const StyledDivider = styled.div`
    display: flex;
    border-top: 1px solid ${(p) => p.theme.colors.mutedTransparent};
    margin: 8px 0px;
`;

export const StyledButtonContainer = styled.div`
    padding: 16px 20px;
    display: flex;
    border: 1px solid ${(p) => p.theme.colors.mutedTransparent};
`;

export const StyledImgBackground = styled.div`
    display: flex;
    width: 500px;
    height: 178px;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: ${(p) => p.theme.colors.loopEmerald};
    @media (max-width: 768px) {
        width: auto;
        height: 128px;
    }
`;

export const StyledIcon = styled.img`
    width: 42px;
    height: 42px;
`;

export const StyledClose = styled(SVG)`
    position: absolute;
    margin-right: 24px;
    margin-top: 24px;
    top: 0;
    right: 0;
    cursor: pointer;
    & path {
        pointer-events: all;
        fill: ${(p) => p.theme.colors.white};
    }
`;
