import React from 'react';
import { StyledBlockingModalContainer, StyledContent, StyledDescription, StyledIcon } from './styles';
import { IBlockingModal } from './types';
import { LoopButton, Modal, Typography } from '@loophealth/loop-ui-web-library';
import { InfoCircleIcon } from '../../../assets/img';

const BlockingModal: React.FunctionComponent<IBlockingModal> = ({ isVisible, setIsVisible }) => {
    return (
        <Modal isVisible={isVisible} setIsVisible={setIsVisible}>
            <StyledBlockingModalContainer>
                <StyledContent>
                    <StyledIcon src={InfoCircleIcon} />
                    <Typography variant="large" weight="bold">{`100% of Sum Insured not assigned`}</Typography>
                </StyledContent>
                <StyledDescription>
                    <Typography variant="small">
                        Please ensure that 100% of sum insured is assigned to nominees
                    </Typography>
                </StyledDescription>
                <LoopButton variant="filled" onClick={() => setIsVisible(false)}>
                    Got it
                </LoopButton>
            </StyledBlockingModalContainer>
        </Modal>
    );
};

export default BlockingModal;
