import React from 'react';
import {
    StyledContainerWrapper,
    StyledContainer,
    StyledMenuContainer,
    NavigatorContainer,
    StyledContentContainer
} from './styles';
import { Stepper } from '../../../../atoms';
import { useHistory, useLocation } from 'react-router-dom';
import { ReduxState } from '../../../../../redux';
import { useDispatch, useSelector } from 'react-redux';
import { EnrolmentNavigator } from '../../../../containers';
import { INTERNAL_ROUTES } from '../../../../../utils/constants';
import { fetchUserPolicyList } from '../../../../../topup-src/redux/slices/PolicyListSlice';
import { enrolUserPolicyFamily } from '../../../../../redux/slices/EnrolmentSlice/thunks';
import { FreshChat } from '../../../../atoms/FreshChat';
import { LocationState } from '../../../../../topup-src/utils/types';
import { fetchParentalPolicyData } from '../../../../../topup-src/redux/slices/PolicyListSlice/thunks';
import { checkParentalPolicy } from '../../../../../utils/PolicyServices';
import { setCurrentStepperStep } from '../../../../../redux/slices/StepperSlice';
import TopUpPage from '../../../../containers/TopUpPage';

const SuperTopUpPage: React.FunctionComponent = () => {
    const location = useLocation<LocationState>();
    const selectedMemberTopUp = useSelector((state: ReduxState) => state.enrolment.selectedMemberTopUp);
    const currentSelectedStep = useSelector((state: ReduxState) => state.stepperData.currentStepperStep);
    const userId = useSelector((state: ReduxState) => state.user.userData.data?.userId || '');
    const topUpDetails: any = useSelector((state: ReduxState) => state.TopUp_policyData.memberTopUp);
    const history = useHistory();
    const dispatch = useDispatch();
    const currentlySelectedPolicy = useSelector((state: ReduxState) => state.enrolment.currentlySelectedPolicy);
    const policyId = useSelector((state: ReduxState) => state.TopUp_applicationState.topupPolicyId);
    React.useEffect(() => {
        dispatch(setCurrentStepperStep({ currentStepperStep: 2 }));
        if (currentlySelectedPolicy && checkParentalPolicy(currentlySelectedPolicy)) {
            const parentalPolicyId = currentlySelectedPolicy?.policyId ?? '';
            dispatch(fetchParentalPolicyData({ userId, parentalPolicyId }));
        } else {
            dispatch(fetchUserPolicyList({ userId }));
        }
    }, [userId]);
    const showSummary = (isTopUpSelected: boolean) => {
        dispatch(setCurrentStepperStep({ currentStepperStep: currentSelectedStep + 1 }));
        if (isTopUpSelected) {
            if (topUpDetails.hasOwnProperty('annualPremium')) {
                const dependentsArray = topUpDetails?.userIds.filter((item: string) => item !== userId);
                dispatch(
                    enrolUserPolicyFamily({
                        userId: userId,
                        policyId: policyId,
                        slabId: topUpDetails.slabId,
                        enrolmentStatus: 'PENDING',
                        policyStatus: 'PENDING',
                        dependentIds: dependentsArray,
                        parentalPolicyId:
                            currentlySelectedPolicy && checkParentalPolicy(currentlySelectedPolicy)
                                ? currentlySelectedPolicy.policyId
                                : ''
                    })
                );
                history.push(INTERNAL_ROUTES.summary, { previousUrl: location.pathname });
            }
        } else {
            history.push(INTERNAL_ROUTES.summary, { previousUrl: location.pathname });
        }
    };
    return (
        <StyledContainer>
            <StyledContainerWrapper>
                <FreshChat />
                <StyledMenuContainer>
                    <Stepper />
                </StyledMenuContainer>
                <StyledContentContainer>
                    <TopUpPage />
                </StyledContentContainer>
            </StyledContainerWrapper>
            <NavigatorContainer>
                <EnrolmentNavigator
                    loading={topUpDetails.hasOwnProperty('annualPremium') ? false : true}
                    isEnrolMemberValid={true}
                    showSummary={showSummary}
                    memberTopUp={selectedMemberTopUp}
                    currentStep={currentSelectedStep}
                    nomineeConsumedPercentage={100}
                />
            </NavigatorContainer>
        </StyledContainer>
    );
};

export default SuperTopUpPage;
