import React from 'react';
import { IUseMobileNumberValidationType } from '../containers/ManageMemberModal/types';

export const useMobileNumberValidation = (formMobileNumber: string | undefined): IUseMobileNumberValidationType => {
    const mobileRegex = /^\d+$/;
    const [mobileNumber, setMobileNumber] = React.useState<string>(formMobileNumber ? formMobileNumber : '');

    const validateMobileNumber = (): string => {
        if (mobileNumber.length) {
            if (!mobileRegex.test(mobileNumber.toLowerCase()) || mobileNumber.length !== 10) {
                return 'Please enter a valid mobile number';
            }
        }
        return '';
    };

    const onMobileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value.trim();
        if ((mobileRegex.test(value) && value.length <= 10) || !value) {
            setMobileNumber(value);
        }
    };

    return {
        mobileNumber,
        setMobileNumber,
        validateMobileNumber,
        onMobileChange
    };
};
