import styled from 'styled-components';

export const Container = styled.div<{ $margin?: string }>`
    display: flex;
    /* flex-direction: column; */
    margin: ${(p) => p.$margin || '0px'};
    flex: 2;
    @media (max-width: 768px) {
        margin: 0px;
    }
`;

export const ButtonContainer = styled.div`
    max-width: 230px;
    display: flex;
    flex: 1;
    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const StyledCheckboxSection = styled.div`
    display: flex;
    margin: 20px 0px 0px 0px;
    align-items: flex-end;
    @media (max-width: 768px) {
        flex-direction: column;
        gap: 8px;
        display: flex;
        align-items: flex-start;
    }
`;

export const StyledImg = styled.img`
    width: 28px;
    margin-bottom: -3px;
`;

export const StyledInput = styled.input.attrs({ type: 'checkbox' })`
    width: 35px;
    height: 20px;
    border-radius: 4px;
    cursor: pointer;
    accent-color: #02a2768c;
    &::checked {
        background-color: #025f4c;
    }
`;

export const StyledContainer = styled.div`
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const StyledInfoContainer = styled.div`
    display: flex;
    gap: 4px;
`;

export const StyledErrorText = styled.span`
    color: ${(p) => p.theme.colors.error};
    margin: 15px 5px;
    font-size: ${(p) => p.theme.fontSizes.label};
`;
