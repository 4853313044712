import { IFormErrorType, IRelationship } from './types';
import { checkHasDependent } from './ManageMemberService';
import { IAllDependents, IDependent } from '../../../redux/slices/PolicyListSlice/types';

export const initialFormErrors: IFormErrorType = {
    firstName: '',
    lastName: '',
    relationship: '',
    dob: '',
    gender: '',
    mobileNumber: '',
    global: ''
};

export const handleDependentsUtil = (
    selectedRelationship: IRelationship,
    allowedDependents: IDependent[] | undefined
): IDependent | undefined => {
    if (selectedRelationship.toLowerCase() == 'parent/parent-in-law') {
        selectedRelationship = 'Parent';
    }
    return checkHasDependent(allowedDependents, selectedRelationship);
};

export const handleExistingDependentPresentUtil = (
    dependentList: IAllDependents,
    existingDependent: string
): IDependent | undefined => {
    return dependentList.data?.find((user) => (user.userId as string) === existingDependent);
};

export const parseError = (error: string | Record<string, unknown> | undefined | null): string => {
    if (error === undefined || error === null) {
        return 'No error message';
    } else if (typeof error === 'string') {
        return error;
    } else if (typeof error === 'object') {
        const errorValues = Object.values(error);
        if (errorValues.length) {
            return String(errorValues[0]);
        }
    }
    return 'No error message';
};

export const liveInOptions = [
    {
        name: 'Spouse',
        value: 'Spouse'
    },
    {
        value: 'live-in-partner',
        name: 'Live-In Partner'
    }
];
