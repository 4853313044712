import React from 'react';
import { IModalProps, ITabItem } from './types';
import {
    StyledPolicyIncExcBodyItem,
    StyledPolicyIncExcBodyItemHeader,
    StyledPolicyIncExcTitleIcon,
    StyledPolicyIncExcList,
    StyledWrapper
} from './styles';
import { Tabs, Typography } from '@loophealth/loop-ui-web-library';
import PolicyCoverageIncExcItem from './PolicyCoverageIncExcItem';
import { CheckIcon, CrossIcon } from '../../../assets/img';
import { IPolicyTabs, tabItems } from './utils';

const PolicyModalMobile: React.FunctionComponent<IModalProps> = ({ data }) => {
    const [selectedTab, setSelectedTab] = React.useState<ITabItem | null>(tabItems[0]);
    const handleClick = (e: ITabItem) => {
        setSelectedTab(e);
    };
    return (
        <StyledWrapper>
            <Tabs.OutlineTab tabItems={tabItems} onClick={(e: ITabItem) => handleClick(e)} selectedTab={selectedTab} />
            {selectedTab?.id === IPolicyTabs.Inclusions ? (
                <StyledPolicyIncExcBodyItem>
                    <StyledPolicyIncExcBodyItemHeader>
                        <StyledPolicyIncExcTitleIcon src={CheckIcon} />
                        <Typography variant="small" weight="semiBold">
                            Inclusions
                        </Typography>
                    </StyledPolicyIncExcBodyItemHeader>
                    {data?.inclusion.length ? (
                        <StyledPolicyIncExcList>
                            {data?.inclusion.map((term) => (
                                <PolicyCoverageIncExcItem key={term.label} term={term} />
                            ))}
                        </StyledPolicyIncExcList>
                    ) : (
                        <Typography variant="small">No inclusions to show</Typography>
                    )}
                </StyledPolicyIncExcBodyItem>
            ) : (
                <StyledPolicyIncExcBodyItem>
                    <StyledPolicyIncExcBodyItemHeader>
                        <StyledPolicyIncExcTitleIcon src={CrossIcon} />
                        <Typography variant="small" weight="semiBold">
                            Exclusions
                        </Typography>
                    </StyledPolicyIncExcBodyItemHeader>
                    {data?.exclusion.length ? (
                        <StyledPolicyIncExcList>
                            {data?.exclusion.map((term) => (
                                <PolicyCoverageIncExcItem key={term.label} isExclusion={true} term={term} />
                            ))}
                        </StyledPolicyIncExcList>
                    ) : (
                        <Typography variant="small">No exclusions to show</Typography>
                    )}
                </StyledPolicyIncExcBodyItem>
            )}
        </StyledWrapper>
    );
};

export default PolicyModalMobile;
