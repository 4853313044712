import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 401px;
`;
export const StyledContent = styled.div`
    padding: 30px 30px 0px 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 24px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    @media (max-width: 768px) {
        padding: 10px;
        gap: 16px;
    }
`;

export const StyledDescription = styled.p`
    text-align: center;
`;

export const StyledErrorText = styled.div(
    (p) => `
    color: ${p.theme.colors.error};
    font-size: ${p.theme.fontSizes.label};
    margin-bottom:20px;
    text-align: center;
`
);

export const StyledButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const StyledIcon = styled.img`
    width: 72px;
    height: 72px;
    @media (max-width: 768px) {
        width: 48px;
        height: 48px;
    }
`;
