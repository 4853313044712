import React from 'react';
import theme from '../../../theme';
import { TextView } from '../../atoms/TextView';
import { CoverageContainer, TopUpOfferContainer, TraditionalContainer } from './styles';

const TopUpCrossCoverage: React.FunctionComponent = () => {
    const commonTextProps = {
        color: theme.colors.beyondGrey,
        fontSize: '16px',
        fontWeight: '400',
        margin: '0px 0px 5px 0px'
    };
    return (
        <TraditionalContainer
            $margin="0px 15px 0px 0px"
            $backgroundColor={theme.colors.lightGrey}
            $borderRadius={'10px'}
            $padding={'10px 0px'}
        >
            <TopUpOfferContainer>
                <TextView fontSize="18px" fontWeight="500" margin="20px 0px 0px 0px" color={theme.colors.loopEmerald}>
                    Other Retails Policies
                </TextView>
            </TopUpOfferContainer>

            <CoverageContainer $margin="32px 0px 40px 0px" $direction="column">
                <TextView {...commonTextProps}>Waiting period between</TextView>
                <TextView {...commonTextProps} color={theme.colors.planRed}>
                    2-4 years
                </TextView>
            </CoverageContainer>
            <CoverageContainer $margin="0px 0px 40px 0px" $direction="column">
                <TextView {...commonTextProps}>
                    Cover parents only{' '}
                    <TextView {...commonTextProps} color={theme.colors.planRed}>
                        upto
                    </TextView>
                </TextView>
                <TextView {...commonTextProps} color={theme.colors.planRed}>
                    65 years
                </TextView>
            </CoverageContainer>
            <CoverageContainer $margin="0px 0px 40px 0px" $direction="column">
                <TextView {...commonTextProps}>
                    May require{' '}
                    <TextView {...commonTextProps} color={theme.colors.planRed}>
                        5-10%
                    </TextView>
                </TextView>
                <TextView {...commonTextProps} color={theme.colors.planRed}>
                    payment
                </TextView>
            </CoverageContainer>
            <CoverageContainer $direction="column" $margin="10px 0px 40px 0px">
                <TextView {...commonTextProps}>
                    Requires{' '}
                    <TextView {...commonTextProps} color={theme.colors.planRed}>
                        coordination with <br /> different agents
                    </TextView>
                    {' for claims'}
                </TextView>
            </CoverageContainer>
        </TraditionalContainer>
    );
};

export default TopUpCrossCoverage;
