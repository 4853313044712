/* eslint-disable quotes */
import React from 'react';
import { ClipboardIcon, PersonImg, QuotesImg } from '../../../assets/img';
import theme from '../../../theme';
import { ITopupTemplate } from './types';
import {
    ButtonContainer,
    Container,
    ContentContainer,
    StyledContainerWrapper,
    StyledHeaderText,
    StyledInnerHeaderText,
    VerticalLine
} from './styles';
import { TextView } from '../../atoms/TextView';
import { useLocation } from 'react-router-dom';
import { LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import TopupIdentityWidget from '../../atoms/TopupIdentityWidget';
import useSegment from '../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';
import { getSegmentPageName } from '../../../utils/common';

const TopupTemplate: React.FunctionComponent<ITopupTemplate> = ({ templateID, onClickExplore }) => {
    const trackClick = useSegment('click');
    const location = useLocation();
    const currentPageAction = SEGMENT_ACTIONS.PAGE[getSegmentPageName(location.pathname) as any];
    const segmentAction = SEGMENT_ACTIONS.CLICK.EXPLORE_BENEFITS;
    const handleClick = (e: React.MouseEvent<Element, MouseEvent>) => {
        onClickExplore(e);
        trackClick({
            name: segmentAction.name,
            properties: {
                describe: segmentAction.properties?.describe || '',
                login_status: segmentAction.properties?.login_status,
                page_title: segmentAction.properties?.page_title,
                page_url: (currentPageAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                page_id: (currentPageAction.properties?.page_id as (path: string) => string)(location.pathname) || ''
            }
        });
    };
    const getTemplateType = (templateID: number) => {
        switch (templateID) {
        case 2:
            return (
                <>
                    <TextView fontSize="32px" color={theme.colors.white} lineHeight="45px">
                        {'Protection for '}
                        <TextView fontSize="32px" color={theme.colors.coverYellow}>
                            {' all costs'}
                        </TextView>
                            , at
                        <TextView fontSize="32px" color={theme.colors.coverYellow}>
                            {' all costs'}
                        </TextView>
                    </TextView>
                    <ContentContainer>
                        <StyledContainerWrapper>
                            <TopupIdentityWidget
                                src={ClipboardIcon}
                                width="32px"
                                backgroundColor={theme.colors.planRed}
                                textComponent={
                                    <>
                                        <TextView fontSize="18px" lineHeight="21px">
                                                Pre-Existing Diseases
                                        </TextView>
                                        <TextView fontSize="16px" margin="7px 0px 0px 0px">
                                                No waiting period
                                        </TextView>
                                    </>
                                }
                            />
                            <VerticalLine />
                            <TopupIdentityWidget
                                src={PersonImg}
                                width="32px"
                                backgroundColor={theme.colors.purple}
                                textComponent={
                                    <>
                                        <TextView fontSize="18px" lineHeight="21px">
                                                Parents Covered
                                        </TextView>
                                        <TextView fontSize="16px" margin="7px 0px 0px 0px">
                                                At zero cost
                                        </TextView>
                                    </>
                                }
                            />
                            <VerticalLine />
                            <TopupIdentityWidget
                                src={QuotesImg}
                                width="32px"
                                backgroundColor={theme.colors.teamTurquoise}
                                textComponent={<TextView fontSize="18px">Customizable Top Ups</TextView>}
                            />
                        </StyledContainerWrapper>
                    </ContentContainer>
                </>
            );
            break;
        case 3:
            return (
                <>
                    <TextView fontSize="32px" color={theme.colors.white} lineHeight="45px" textAlign={'center'}>
                            The illness will come, but
                        <TextView fontSize="32px" color={theme.colors.coverYellow} textAlign={'center'}>
                            {' you’ll decide '} <br />
                        </TextView>
                            what it takes from you.
                    </TextView>
                    <ContentContainer>
                        <StyledContainerWrapper>
                            <TopupIdentityWidget
                                src={ClipboardIcon}
                                width="32px"
                                backgroundColor={theme.colors.planRed}
                                textComponent={
                                    <TextView fontSize="18px" lineHeight="24px">
                                            Increase coverage by <br /> up to 20 Lakhs
                                    </TextView>
                                }
                            />
                            <VerticalLine />
                            <TopupIdentityWidget
                                src={PersonImg}
                                width="32px"
                                backgroundColor={theme.colors.purple}
                                textComponent={<TextView fontSize="18px">7500+ Network Hospitals</TextView>}
                            />
                            <VerticalLine />
                            <TopupIdentityWidget
                                src={QuotesImg}
                                width="32px"
                                backgroundColor={theme.colors.teamTurquoise}
                                textComponent={<TextView fontSize="18px">Coverage from day 1</TextView>}
                            />
                        </StyledContainerWrapper>
                    </ContentContainer>
                </>
            );
            break;
        default:
            return (
                <>
                    <StyledHeaderText>
                        <StyledInnerHeaderText $color={theme.colors.white}>
                            {"You can't put a price on your"}
                            <StyledInnerHeaderText $color={theme.colors.coverYellow}>
                                {' family’s '} {' health'}
                            </StyledInnerHeaderText>
                            {', but you can recognize its value.'}
                        </StyledInnerHeaderText>
                    </StyledHeaderText>
                    <StyledContainerWrapper>
                        <TopupIdentityWidget
                            src={ClipboardIcon}
                            width="32px"
                            backgroundColor={theme.colors.planRed}
                            textComponent={
                                <Typography variant="large" color="tertiary">
                                        Parents covered without medical tests
                                </Typography>
                            }
                        />
                        <VerticalLine />
                        <TopupIdentityWidget
                            src={PersonImg}
                            width="48px"
                            backgroundColor={theme.colors.purple}
                            textComponent={
                                <Typography variant="large" color="tertiary">
                                        Zero waiting period
                                </Typography>
                            }
                        />
                        <VerticalLine />
                        <TopupIdentityWidget
                            src={QuotesImg}
                            width="32px"
                            backgroundColor={theme.colors.teamTurquoise}
                            textComponent={
                                <Typography variant="large" color="tertiary">
                                        On ground support for claims
                                </Typography>
                            }
                        />
                    </StyledContainerWrapper>
                </>
            );
        }
    };
    return (
        <Container>
            {getTemplateType(templateID)}
            <ButtonContainer>
                <LoopButton variant="filled" onClick={(e) => handleClick(e)}>
                    Explore benefits
                </LoopButton>
            </ButtonContainer>
        </Container>
    );
};

export default React.memo(TopupTemplate);
