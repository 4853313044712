import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
    StyledContainer,
    StyledContentContainer,
    StyledMenuContainer,
    NavigatorContainer,
    StyledDivContainer,
    StyledTitleText,
    StyledContentWrapper,
    StyledStickyContainer,
    StyledButtonContainer
} from './styles';
import { ReduxState } from '../../../../../redux';
import {
    fetchPolicyDetails,
    fetchTopUpOptions,
    resetUpdateTopUpOption,
    setSelectedMemberTopUp
} from '../../../../../redux/slices/EnrolmentSlice';
import { fetchNomineeConfig, setCurrentStepperStep } from '../../../../../redux/slices/StepperSlice';

import {
    insuranceCardDateFormat,
    getAvailableTopup,
    getDaysLeft,
    getSumInsured,
    checkIfTopUpAvailable,
    formatAmount
} from '../../../../../utils/common';
import { ErrorContainer, Loader, Stepper } from '../../../../atoms';
import { EnrolMembers, EnrolmentCard, EnrolmentNavigator, TopUpMembers, SummaryModal } from '../../../../containers';
import { INTERNAL_ROUTES } from '../../../../../utils/constants';
import { PARENTAL_POLICY } from '../../../../../utils/constants';
import { setShowCallbackToolTip } from '../../../../../topup-src/redux/slices/ApplicationStateSlice';
import { LocationState } from '../../../../../topup-src/utils/types';
import { useLocation } from 'react-router-dom';
import { checkTopUpAvailable } from '../../../../../utils/PolicyServices';
import { LoopButton, Toast } from '@loophealth/loop-ui-web-library';
import BlockingModal from '../../../../containers/ManageNomineesComponent/BlockingModal';
import { EnrolmentStatus } from '../DashboardPage/utils';
import { IDependent } from '../../../../../redux/slices/PolicyListSlice/types';
import { SEGMENT_ACTIONS } from '../../../../../utils/constants/SegmentActionConstants';
import useSegment from '../../../../../utils/hooks/useSegment';
import { useNomineesData } from '../../../../hooks/useNomineesData';

const EnrolmentPage: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const location = useLocation<LocationState>();
    const history = useHistory();
    const trackClick = useSegment('click');
    const userId = useSelector((state: ReduxState) => state.user?.userData?.data?.userId || '');
    const selectedMemberTopUp = useSelector((state: ReduxState) => state.enrolment.selectedMemberTopUp);
    const companyId = useSelector((state: ReduxState) => state.user?.userData?.data?.employer || '');
    const currentlySelectedPolicy = useSelector((state: ReduxState) => state.enrolment.currentlySelectedPolicy);
    const topUpOptions = useSelector((state: ReduxState) => state.enrolment.topUpOptions);
    const updateTopUpOptions = useSelector((state: ReduxState) => state.enrolment.updateTopUpOption);
    const currentSelectedStep = useSelector((state: ReduxState) => state.stepperData.currentStepperStep);
    const [nomineeConsumedPercentage, setNomineeConsumedPercentage] = React.useState(0);
    const topupAvailable = !topUpOptions.loading ? (topUpOptions.data?.length ? true : false) : true;
    const checkSuperTopUp = checkTopUpAvailable();
    const superTopUpAvailable = checkIfTopUpAvailable(checkSuperTopUp, currentlySelectedPolicy);
    const [summaryModal, setSummaryModal] = React.useState<boolean>(false);
    const [blockingModal, setBlockingModal] = React.useState<boolean>(false);
    const isNomineeEnabledForCompany = useSelector(
        (state: ReduxState) => state.stepperData.nomineeConfig.data.isEnabled
    );
    const isNomineeConfigLoading = useSelector((state: ReduxState) => state.stepperData.nomineeConfig.loading);
    const [enableSubmit, setEnableSubmit] = React.useState<boolean>(true);
    const [previousDetails, setPreviousDetails] = React.useState<IDependent[]>([]);
    const [isNomineeLoading, setIsNomineeLoading] = React.useState(true);
    React.useEffect(() => {
        dispatch(setCurrentStepperStep({ currentStepperStep: 1 }));
        dispatch(setShowCallbackToolTip({ showCallbackToolTip: false }));
    }, []);
    const { nomineesData } = useNomineesData(userId, currentlySelectedPolicy?.policyId ?? '', setIsNomineeLoading);
    // handle the different cases and cleanup of currentlySelectedPolicy
    React.useEffect(() => {
        if (!currentlySelectedPolicy) {
            history.push(INTERNAL_ROUTES.dashboard, { previousUrl: location.pathname });
            return;
        }
        if (updateTopUpOptions) {
            dispatch(resetUpdateTopUpOption());
        }

        if (currentlySelectedPolicy && currentlySelectedPolicy.policyId) {
            if (companyId) {
                dispatch(fetchNomineeConfig({ companyId, policyId: currentlySelectedPolicy.policyId }));
            }
            // fetch Company Provided topup options parallelly
            dispatch(
                fetchTopUpOptions({
                    policyId: currentlySelectedPolicy.policyId
                })
            );
            // add dispatch here
            dispatch(fetchPolicyDetails({ policyId: currentlySelectedPolicy.policyId }));
        }
    }, [currentlySelectedPolicy]);

    // React.useEffect(() => {
    //     const dependents = currentlySelectedPolicy?.dependents || [];
    //     console.log('dependents --> ', dependents);
    //     const isEnrolmentCompleted =
    //         currentlySelectedPolicy?.enrolmentStatus?.toLowerCase() === EnrolmentStatus.ENROLED;
    //     const isEnrolmentLapsed =
    //         currentlySelectedPolicy?.enrolmentDueDate &&
    //         getDaysLeft(currentlySelectedPolicy?.enrolmentDueDate) === 'OVERDUE';
    //     const changedDependents = currentlySelectedPolicy?.dependents ? [...currentlySelectedPolicy.dependents] : [];
    //     if (nomineesData && nomineesData.length > 0) {
    //         changedDependents.push(...nomineesData);
    //     }
    //     const isChanged =
    //         previousDetails.length !== 0 &&
    //         Object.values(changedDependents).every((p: any) => changedDependents[p] === previousDetails[p]);
    //     setPreviousDetails(changedDependents);

    //     if (isEnrolmentCompleted && !isEnrolmentLapsed && !isChanged) setEnableSubmit(false);
    //     else setEnableSubmit(true);
    // }, [currentlySelectedPolicy?.dependents]);

    React.useEffect(() => {
        let changedDependents = currentlySelectedPolicy?.dependents ? [...currentlySelectedPolicy.dependents] : [];
        if (!isNomineeLoading && nomineesData && nomineesData.length > 0) {
            const mappedNomineesData: IDependent[] = nomineesData.map((nominee) => ({
                relationship: nominee.relationship as 'Self' | 'Spouse' | 'Child' | 'Parent' | 'Parent-in-law',
                doB: new Date(nominee.dob).getTime(),
                gender: nominee.gender === 'male' ? 'male' : 'female',
                mobile: '',
                firstName: nominee.firstName,
                lastName: nominee.lastName,
                userId: nominee.id || '' // Ensure userId is a string
            }));
            changedDependents = changedDependents.concat(mappedNomineesData);
        }
        const isEnrolmentCompleted =
            currentlySelectedPolicy?.enrolmentStatus?.toLowerCase() === EnrolmentStatus.ENROLED;
        const isEnrolmentLapsed =
            currentlySelectedPolicy?.enrolmentDueDate &&
            getDaysLeft(currentlySelectedPolicy?.enrolmentDueDate) === 'OVERDUE';
        const isChanged =
            previousDetails.length !== 0 &&
            Object.values(changedDependents).every((p: any) => changedDependents[p] === previousDetails[p]);
        setPreviousDetails(changedDependents);

        if (isEnrolmentCompleted && !isEnrolmentLapsed && !isChanged) setEnableSubmit(false);
        else setEnableSubmit(true);
    }, [currentlySelectedPolicy?.dependents, nomineesData]);

    //

    const showSummary = () => {
        history.push(INTERNAL_ROUTES.summary, { previousUrl: location.pathname });
    };

    const isEnrolMemberValidCheck = (): boolean => {
        if (currentlySelectedPolicy) {
            if (PARENTAL_POLICY.includes(currentlySelectedPolicy?.familyStruture)) {
                return !!(
                    currentlySelectedPolicy &&
                    currentlySelectedPolicy?.dependents.filter(
                        (dep: any) =>
                            dep?.relationship?.toLowerCase() === 'parent' ||
                            dep?.relationship?.toLowerCase() === 'parent-in-law'
                    ).length > 0
                );
            } else {
                return !!(
                    currentlySelectedPolicy &&
                    currentlySelectedPolicy?.dependents.filter(
                        (dep: any) => dep?.relationship?.toLowerCase() === 'self'
                    ).length > 0
                );
            }
        }
        return false;
    };

    const onProceed = () => {
        const segmentAction = SEGMENT_ACTIONS.CLICK.SUBMIT_AND_PROCEED;
        trackClick({
            name: segmentAction.name,
            properties: {
                page_url: (segmentAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                page_id: (segmentAction.properties?.page_id as (path: string) => string)(location.pathname) || '',
                login_status: segmentAction.properties?.login_status,
                page_title: segmentAction.properties?.page_title,
                policy_id: (segmentAction.properties?.policy_id as (policyId: string) => string)(
                    currentlySelectedPolicy?.policyId ?? ''
                )
            }
        });
        if (isNomineeEnabledForCompany && !isNomineeConfigLoading && nomineeConsumedPercentage != 100) {
            setBlockingModal(true);
        } else setSummaryModal(true);
    };

    return (
        <StyledContainer>
            <StyledContentWrapper>
                {superTopUpAvailable && (
                    <StyledMenuContainer>
                        <Stepper />
                    </StyledMenuContainer>
                )}
                <StyledContentContainer>
                    {currentlySelectedPolicy && (
                        <>
                            {currentSelectedStep === 1 && (
                                <Toast.ToastContainer
                                    maxWidth="800px"
                                    expires={7000}
                                    closeOnClick={true}
                                    variant="light"
                                    position="top-center"
                                >
                                    <EnrolmentCard
                                        policyId={currentlySelectedPolicy.policyId}
                                        policyImage={currentlySelectedPolicy.policyImage}
                                        insuranceName={currentlySelectedPolicy.policyName}
                                        insuranceCategory={currentlySelectedPolicy.policyType}
                                        sumInsured={formatAmount(currentlySelectedPolicy.sumInsured)}
                                        dependentsInsured={currentlySelectedPolicy.familyStruture}
                                        enrolmentDueDate={
                                            currentlySelectedPolicy?.enrolmentDueDate
                                                ? insuranceCardDateFormat(currentlySelectedPolicy.enrolmentDueDate)
                                                : 'N/A'
                                        }
                                        daysLeft={getDaysLeft(currentlySelectedPolicy.enrolmentDueDate)}
                                        availableTopUp={getAvailableTopup(currentlySelectedPolicy.availableTopUp)}
                                        topUpAdded={getSumInsured(currentlySelectedPolicy.topUpAdded, '')}
                                    >
                                        <EnrolMembers
                                            dependents={currentlySelectedPolicy.dependents}
                                            familyStructure={currentlySelectedPolicy.familyStruture}
                                            nomineeConsumedPercentage={nomineeConsumedPercentage}
                                            setNomineeConsumedPercentage={setNomineeConsumedPercentage}
                                        />
                                    </EnrolmentCard>
                                </Toast.ToastContainer>
                            )}
                            {topupAvailable && currentSelectedStep === 2 && (
                                <>
                                    <EnrolmentCard
                                        policyId={currentlySelectedPolicy.policyId}
                                        policyImage={currentlySelectedPolicy.policyImage}
                                        insuranceName={currentlySelectedPolicy.policyName}
                                        insuranceCategory={currentlySelectedPolicy.policyType}
                                        sumInsured={formatAmount(currentlySelectedPolicy.sumInsured)}
                                        dependentsInsured={currentlySelectedPolicy.familyStruture}
                                        enrolmentDueDate={
                                            currentlySelectedPolicy?.enrolmentDueDate
                                                ? insuranceCardDateFormat(currentlySelectedPolicy.enrolmentDueDate)
                                                : 'N/A'
                                        }
                                        daysLeft={getDaysLeft(currentlySelectedPolicy.enrolmentDueDate)}
                                        availableTopUp={getAvailableTopup(currentlySelectedPolicy.availableTopUp)}
                                        topUpAdded={getSumInsured(currentlySelectedPolicy.topUpAdded, '')}
                                    >
                                        {topUpOptions.loading && <Loader />}
                                        {topUpOptions.error && <ErrorContainer message={topUpOptions.error.message} />}
                                        {!!topUpOptions.data?.length ? (
                                            <TopUpMembers
                                                selectedOption={selectedMemberTopUp}
                                                options={topUpOptions.data}
                                                setTopUp={(val) =>
                                                    dispatch(
                                                        setSelectedMemberTopUp({
                                                            selectedMemberTopUp: val
                                                        })
                                                    )
                                                }
                                            />
                                        ) : (
                                            <StyledDivContainer>
                                                <StyledTitleText>No TopUps Available</StyledTitleText>
                                            </StyledDivContainer>
                                        )}
                                    </EnrolmentCard>
                                </>
                            )}
                        </>
                    )}
                </StyledContentContainer>
            </StyledContentWrapper>

            <NavigatorContainer>
                {superTopUpAvailable ? (
                    <EnrolmentNavigator
                        nomineeConsumedPercentage={nomineeConsumedPercentage}
                        loading={updateTopUpOptions.loading}
                        isEnrolMemberValid={isEnrolMemberValidCheck()}
                        showSummary={showSummary}
                        memberTopUp={selectedMemberTopUp}
                        currentStep={currentSelectedStep}
                    />
                ) : (
                    <StyledStickyContainer>
                        <StyledButtonContainer>
                            <LoopButton variant={enableSubmit ? 'filled' : 'disabled'} onClick={onProceed}>
                                Submit and Proceed
                            </LoopButton>
                        </StyledButtonContainer>
                    </StyledStickyContainer>
                )}
            </NavigatorContainer>

            <SummaryModal isVisible={summaryModal} setIsVisible={setSummaryModal} />
            <BlockingModal isVisible={blockingModal} setIsVisible={setBlockingModal} />
        </StyledContainer>
    );
};

export default EnrolmentPage;
