import styled from 'styled-components';
import theme from '../../../theme';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 60px;
    align-items: center;
    background-color: ${theme.colors.white};
    @media only screen and (max-width: 768px) {
        padding: 30px 20px;
    }
`;
export const StyledTabWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        margin-bottom: 30px;
    }
`;
export const StyledTabContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 49%;
    border-radius: 16px;
    background-color: ${theme.colors.loopEmerald};
    align-items: flex-end;
    @media only screen and (max-width: 768px) {
        width: 100%;
        margin: 10px 0px;
    }
`;
export const StyledTabSection = styled.div`
    display: flex;
    padding: 30px 0px 30px 20px;
    flex-direction: column;
    gap: 16px;
    @media only screen and (max-width: 768px) {
        width: 100%;
        gap: 12px;
    }
`;
export const StyledHospitalLink = styled.span`
    text-decoration: underline;
    text-underline-offset: 3px;
    cursor: pointer;
`;

export const StyledImage = styled.img`
    width: 190px;
    display: flex;
    @media only screen and (max-width: 768px) {
        width: 150px;
    }
`;

export const BenefitImageContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

export const RowContainer = styled.div<{ $margin?: string | null }>`
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    margin: ${(p) => p.$margin};
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        margin: 0px 0px 0px 0px;
    }
`;

export const StyledInfoContainer = styled.div`
    display: flex;
    margin: 50px 0px 20px 0px;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        margin: 10px;
        background-color: ${theme.colors.mutedBG};
        padding: 16px;
        border-radius: 12px;
    }
`;

export const StyledInfoHeaderContainer = styled.div`
    display: flex;
    margin: 30px 30px 50px 30px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        margin: 20px 0px;
    }
`;
