import React from 'react';
import {
    StyledCheckboxText,
    StyledDivider,
    StyledInput,
    StyledPremiumCard,
    StyledRowAlignment,
    StyledTermsContainer,
    StyledTermsText,
    StyledVerify
} from './styles';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../redux';
import { Typography } from '@loophealth/loop-ui-web-library';
import { IPremiumBreakdown } from './types';

import { REDIRECT_URL } from '../../../../../utils/constants/RedirectConstants';
import { redirectToMobileStore } from '../DashboardPage/utils';

const PremiumBreakdown: React.FunctionComponent<IPremiumBreakdown> = ({ checkboxChecked, setShowcheckboxChecked }) => {
    const topUpDetails: any = useSelector((state: ReduxState) => state.TopUp_policyData.memberTopUp);

    return (
        <StyledPremiumCard>
            <StyledRowAlignment>
                <Typography variant="small">GMC (Base Cover)</Typography>
                <Typography variant="small" weight="bold">
                    ₹0
                </Typography>
            </StyledRowAlignment>
            <div>
                <StyledRowAlignment>
                    <Typography variant="small">Top up Premium</Typography>
                    <Typography variant="small" weight="bold">
                        ₹{topUpDetails.annualPremium}
                    </Typography>
                </StyledRowAlignment>
                <Typography variant="extraSmall" color="secondary">
                    *Inclusive of GST and service charge
                </Typography>
            </div>
            <StyledDivider />
            <StyledRowAlignment>
                <Typography variant="small">Total Annual Premium</Typography>
                <Typography variant="large" weight="bold" color="emerald">
                    ₹{topUpDetails.annualPremium}
                </Typography>
            </StyledRowAlignment>
            <StyledTermsContainer>
                <StyledInput
                    type="checkbox"
                    defaultChecked={true}
                    value=""
                    onChange={() => setShowcheckboxChecked(!checkboxChecked)}
                />
                <StyledCheckboxText>
                    <StyledVerify>
                        I hereby declare that I have read policy
                        <StyledTermsText onClick={() => redirectToMobileStore(REDIRECT_URL.TERMS_CONDITIONS)}>
                            Terms and Conditions
                        </StyledTermsText>
                        carefully.
                    </StyledVerify>
                </StyledCheckboxText>
            </StyledTermsContainer>
        </StyledPremiumCard>
    );
};

export default PremiumBreakdown;
