import React from 'react';
import {
    Ambulance,
    ClaimsIcon,
    cooldudeIcon,
    CovidIcon,
    Cross,
    Door,
    HospitalIcon,
    InjectionIcon,
    LeavesIcon,
    Stethoscope
} from '../../../assets/img';
import theme from '../../../theme';
import { ITopupBenefits } from './types';
import { BenefitWidget } from '../BenefitWidget';
import {
    BenefitImageContainer,
    Container,
    RowContainer,
    StyledTabWrapper,
    StyledImage,
    StyledTabContainer,
    StyledTabSection,
    StyledHospitalLink,
    StyledInfoContainer,
    StyledInfoHeaderContainer
} from './styles';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../redux';
import { Typography } from '@loophealth/loop-ui-web-library';
import { redirectTo } from '../../../utils/Redirect';
const TopupCoveredBenefits: React.FunctionComponent<ITopupBenefits> = ({ scrollRef }) => {
    // const trackClick = useSegment('click');
    const topupPolicyData = useSelector((state: ReduxState) => state.TopUp_policyData.topupPolicyDetails.data);
    const commonProps = {
        subTitleFont: '13px',
        titleFont: '18px',
        iconHeight: '21px',
        containerWidth: '48px',
        containerHeight: '48px',
        paddingStyle: '0px',
        widgetBackgroundColor: theme.colors.white
    };
    const today = new Date();
    const oneYearFromNow = new Date(today);
    oneYearFromNow.setFullYear(today.getFullYear() + 1);
    return (
        <Container>
            <div ref={scrollRef} />
            <StyledInfoHeaderContainer>
                <Typography variant="title2" color="emerald">
                    Extended health cover for your
                    <Typography variant="title2" color="planRed">
                        {' '}
                        peace of mind
                    </Typography>
                </Typography>
                <Typography variant="large" color="emerald">
                    A super top up covers all benefits from your base policy with an extended coverage
                </Typography>
            </StyledInfoHeaderContainer>

            <StyledTabWrapper>
                <StyledTabContainer>
                    <StyledTabSection>
                        <Typography variant="title3" color="tertiary">
                            7500+ Network Hospitals
                        </Typography>
                        <Typography variant="small" color="tertiary">
                            Across 25+ cities for a smooth cashless experience
                        </Typography>
                        <Typography variant="small" color="green">
                            {topupPolicyData ? (
                                <StyledHospitalLink onClick={() => redirectTo(topupPolicyData, 'Hospitals')}>
                                    {' '}
                                    Explore hospitals →
                                </StyledHospitalLink>
                            ) : null}
                        </Typography>
                    </StyledTabSection>
                    <StyledImage src={ClaimsIcon} />
                </StyledTabContainer>
                <StyledTabContainer>
                    <StyledTabSection>
                        <Typography variant="title3" color="tertiary">
                            Valid for 1 year
                        </Typography>
                        <Typography variant="small" color="tertiary">
                            {today.toLocaleDateString('en-GB')} - {oneYearFromNow.toLocaleDateString('en-GB')}
                        </Typography>
                        <Typography variant="small" color="tertiary">
                            Even if you leave your current employer, the super top up continues to secure you
                        </Typography>
                    </StyledTabSection>
                    <StyledImage src={cooldudeIcon} />
                </StyledTabContainer>
            </StyledTabWrapper>

            <BenefitImageContainer>
                <RowContainer $margin="50px 0px 0px 0px">
                    <BenefitWidget
                        src={HospitalIcon}
                        title={'Hopitalisations'}
                        marginStyle="0px 24px 0px 0px"
                        subTitle={'Covers all treatments & accidents leading to 24 hours+ hospitalisation'}
                        backgroundColor={theme.colors.coverYellow}
                        {...commonProps}
                    />
                    <BenefitWidget
                        src={Ambulance}
                        title={'Ambulance'}
                        subTitle={
                            // eslint-disable-next-line max-len
                            'Covered upto <b>₹2,500</b> for emergency cases'
                        }
                        backgroundColor={theme.colors.coverUpGreen}
                        {...commonProps}
                    />
                    <BenefitWidget
                        {...commonProps}
                        src={Door}
                        title={'Room Rent'}
                        marginStyle="0px 0px 0px 24px"
                        subTitle={
                            // eslint-disable-next-line max-len
                            `Covered upto <b>2%</b> (of Sum Insured) for a normal room and <b>4%</b> (of Sum Insured) for ICU per day`
                        }
                        backgroundColor={theme.colors.planRed}
                    />
                </RowContainer>
                <RowContainer $margin="50px 0px 0px 0px">
                    <BenefitWidget
                        src={InjectionIcon}
                        title={'Major Treatments'}
                        marginStyle="0px 24px 0px 0px"
                        subTitle={`Including <b>heart surgery, cancer therapies</b> and <b>transplants </b>`}
                        backgroundColor={theme.colors.teamTurquoise}
                        {...commonProps}
                    />
                    <BenefitWidget
                        src={Ambulance}
                        title={'Accidents'}
                        subTitle={
                            // eslint-disable-next-line max-len
                            'All injuries and expenses arising out of an emergency and accidents'
                        }
                        backgroundColor={theme.colors.purple}
                        {...commonProps}
                    />
                    <BenefitWidget
                        {...commonProps}
                        src={Cross}
                        title={'Day Care Treatments'}
                        marginStyle="0px 0px 0px 24px"
                        subTitle={
                            // eslint-disable-next-line max-len
                            `Covers treatments which require less than 24 hours hospitalisation `
                        }
                        backgroundColor={theme.colors.coverUpGreen}
                    />
                </RowContainer>
                <RowContainer $margin="50px 0px 0px 0px">
                    <BenefitWidget
                        src={Stethoscope}
                        title={'Pre & Post hospitalisation'}
                        marginStyle="0px 24px 0px 0px"
                        subTitle={`Expenses <b>30 Days</b> before and <b>60 days</b> after hospitalisation are covered`}
                        backgroundColor={theme.colors.planRed}
                        {...commonProps}
                    />
                    <BenefitWidget
                        src={CovidIcon}
                        title={'Covid Treatment'}
                        subTitle={
                            // eslint-disable-next-line max-len
                            'Covers any hospital expenses arising from COVID-19 infection'
                        }
                        backgroundColor={theme.colors.coverYellow}
                        {...commonProps}
                    />
                    <BenefitWidget
                        {...commonProps}
                        src={LeavesIcon}
                        title={'AYUSH Treatment'}
                        marginStyle="0px 0px 0px 24px"
                        subTitle={`Covered upto ₹50,000 for Ayurvedic, Yoga, Unani, Siddha & Homeopathy`}
                        backgroundColor={theme.colors.purple}
                    />
                </RowContainer>
            </BenefitImageContainer>

            <StyledInfoContainer>
                <Typography variant="medium" color="planRed">
                    *Maternity
                    <Typography variant="medium" color="primary">
                        {' '}
                        is not covered in this super top-up but you are already covered for maternity under your base
                        GMC policy
                    </Typography>
                </Typography>
            </StyledInfoContainer>
        </Container>
    );
};

export default TopupCoveredBenefits;
