/* eslint-disable max-len */
import React from 'react';
import {
    IFieldType,
    IUseNameValidationFunctionType,
    IUseNameValidationReturnType
} from '../containers/ManageMemberModal/types';

export const useNameValidation = (
    formFirstName: string | undefined,
    formLastName: string | undefined
): IUseNameValidationReturnType => {
    const charRegex = /^[a-zA-Z ]+$/;
    const [firstName, setFirstName] = React.useState(formFirstName || '');
    const [lastName, setLastName] = React.useState(formLastName || '');

    const validateName = (): IUseNameValidationFunctionType => {
        const firstNameCheck = !firstName.length || !charRegex.test(firstName);
        const lastNameCheck = !lastName.length || !charRegex.test(lastName);
        if (firstNameCheck && lastNameCheck) {
            return {
                firstNameError: 'Please enter a valid first name',
                lastNameError: 'This field is required. If you don’t have a last name, use your first name again!'
            };
        } else if (firstNameCheck) {
            return {
                firstNameError: 'Please enter a valid first name',
                lastNameError: ''
            };
        } else if (lastNameCheck) {
            return {
                firstNameError: '',
                lastNameError: 'This field is required. If you don’t have a last name, use your first name again!'
            };
        }
        return {
            firstNameError: '',
            lastNameError: ''
        };
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>, type: IFieldType) => {
        const value = e.currentTarget.value.trim();
        if (charRegex.test(value) || !value) {
            type === 'firstName' ? setFirstName(value) : setLastName(value);
        }
    };

    return {
        firstName: {
            value: firstName,
            setValue: setFirstName
        },
        lastName: {
            value: lastName,
            setValue: setLastName
        },
        validateName,
        onChange
    };
};
