import React from 'react';
import {
    StyledModalContainer,
    StyledTitleContainer,
    StyledImage,
    StyledContentImage,
    StyledContentText,
    StyledButtonContainer,
    UserRegisteredButton,
    StyledContentDisclaimer
} from './styles';
import { IPaymentFailedModal } from './types';
import { styledCross, oopsShapeIcon } from '../../../assets/img';
import { Modal } from '@loophealth/loop-ui-web-library';
const PaymentFailedModal: React.FunctionComponent<IPaymentFailedModal> = ({ isVisible, setIsVisible }) => {
    return (
        <Modal setIsVisible={setIsVisible} isVisible={isVisible}>
            <StyledModalContainer>
                <StyledTitleContainer>
                    <StyledImage src={styledCross} />
                    Oops! Payment Failed
                </StyledTitleContainer>
                <StyledContentImage src={oopsShapeIcon} />
                <StyledContentText>
                    Your payment didn’t go through. Please try again using
                    <br />
                    Retry Payment.
                </StyledContentText>
                <StyledButtonContainer>
                    <UserRegisteredButton onClick={() => setIsVisible(false)}>Retry Payment</UserRegisteredButton>
                    <StyledContentDisclaimer>
                        In case the payment got deducted from your bank account, it will <br /> be refunded back within
                        2-3 business days
                    </StyledContentDisclaimer>
                </StyledButtonContainer>
            </StyledModalContainer>
        </Modal>
    );
};

export default PaymentFailedModal;
