import React from 'react';
import {
    Container,
    StyledHeaderWrapper,
    StyledItemContainer,
    StyledItemWrapper,
    StyledLoopItemCard,
    StyledOtherItemCard,
    TopUpFeatureContainerResponsive,
    StyledHeaderText,
    StyledInnerHeaderText
} from './styles';
import TopUpCrossCoverage from './TopUpCrossCoverage';
import TopUpGivenCoverage from './TopUpGivenCoverage';
import TopUpDetails from './TopUpDetails';
import { TopUpFeatureContainer } from './styles';
import { Typography } from '@loophealth/loop-ui-web-library';

import theme from '../../../theme';
import { PersonImg, QuotesImg, Stethoscope, contentIcon } from '../../../assets/img';
import { ImageView } from '../../atoms/ImageView';
const TopupLoopDifference: React.FunctionComponent = () => {
    const commonImgProps = {
        height: '21px',
        width: '21px',
        containerMinHeight: '42px',
        containerWidth: '42px',
        radius: '32px'
    };
    return (
        <Container>
            <StyledHeaderWrapper>
                <StyledInnerHeaderText $color={theme.colors.loopEmerald}>
                    What makes us
                    <StyledInnerHeaderText $color={theme.colors.purple}>{' the best?'}</StyledInnerHeaderText>
                </StyledInnerHeaderText>
                <Typography variant="medium" color="emerald">
                    We have secured the most comprehensive coverage possible. Your family deserves it.
                </Typography>
            </StyledHeaderWrapper>
            <StyledHeaderText></StyledHeaderText>
            <TopUpFeatureContainer>
                <TopUpDetails />
                <TopUpGivenCoverage />
                <TopUpCrossCoverage />
            </TopUpFeatureContainer>
            <TopUpFeatureContainerResponsive>
                <StyledItemWrapper>
                    <StyledItemContainer>
                        <ImageView {...commonImgProps} src={Stethoscope} backgroundColor={theme.colors.planRed} />
                        <Typography variant="small" color="tertiary">
                            Are pre-existing diseases covered?
                        </Typography>
                    </StyledItemContainer>
                    <StyledLoopItemCard>
                        <Typography variant="small" color="emerald">
                            Loop: Covered from Day 1
                        </Typography>
                    </StyledLoopItemCard>
                    <StyledOtherItemCard>
                        <Typography variant="small" color="tertiary">
                            Others: Waiting period between 2-4 years
                        </Typography>
                    </StyledOtherItemCard>
                </StyledItemWrapper>
                <StyledItemWrapper>
                    <StyledItemContainer>
                        <ImageView {...commonImgProps} src={PersonImg} backgroundColor={theme.colors.coverUpGreen} />
                        <Typography variant="small" color="tertiary">
                            Are parents covered?
                        </Typography>
                    </StyledItemContainer>
                    <StyledLoopItemCard>
                        <Typography variant="small" color="emerald">
                            Loop: Yes, upto 90 years
                        </Typography>
                    </StyledLoopItemCard>
                    <StyledOtherItemCard>
                        <Typography variant="small" color="tertiary">
                            Others: Cover parents only up-to 65 years
                        </Typography>
                    </StyledOtherItemCard>
                </StyledItemWrapper>
                <StyledItemWrapper>
                    <StyledItemContainer>
                        <ImageView {...commonImgProps} src={QuotesImg} backgroundColor={theme.colors.coverYellow} />
                        <Typography variant="small" color="tertiary">
                            What about out-of-pocket payment for claims?
                        </Typography>
                    </StyledItemContainer>
                    <StyledLoopItemCard>
                        <Typography variant="small" color="emerald">
                            Loop: None, at all
                        </Typography>
                    </StyledLoopItemCard>
                    <StyledOtherItemCard>
                        <Typography variant="small" color="tertiary">
                            Others: May require 5-10% payment
                        </Typography>
                    </StyledOtherItemCard>
                </StyledItemWrapper>
                <StyledItemWrapper>
                    <StyledItemContainer>
                        <ImageView {...commonImgProps} src={contentIcon} backgroundColor={theme.colors.purple} />
                        <Typography variant="small" color="tertiary">
                            What about claims support?
                        </Typography>
                    </StyledItemContainer>
                    <StyledLoopItemCard>
                        <Typography variant="small" color="emerald">
                            Loop: Hassle-free with 24*7 support
                        </Typography>
                    </StyledLoopItemCard>
                    <StyledOtherItemCard>
                        <Typography variant="small" color="tertiary">
                            Others: Requires coordination with different agents for claims
                        </Typography>
                    </StyledOtherItemCard>
                </StyledItemWrapper>
            </TopUpFeatureContainerResponsive>
        </Container>
    );
};

export default TopupLoopDifference;
