import React from 'react';
import PolicyModalDesktop from './PolicyModalDesktop';
import PolicyModalMobile from './PolicyModalMobile';
import withPolicyBenefitsModal from './withPolicyBenefitsModal';
import { IPolicyBenefitsModal } from './types';
import { Loader, Modal } from '@loophealth/loop-ui-web-library';
import { StyledContainer, StyledContentWrapper, StyledHeaderWrapper } from './styles';
import { InsuranceIdentity } from '../../atoms';
import useFetchPolicyInclusions from '../../pages/SPAContainer/pages/DashboardPage/hooks/useFetchPolicyInclusions';
const ResponsiveInsuranceComponent = withPolicyBenefitsModal(PolicyModalDesktop, PolicyModalMobile);
const PolicyInclusionsModal: React.FunctionComponent<IPolicyBenefitsModal> = ({
    isVisible,
    setIsVisible,
    policyId,
    insuranceCategory,
    pageTitle,
    policyImage,
    policyName,
    familyStructure
}) => {
    const { detailsLoading, InclusionData } = useFetchPolicyInclusions(policyId);
    return (
        <Modal title={pageTitle} isVisible={isVisible} setIsVisible={setIsVisible}>
            <StyledContainer>
                <StyledHeaderWrapper>
                    <InsuranceIdentity
                        policyCategory={insuranceCategory}
                        policyImage={policyImage}
                        policyName={policyName}
                        familyStructure={familyStructure}
                        isPhoneResponsive={true}
                    />
                </StyledHeaderWrapper>
                <StyledContentWrapper>
                    {detailsLoading ? (
                        <Loader />
                    ) : (
                        <>{InclusionData ? <ResponsiveInsuranceComponent data={InclusionData} /> : null}</>
                    )}
                </StyledContentWrapper>
            </StyledContainer>
        </Modal>
    );
};

export default PolicyInclusionsModal;
