import { useSelector } from 'react-redux';
import { checkParentalPolicy } from '../../../utils/PolicyServices';
import { familyDefinition, getDependentMappings } from '../../../utils/mapping';
import { ReduxState } from '../../../redux';

export const getTitle = (): string => {
    const currentPolicy = useSelector((state: ReduxState) => state.enrolment.currentlySelectedPolicy);
    if (currentPolicy) {
        const policyType = currentPolicy?.policyType;
        if (policyType === 'GMC') {
            return `Added Everyone?`;
        } else if (policyType === 'GTL' || policyType === 'GPA') {
            return `Almost There!`;
        }
    }
    return '';
};

export const getDescriptionTitle = (): string => {
    const currentPolicy = useSelector((state: ReduxState) => state.enrolment.currentlySelectedPolicy);
    if (currentPolicy) {
        const policyType = currentPolicy?.policyType;
        if (policyType === 'GMC') {
            const isParentalPolicy = checkParentalPolicy(currentPolicy);
            const familyStructure = currentPolicy?.familyStruture;
            const targetCount = isParentalPolicy
                ? familyDefinition[familyStructure].totalDependents
                : familyDefinition[familyStructure].totalDependents + 1;
            const currentCount = isParentalPolicy
                ? getDependentMappings(currentPolicy.dependents, familyStructure).totalDependents
                : getDependentMappings(currentPolicy.dependents, familyStructure).totalDependents + 1;
            return `You've added ${currentCount} eligible family members, you can add up to ${targetCount}.`;
        } else if (policyType === 'GTL' || policyType === 'GPA') return `Checked your personal details?`;
    }
    return '';
};

export const getDescription = (): string => {
    const currentPolicy = useSelector((state: ReduxState) => state.enrolment.currentlySelectedPolicy);
    if (currentPolicy) {
        const policyType = currentPolicy?.policyType;
        if (policyType === 'GMC') {
            return `Should we go ahead and complete your registration?`;
        } else if (policyType === 'GTL' || policyType === 'GPA') {
            return `Lets proceed to secure your coverage`;
        }
    }
    return '';
};
