import styled from 'styled-components';
import theme from '../../../theme';

export const FaqContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 20px;
`;

export const Container = styled.div<{ $margin?: string; $bgColor: string }>`
    display: flex;
    flex-direction: column;
    width: 60vw;
    background-color: ${(p) => p.$bgColor};
    padding: 18px 24px;
    margin: ${(p) => p.$margin || null};
    border-radius: 12px;
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

export const FaqTitle = styled.span`
    font-size: ${(p) => p.theme.fontSizes.mediumFont};
    color: ${theme.colors.beyondGrey};
`;

export const FaqDescription = styled.span`
    font-size: ${(p) => p.theme.fontSizes.body};
    color: ${theme.colors.beyondGrey};
    margin-top: 18px;
    line-height: 18px;
    opacity: 0.8;
    text-align: justify;
`;
