import React from 'react';
import {
    StyledButtonContainer,
    StyledButtonRow,
    StyledContainer,
    StyledIcon,
    StyledImageWrapper,
    StyledInfoContainer,
    StyledSliderImage,
    StyledSliderWrapper,
    StyledTagWrapper
} from './styles';
import { IWelcomeCard } from './types';
import { LoopButton, LoopTag, Typography } from '@loophealth/loop-ui-web-library';
import { TextView } from '../../atoms/TextView';
import theme from '../../../theme';
import {
    SliderIcon,
    WelcomeClaimIcon,
    WelcomeOneIcon,
    WelcomeRegisIcon,
    welcomeMobileIcon,
    welcomeMobileThreeIcon,
    welcomeMobileTwoIcon
} from '../../../assets/img';
import useResponsive from '../PolicyInclusionsModal/hooks/useResponsive';

const WelcomeCard: React.FunctionComponent<IWelcomeCard> = ({ onProceed }) => {
    const [activeScreen, setActiveScreen] = React.useState<number>(1);
    const isDesktop = useResponsive('(min-width: 768px)');
    const fontSize = isDesktop ? '54px' : '20px';

    const renderContent = () => {
        switch (activeScreen) {
        case 2:
            return (
                <>
                    <TextView fontSize={fontSize} margin="0px 0px 0px 4px" color={theme.colors.loopEmerald}>
                        {'Claims experts '}
                        <TextView fontSize={fontSize} margin="0px 0px 0px 4px" color={theme.colors.beyondGrey}>
                                that {isDesktop ? <br /> : null}
                                work 24x7 to
                            <TextView fontSize={fontSize} margin="0px 0px 0px 4px" color={theme.colors.loopEmerald}>
                                {' support '}
                            </TextView>
                                you
                        </TextView>
                    </TextView>
                    <Typography variant={isDesktop ? 'large' : 'small'} weight="medium">
                            From filing to discharge, get end-to-end claims support from a dedicated team.
                    </Typography>
                </>
            );
        case 3:
            return (
                <>
                    <TextView fontSize={fontSize} margin="0px 0px 0px 4px" color={theme.colors.loopEmerald}>
                        {'Easy registration '}
                        <TextView fontSize={fontSize} margin="0px 0px 0px 4px" color={theme.colors.beyondGrey}>
                                to {isDesktop ? <br /> : null}
                                start your family’s coverage
                        </TextView>
                    </TextView>
                    <Typography variant={isDesktop ? 'large' : 'small'} weight="medium">
                            Spend less than 5 minutes to enroll you and your family on Loop to enjoy health benefits and
                            stress-free claims.
                    </Typography>
                </>
            );
        default:
            return (
                <>
                    <StyledTagWrapper>
                        <LoopTag variant="default">👋 Welcome to Loop!</LoopTag>
                    </StyledTagWrapper>
                    <TextView fontSize={fontSize} margin="0px 0px 0px 4px" color={theme.colors.loopEmerald}>
                            Unlimited
                        {isDesktop ? <br /> : null}
                        <TextView fontSize={fontSize} margin="0px 0px 0px 4px" color={theme.colors.beyondGrey}>
                                healthcare from
                            <TextView fontSize={fontSize} margin="0px 0px 0px 4px" color={theme.colors.loopEmerald}>
                                {' top 10% '}
                            </TextView>
                                doctors
                        </TextView>
                    </TextView>
                    <Typography variant={isDesktop ? 'large' : 'small'} weight="medium">
                            Health benefits designed to keep you and your family out of the hospital.
                    </Typography>
                </>
            );
        }
    };

    const renderImage = () => {
        switch (activeScreen) {
        case 2:
            return <StyledSliderImage src={isDesktop ? WelcomeClaimIcon : welcomeMobileIcon} />;
        case 3:
            return <StyledSliderImage src={isDesktop ? WelcomeRegisIcon : welcomeMobileTwoIcon} />;
        default:
            return <StyledSliderImage src={isDesktop ? WelcomeOneIcon : welcomeMobileThreeIcon} />;
        }
    };

    const handleNext = () => {
        if (activeScreen < 3) {
            setActiveScreen((prev) => prev + 1);
        } else {
            onProceed();
        }
    };

    const handleBack = () => {
        setActiveScreen((prev) => (prev > 1 ? prev - 1 : prev));
    };

    return (
        <StyledContainer>
            <StyledInfoContainer>
                {renderContent()}
                <StyledSliderWrapper>
                    {[1, 2, 3].map((screen) => (
                        <StyledIcon
                            key={screen}
                            src={SliderIcon}
                            $fillActive={activeScreen === screen}
                            onClick={() => setActiveScreen(screen)}
                        />
                    ))}
                </StyledSliderWrapper>
                <StyledButtonRow>
                    {activeScreen !== 1 && (
                        <StyledButtonContainer>
                            <LoopButton variant={'outline'} onClick={handleBack}>
                                Back
                            </LoopButton>
                        </StyledButtonContainer>
                    )}
                    <StyledButtonContainer>
                        <LoopButton variant={'filled'} onClick={handleNext}>
                            {activeScreen === 3 ? 'Get Started' : 'Next'}
                        </LoopButton>
                    </StyledButtonContainer>
                </StyledButtonRow>
            </StyledInfoContainer>
            <StyledImageWrapper>{renderImage()}</StyledImageWrapper>
        </StyledContainer>
    );
};

export default WelcomeCard;
