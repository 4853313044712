import React from 'react';
import { SEGMENT_ACTIONS } from '../../../../../utils/constants/SegmentActionConstants';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../redux';
import { getSegmentPageName } from '../../../../../utils/common';
import useSegment from '../../../../../utils/hooks/useSegment';
import { getEnrolledPolicyCompany } from '../../../../../utils/PolicyServices';
import SummaryPage from '../SummaryPage';

const CheckoutSummaryPage: React.FunctionComponent = () => {
    const currentlySelectedPolicy = useSelector((state: ReduxState) => state.enrolment.currentlySelectedPolicy);
    const policyList = useSelector((state: ReduxState) => state.policyList.policyList.data) || [];
    const currentPageAction = SEGMENT_ACTIONS.PAGE[getSegmentPageName(location.pathname) as any];
    const trackPage = useSegment('page');
    const topUpDetails: any = useSelector((state: ReduxState) => state.TopUp_policyData.memberTopUp);
    const getEnrolledGMCPolicies = getEnrolledPolicyCompany(policyList);

    React.useEffect(() => {
        trackPage({
            name: currentPageAction.name,
            properties: {
                page_url: (currentPageAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                page_id: (currentPageAction.properties?.page_id as (path: string) => string)(location.pathname) || '',
                page_title: currentPageAction.properties?.page_title,
                login_status: currentPageAction.properties?.login_status,
                referrer_page_title: currentPageAction.properties?.referrer_page_title,
                enrolling_in: (currentPageAction.properties?.enrolling_in as (enrolledIN: string) => string)(
                    getEnrolledGMCPolicies.length
                        ? topUpDetails && topUpDetails.slabId
                            ? 'topup'
                            : 'both'
                        : topUpDetails && topUpDetails.slabId
                            ? 'both'
                            : 'GMC'
                ),
                sum_insured: (currentPageAction.properties?.sum_insured as (sumInsured: string) => string)(
                    topUpDetails?.sumInsured
                )
            }
        });
    }, [currentPageAction]);
    return <>{currentlySelectedPolicy ? <SummaryPage selectedBasePolicy={currentlySelectedPolicy} /> : null}</>;
};

export default CheckoutSummaryPage;
