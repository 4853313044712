import React, { useState } from 'react';
import {
    StyledCalendarIcon,
    StyledContainer,
    StyledContent,
    StyledContentWrapper,
    StyledDaysLeft,
    StyledDueDate,
    StyledDueDateContainer,
    StyledEnrolmentDate,
    StyledEnrolmentDateContainer,
    StyledEnrolmentInfoWrapper,
    StyledHorizontalDivider,
    StyledPolicyInfoWrapper,
    StyledPolicyInfoMobile,
    StyledBackground
} from './styles';
import { IEnrolmentCard } from './types';
import { InsuranceIdentity } from '../../atoms';
import { capitalizeFirstLetter, replaceFamilyStructureText } from '../../../utils/common';
import { CalendarIcon } from '../../../assets/img';
import PolicyInfo from './PolicyInfo';

const EnrolmentCard: React.FunctionComponent<IEnrolmentCard> = ({ children, ...insuranceCardHeaderProps }) => {
    const [displayPolicyInfo, setDisplayPolicyInfo] = useState(false);

    return (
        <StyledContainer>
            <StyledBackground>
                <StyledContentWrapper>
                    <InsuranceIdentity
                        policyImage={insuranceCardHeaderProps.policyImage}
                        policyName={insuranceCardHeaderProps.insuranceName}
                        policyCategory={insuranceCardHeaderProps.insuranceCategory}
                        familyStructure={insuranceCardHeaderProps.dependentsInsured}
                        onIconClick={() => setDisplayPolicyInfo(!displayPolicyInfo)}
                        isInverted={displayPolicyInfo}
                    />
                    {insuranceCardHeaderProps.insuranceCategory !== 'SUPER_TOP_UP' && (
                        <>
                            <StyledPolicyInfoMobile $isCollapsed={!displayPolicyInfo}>
                                <PolicyInfo
                                    dependentsInsured={capitalizeFirstLetter(
                                        replaceFamilyStructureText(insuranceCardHeaderProps.dependentsInsured)
                                    )}
                                    sumInsured={
                                        insuranceCardHeaderProps.sumInsured.trim()
                                            ? insuranceCardHeaderProps.sumInsured
                                            : 'N/A'
                                    }
                                />
                            </StyledPolicyInfoMobile>
                            <StyledEnrolmentInfoWrapper $isCollapsed={!displayPolicyInfo}>
                                <StyledDueDateContainer>
                                    <StyledCalendarIcon alt="Calendar-Icon" src={CalendarIcon} />
                                    <StyledDueDate>Registration Deadline</StyledDueDate>
                                </StyledDueDateContainer>
                                <StyledEnrolmentDateContainer>
                                    <StyledEnrolmentDate>
                                        {insuranceCardHeaderProps.enrolmentDueDate?.trim()
                                            ? insuranceCardHeaderProps.enrolmentDueDate
                                            : null}
                                    </StyledEnrolmentDate>
                                    <StyledDaysLeft>
                                        {insuranceCardHeaderProps.daysLeft.length !== 0
                                            ? insuranceCardHeaderProps.daysLeft
                                            : null}
                                    </StyledDaysLeft>
                                </StyledEnrolmentDateContainer>
                            </StyledEnrolmentInfoWrapper>
                        </>
                    )}
                </StyledContentWrapper>
                <StyledPolicyInfoWrapper>
                    <PolicyInfo
                        dependentsInsured={capitalizeFirstLetter(
                            replaceFamilyStructureText(insuranceCardHeaderProps.dependentsInsured)
                        )}
                        sumInsured={
                            insuranceCardHeaderProps.sumInsured.trim() ? insuranceCardHeaderProps.sumInsured : 'N/A'
                        }
                    />
                </StyledPolicyInfoWrapper>
                <StyledHorizontalDivider />
            </StyledBackground>
            <StyledContent>{children}</StyledContent>
        </StyledContainer>
    );
};

export default EnrolmentCard;
