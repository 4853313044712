import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* width: 100%; */
    padding: 32px 24px 0px 24px;
    @media (max-width: 768px) {
        padding: 24px 20px;
        width: 100%;

        &::-webkit-scrollbar {
            display: none;
        }
    }
    .leadpage-input {
        width: 100%;
        box-shadow: 0px 4px 10px rgba(149, 159, 190, 0.1);
        border-radius: 8px;
        padding: 9px;
        box-sizing: border-box;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #3d4144;
        background: #edeff3;
        border: 0px;
    }
    .react-date-picker__wrapper {
        border: 0;
    }
    .date-picker-error {
        border: 1px solid ${(p) => p.theme.colors.error};
    }
`;
export const StyledRow = styled.div<{ $isError?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: ${(p) => (p.$isError ? '24px' : '0px')};
    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
        justify-content: center;
    }
`;
export const StyledInputGroup = styled.div`
    position:: relative;
    margin-right: 30px;
    &:last-child {
        margin-right: 0px;
    }
    margin-bottom: 30px;
    max-width: 50%;
    min-width: 300px;
    @media (max-width: 768px) {
        min-width: 80%;
        margin-right: 0px;
    }
`;

export const StyledSelectOption = styled.div`
    width: 100%;
    min-width: 300px;
    @media (max-width: 768px) {
        min-width: 80%;
        margin-right: 0px;
    }
`;

export const StyledSpan = styled.div(
    (p) => `
    color: ${p.theme.colors.error};
    margin-left: 3px;
    font-size: ${p.theme.fontSizes.body};
`
);

export const StyledHeading = styled.div(
    (p) => `
    margin-bottom: 20px;
    color: ${p.theme.colors.black};
    font-size: ${p.theme.fontSizes.subHeading};
    font-weight: 700;
    letter-spacing: 0.05em;
    width:100%;
`
);

export const StyledSelect = styled.select<{
    $src: string;
    $color: boolean;
    $error: boolean;
}>`
    width: 100%;
    box-shadow: 0px 4px 10px rgba(149, 159, 190, 0.1);
    border-radius: 8px;
    padding: 15px;
    box-sizing: border-box;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: ${(p) => (p.$error ? p.theme.colors.error : p.$color ? p.theme.colors.muted : p.theme.colors.black)};
    border: 0px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #edeff3 url(${(p) => p.$src}) no-repeat scroll 95% center;
    ${(p) => (p.$error ? `border: 1px solid ${p.theme.colors.error}` : '')}
`;

export const StyledDividerContainer = styled.div`
    width: 100%;
    margin: 10px;
`;

export const StyledAlignment = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    align-items: center;
`;

export const StyledStickyContainer = styled.div`
    border-top: 1px solid ${(p) => p.theme.colors.seafoamGray};
    background-color: ${(p) => p.theme.colors.white};
    position: sticky;
    bottom: 0px;
    display: flex;
    justify-content: flex-end;
    padding: 20px 16px;
`;

export const StyledButtonContainer = styled.div`
    width: 135px;
    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const StyledDisclaimerContainer = styled.div`
    display: flex;
    border-radius: 8px;
    background: #5959591a;
    margin-bottom: 24px;
    width: 100%;
    max-width: 600px;
`;
