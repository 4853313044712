import { FirebaseRemoteConfig } from '../../../adapters/provider';
import { IDependent } from '../../../redux/slices/PolicyListSlice/types';
import { getAgeFromDOB } from '../../../utils/common';
import { COMPANY_ID, PARENTAL_POLICY } from '../../../utils/constants';
import { familyDefinition } from '../../../utils/mapping';
import { IInsurancePropsType } from './types';

export const showTaxStripUtil = (
    insuranceProps: IInsurancePropsType,
    SI: string | undefined,
    enrolmentStatus: string | undefined,
    superTopupStatus: string | undefined,
    companyId: string,
    dependents: IDependent[]
): boolean => {
    const companyIDs = FirebaseRemoteConfig.getValue('SupertopUpParentalWhitelisting').asString();
    const companyIDsArray = companyIDs && JSON.parse(companyIDs);
    if (
        insuranceProps.insuranceCategory == 'GMC' &&
        Number(SI) >= 200000 &&
        (superTopupStatus === 'NOT_SELECTED' || superTopupStatus === 'PENDING')
    ) {
        if (
            typeof companyId != 'undefined' && companyIDsArray.length
                ? companyIDsArray.includes(companyId)
                : COMPANY_ID.includes(companyId) && insuranceProps.dependentsInsured.toLowerCase().includes('self')
        ) {
            return false;
        }
        const filteredUserDOB = dependents.find((user: { doB: number }) => {
            return getAgeFromDOB(user.doB) > 90;
        });
        if (typeof filteredUserDOB != 'undefined') {
            return false;
        }
        return true;
    } else if (insuranceProps.insuranceCategory == 'SUPER_TOP_UP' && enrolmentStatus !== 'ENROLED') {
        return true;
    } else if (
        insuranceProps.insuranceCategory == 'PARENTAL' &&
        Number(SI) >= 200000 &&
        (superTopupStatus === 'NOT_SELECTED' || superTopupStatus === 'PENDING') &&
        typeof companyId != 'undefined' &&
        companyIDsArray.length
            ? companyIDsArray.includes(companyId)
            : COMPANY_ID.includes(companyId) && !insuranceProps.dependentsInsured.toLowerCase().includes('self')
    ) {
        return true;
    }
    return false;
};

export const calculateAPString = (annualPremium: number): string => {
    if (annualPremium <= 0) {
        return '-';
    }
    return `₹` + annualPremium?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getEnroledMembersString = (
    dependentsCount: number,
    dependentsInsured: string,
    isTopUpPolicy: boolean
): string => {
    if (isTopUpPolicy) {
        return `${dependentsCount}/${dependentsCount}`;
    }
    return `${dependentsCount}/${
        (familyDefinition[dependentsInsured]?.totalDependents || 0) +
        (PARENTAL_POLICY.includes(dependentsInsured) ? 0 : 1)
    }`;
};
