import React from 'react';
import { StyledEnrolledMemberContent } from './styles';
import { IMemberTableProps } from './types';
import { Typography } from '@loophealth/loop-ui-web-library';
import EnrolledMemberRow from './EnrolledMemberRow';
import { getEnroledMembersString } from './utils';

const EnrolledMemberTable: React.FunctionComponent<IMemberTableProps> = ({ data }) => {
    return (
        <StyledEnrolledMemberContent $isCollapsed={data.isCollapsed}>
            <Typography variant="small" color="secondary" weight="semiBold">
                {getEnroledMembersString(data.dependents?.length, data.dependentsInsured, data.isTopUpPolicy ?? false)}
                {' eligible members registered'}
            </Typography>
            {data.dependents.map((dep, index) => (
                <EnrolledMemberRow key={index} index={index} {...dep} />
            ))}
        </StyledEnrolledMemberContent>
    );
};

export default EnrolledMemberTable;
