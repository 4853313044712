import styled from 'styled-components';

export const StyledBox = styled.div<{ $color: string }>(
    (p) => `
    height: 34px;
    width: 34px;
    min-height: 34px;
    min-width: 34px;
    background-color: ${p.$color};
    color: ${p.theme.colors.white};
    font-size: ${p.theme.fontSizes.label};
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
`
);
